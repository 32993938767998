import {
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from '@mui/icons-material';
import { InputAdornment } from '@mui/material';
import { FC } from 'react';

interface Props {
  showPass: boolean;
  onClick: () => void;
}

const PasswordAdornment: FC<Props> = props => (
  <InputAdornment position='start' onClick={props.onClick} style={{ cursor: 'pointer' }}>
    {props.showPass ? <VisibilityOffIcon color='disabled' /> : <VisibilityIcon color='disabled' />}
  </InputAdornment>
);

export default PasswordAdornment;
