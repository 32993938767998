import { Action, AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import {
  DateContexts,
  FullDateContextItem,
  Modifier,
  ReportRequestStatus,
  ReportUpdateHighlightMode,
  Sort,
} from 'algo-react-dataviz';
import { default as axios } from 'axios';
import equal from 'deep-equal';
import { v4 } from 'uuid';
import { GroupingLayerId } from '../components/designer-panel/drag-and-drop/groupingLayerId';
import { ProxyConfigDto } from '../components/drawers/proxy-drawer/utils';
import { triggerJsonExport } from '../components/report/helpers/exportUtils';
import { oidcClientRefreshSecondsBeforeExpire } from '../components/security/auth/authConfig';
import {
  baseUrl,
  isOidcClientEnabled,
  isPsbcPortalEnabled,
  psbcPortalRefreshSecondsBeforeExpire,
} from '../components/shared/environment';
import { createDefaultSort } from '../model/metadata/sorting';
import { createDefaultConfig, ScenariosConfig } from '../model/scenariosConfig';
import { createDefaultSettings, Settings } from '../model/settings';
import {
  defaultMetadataReportDefinition,
  defaultReportDesignerBenchmarks,
  DESIGNER_SEQUENCE_ID,
  DrawerType,
  INITIAL_PENDING_STATUS,
  METADATA_SEQUENCE_ID,
  NotificationLevel,
} from '../shared/constants';
import {
  AdhocReportRequest,
  Benchmark,
  Characteristic,
  ComponentPermission,
  DrawerWidths,
  FilterOptions,
  FilterType,
  FolderListRow,
  LayerDefinition,
  NodeWithParentId,
  OptionTypes,
  ParentToChildRelationship,
  ReportDefinition,
  ReportRequest,
  Sandbox,
  SelectedPortfolios,
  UISlot,
  WorkspaceData,
  WorkspacePayload,
} from '../shared/dataTypes';
import {
  extractWorkspacePayloadAttr,
  findTabId,
  getCommonRequestProperties,
  getExportType,
  getNumPendingRequests,
  getReportWorkspacePayload,
  getSandbox,
  isCharCustomGrouping,
  isGroupingLayer,
  nextRequestId,
} from '../shared/utils';
import * as ActionTypes from './ActionTypes';
import { AppState, AppThunk, sendStompMessage } from './configureStore';
import { DesignerSource } from './designer/panel/designerSource';
import { FolderDrawerState } from './folderDrawer';
import { setSandbox } from './metadata/actions';
import { getMetadataSandbox } from './metadata/selectors';
import {
  addReportPendingRequest,
  reportFailed,
  reportLoading,
  sendReportUpdateMessage,
  setReportDefinition,
  setReportDefinitionCharacteristics,
  triggerChildUpdate,
  triggerReportRegen,
  updateAndSendSortDefinition,
  updateReportData,
} from './ReportActionCreators';
import { wsConnected } from './reportSaga';
import { ScenariosConfigReducerId } from './scenariosConfig';
import { setFoliaSyntaxGuideOpen } from './ui/actionCreators';
import { isDesignerOpen } from './ui/selectors';
import { fetchUserInfo } from './UserProfileActionCreators';
import { addReportToWorkspace } from './WorkspaceActionCreators';

export const getErrorMessage = (error: any) =>
  error.response?.data
    ? `${error.response.data.message}: ${error.response.data.error} ${error.response.data.status}`
    : `${error.message}`;

export const handleRequestTimerSetup = (
  dispatch: ThunkDispatch<AppState, unknown, Action>,
  requestId: string,
  sequenceId: number,
  rpcTimeout: number,
  scrollTop?: boolean,
  disableUi?: boolean,
) => {
  const timerId = window.setTimeout(() => {
    dispatch({
      type: ActionTypes.REMOVE_PENDING_OPERATION,
      payload: {
        requestIds: [requestId],
        sequenceId,
        timerId,
      },
    });
    dispatch(
      reportFailed(
        sequenceId,
        `Report operation with request id ${requestId} is taking longer than expected.`,
      ),
    );
  }, rpcTimeout + 10000);

  dispatch({
    type: ActionTypes.ADD_REQUEST_TIMER,
    payload: { requestId, sequenceId, timerId, scrollTop, disableUi },
  });
};

// SD-2911 When the parent's selected cell changes, the child reports need to be reset so
// they are scrolled to the top. Doing a quick render with no data accomplishes this.
const resetChildren = (sequenceId: number): AppThunk => (dispatch, getState) =>
  getState().workspace.parentToChildRelationship[sequenceId]?.forEach(
    childSequenceId =>
      extractWorkspacePayloadAttr('live', childSequenceId, getState()) &&
      dispatch(updateReportData({ sequenceId: childSequenceId }, true)),
  );

export const updateElementSelection = (
  elementName: string,
  sequenceId: number,
  isLeftClick: boolean,
  isCtrlClick: boolean,
): AppThunk => (dispatch, getState) => {
  const tabId = findTabId(sequenceId, getState().workspace.data);

  const reportWorkspacePayload = Object.values(getState().workspace.data.tabs[tabId].reports).find(
    report => report.sequenceId === sequenceId,
  );

  const selectedElements = reportWorkspacePayload.selectedElements || [];
  const numberOfSelections = selectedElements.length;
  const i = selectedElements.indexOf(elementName);
  const elementIsAlreadySelected = i !== -1;
  let newSelectedElements = [];

  if (isCtrlClick) {
    newSelectedElements = [...selectedElements];
    if (elementIsAlreadySelected) {
      newSelectedElements.splice(i, 1);
    } else {
      newSelectedElements.push(elementName);
    }
  } else {
    if (elementIsAlreadySelected && numberOfSelections === 1 && isLeftClick) {
      newSelectedElements.splice(i, 1);
    } else if (elementIsAlreadySelected && numberOfSelections > 1 && !isLeftClick) {
      newSelectedElements = [...selectedElements];
    } else {
      newSelectedElements.push(elementName);
    }
  }

  if (equal(selectedElements, newSelectedElements)) {
    // no need to do anything if we end up with exact same selection as before
    return;
  }

  // Place all descendants into an initial pending state.
  getAllChildren(
    getState().workspace.parentToChildRelationship,
    sequenceId,
    getState().workspace.data,
  ).forEach(seqId => {
    dispatch(
      addReportPendingRequest(
        seqId,
        INITIAL_PENDING_STATUS,
        ReportRequestStatus.PENDING,
        ReportUpdateHighlightMode.REPORT_CHANGE,
      ),
    );
  });

  // updates selectedElements redux workspace state of the report that received a new selection
  dispatch({
    type: ActionTypes.UPDATE_WORKSPACE_REPORT_SELECTION,
    payload: {
      sequenceId,
      newSelectedElements,
    },
  });

  dispatch(resetChildren(sequenceId));
  dispatch(triggerChildUpdate(sequenceId, true, newSelectedElements));
};

export const updateWhatIfStrategy = (reportDetails): AppThunk => (dispatch, getState) => {
  const requestId = `seq-${reportDetails.sequenceId}-req-${nextRequestId()}`;
  // dispatch a report pending message
  dispatch(
    addReportPendingRequest(
      reportDetails.sequenceId,
      requestId,
      ReportRequestStatus.UPDATING,
      ReportUpdateHighlightMode.REPORT_CHANGE,
    ),
  );

  // add whatIfParameters to report state
  dispatch({
    type: ActionTypes.ADD_REPORT_WHAT_IF_PARAMS,
    payload: {
      sequenceId: reportDetails.sequenceId,
      whatIfParameters: reportDetails.whatIfParameters,
    },
  });

  // send a message to trigger report regeneration due to what if strategy
  // TODO the message format needs to be updated (right now ReportDetails is expected)

  const slotUpdate = {
    horizontalPosition: reportDetails.slot.horizontalPosition,
    verticalPosition: reportDetails.slot.verticalPosition,
    changedValue: reportDetails.whatIfParameters,
    complicationId: reportDetails.slot.complicationDetails.id,
  };

  sendStompMessage(
    {
      requestType: 'generate',
      ...reportDetails,
      slotUpdate,
      requestId,
      highlightMode: 'REPORT_CHANGE',
      ...getCommonRequestProperties(getState()),
      reportDefinition: getState().report.reportDefinition[reportDetails.sequenceId],
    },
    getState().user.tk || localStorage.getItem('id_token'),
    getNumPendingRequests(getState().report.reportData),
  ).catch((error: Error) => {
    dispatch(reportFailed(reportDetails.sequenceId, error.message));
  });
};

export const setGlobalOfflineFlag = (offlineFlag: boolean): AnyAction => ({
  type: ActionTypes.SET_GLOBAL_OFFLINE_FLAG,
  payload: { offlineFlag: offlineFlag },
});

export const retrieveReport = reportId => ({
  type: ActionTypes.REPORT_FETCH_REQUESTED,
  payload: { reportId: reportId },
});

export const fetchCurrencies = (): AppThunk => async dispatch =>
  axios
    .get<string[]>(`${baseUrl}api/currencies`)
    .then(currencies => dispatch(updateCurrencies(currencies.data)))
    .catch(error => {
      dispatch(
        enqueueSnackbar(
          NotificationLevel.ERROR,
          `Warning: Unable to retrieve currencies: ${getErrorMessage(error)}`,
        ),
      );
    });

export const updateCurrencies = (currencies: string[]) =>
  ({
    type: ActionTypes.UPDATE_CURRENCIES,
    payload: currencies,
  } as const);

export const _updatePinWorkspaceDrawer = (toggle: boolean) =>
  ({
    type: ActionTypes.UPDATE_PIN_WORKSPACE_DRAWER,
    payload: toggle,
  } as const);

export const updatePinWorkspaceDrawer = (toggle: boolean): AppThunk => dispatch => {
  dispatch(_updatePinWorkspaceDrawer(toggle));
  axios
    .post(`${baseUrl}api/updatePinWorkspaceDrawer`, null, {
      params: { pinWorkspaceDrawer: toggle },
    })
    .catch(error => {
      dispatch(_updatePinWorkspaceDrawer(!toggle));
      dispatch(
        enqueueSnackbar(
          NotificationLevel.ERROR,
          `Warning: Unable to pin workspace drawer: ${getErrorMessage(error)}`,
        ),
      );
    });
};

export const updateWorkspaceCurrency = (currency: string) => ({
  type: ActionTypes.UPDATE_WORKSPACE_CURRENCY,
  payload: { currency },
});

export const updateSelectedPortfolioHierarchyName = (
  selectedPortfolioHierarchyName: string | null,
) => ({
  type: ActionTypes.UPDATE_SELECTED_PORTFOLIO_HIERARCHY_NAME,
  payload: selectedPortfolioHierarchyName,
});

export const updateCurrency = (currency: string): AppThunk => (dispatch, getState) => {
  const previousCurrency = getState().user.userInfo?.userPreferences?.selectedCurrency;

  dispatch(updateWorkspaceCurrency(currency));

  axios
    .post(`${baseUrl}api/updateCurrencyPreference`, null, {
      params: { ccy: currency },
    })
    .then(() => {
      // walk reports in the tab with tabId and trigger a report regeneration for top level reports only
      // (children will update as a result of parent update)
      triggerReportRegen(
        getState().workspace.data,
        {
          currency,
          dateContext: getState().user.selectedDateContext,
          adhoc: true,
        },
        dispatch,
        sequenceId => getState().report.reportDefinition[sequenceId].currency === 'default',
      );
    })
    .catch(error => {
      // Revert to previous ccy
      dispatch({
        type: ActionTypes.UPDATE_WORKSPACE_CURRENCY,
        payload: { currency: previousCurrency },
      });

      dispatch(
        enqueueSnackbar(
          NotificationLevel.ERROR,
          `Warning: Unable to update currency selection: ${getErrorMessage(error)}`,
        ),
      );
    });
};

export const fetchDateContexts = (): AppThunk => async dispatch =>
  axios
    .get<DateContexts>(`${baseUrl}api/publishedContexts`)
    .then(response => dispatch(updateDateContexts(response.data)))
    .catch(error => {
      dispatch(
        enqueueSnackbar(
          NotificationLevel.ERROR,
          `Warning: Unable to retrieve date contexts: ${getErrorMessage(error)}`,
        ),
      );
    });

export const updateDateContexts = (dateContexts: DateContexts) =>
  ({
    type: ActionTypes.UPDATE_DATE_CONTEXTS,
    payload: dateContexts,
  } as const);

export const updateDateContext = (dateContext: FullDateContextItem): AppThunk => (
  dispatch,
  getState,
) => {
  dispatch({
    type: ActionTypes.UPDATE_SELECTED_DATE_CONTEXT,
    payload: { dateContext },
  } as const);

  if (getState().user.userInfo?.userPreferences)
    // walk reports in the tab with tabId and trigger a report regeneration for top level reports only
    // (children will update as a result of parent update)
    triggerReportRegen(
      getState().workspace.data,
      {
        dateContext,
        currency: getState().user.userInfo.userPreferences.selectedCurrency,
        adhoc: true,
      },
      dispatch,
      sequenceId => getState().report.reportDefinition[sequenceId].dateContext.type === 'default',
    );
};

export const updateSelectedDateContextRun = (id: string): AppThunk => (dispatch, getState) =>
  dispatch(updateDateContext({ ...getState().user.selectedDateContext, id }));

export const publishDateContexts = (
  date: string,
  id: string,
  published: boolean,
): AppThunk => async dispatch =>
  axios
    .put(`${baseUrl}api/publishedContexts`, { date, id, published })
    .then(() => {
      dispatch(fetchDateContexts());
      dispatch(
        enqueueSnackbar(
          NotificationLevel.SUCCESS,
          published ? 'Published successfully' : 'Unpublished successfully',
        ),
      );
    })
    .catch(error => {
      dispatch(
        enqueueSnackbar(
          NotificationLevel.ERROR,
          `Error publishing date context: ${getErrorMessage(error)}`,
        ),
      );
    });

export const toggleSidePanel = (
  open: boolean,
  slot: UISlot,
  reportDetails?: ReportRequest,
  positions?: [][],
): AnyAction => ({
  type: ActionTypes.TOGGLE_SIDE_PANEL,
  payload: {
    open,
    reportDetails,
    positions,
    slot,
  },
});

export const toggleReportLiveStatus = (
  sequenceId: number,
  live: boolean,
  parentSelectedElements: string[],
): AppThunk => (dispatch, getState) => {
  dispatch({
    type: ActionTypes.TOGGLE_REPORT_LIVE_STATUS,
    payload: {
      sequenceId,
      live: !live,
      parentSelectedElements,
    },
  });
  const requestId = `seq-${sequenceId}-req-${nextRequestId()}`;

  if (!live) {
    // we are going from non-live to live, register pending operation
    dispatch(
      addReportPendingRequest(
        sequenceId,
        requestId,
        ReportRequestStatus.REFRESHING,
        ReportUpdateHighlightMode.REPORT_CHANGE,
      ),
    );

    const reportRequest: ReportRequest = {
      ...getReportWorkspacePayload(sequenceId, getState()),
      parentSelectedElements,
      requestId,
      currency: getState().user.userInfo.userPreferences.selectedCurrency,
      dateContext: getState().user.selectedDateContext,
      reportDefinition: getState().report.reportDefinition[sequenceId],
    };

    // and send a message to trigger report regeneration due to toggling to live
    sendStompMessage(
      {
        ...reportRequest,
        requestType: 'generate',
      },
      getState().user.tk || localStorage.getItem('id_token'),
      getNumPendingRequests(getState().report.reportData),
    ).catch((error: Error) => {
      dispatch(reportFailed(sequenceId, error.message));
    });
  }
};

export const setShouldRegenerate = (shouldRegenerate: boolean) =>
  ({
    type: ActionTypes.SET_SHOULD_REGENERATE,
    payload: shouldRegenerate,
  } as const);

export const setHasGenerated = (hasGenerated: boolean) =>
  ({ type: ActionTypes.SET_HAS_GENERATED, payload: hasGenerated } as const);

export const regenerateIfAuto = (): AppThunk => (dispatch, getState) =>
  isDesignerOpen(getState()) &&
  dispatch(setShouldRegenerate(getState().reportDesigner.panelControl.isAutoGenerate));

export const setOriginalDetachedReport = (reportDefinition?: ReportDefinition) =>
  ({
    type: ActionTypes.SET_ORIGINAL_DETACHED_REPORT,
    payload: reportDefinition,
  } as const);

export const setIsAutoGenerate = (isAutoGenerate: boolean) =>
  ({
    type: ActionTypes.SET_IS_AUTOGENERATE,
    payload: isAutoGenerate,
  } as const);

export const setCharToNickname = (payload?: Characteristic) =>
  ({ type: ActionTypes.SET_CHAR_TO_NICKNAME, payload } as const);

export const generateIfAuto = (): AppThunk => (dispatch, getState) =>
  getState().report.reportDefinition[DESIGNER_SEQUENCE_ID]?.chars.length >= 1 &&
  getState().reportDesigner.panelControl.isAutoGenerate &&
  dispatch(generateReportPreview());

export const generateReportPreview = (): AppThunk => (dispatch, getState) => {
  let message: ReportRequest;

  if (getState().reportDesigner.panelControl.source === DesignerSource.OPEN_WITH_DRILL_THROUGH) {
    const {
      parentSequenceId,
      parentSelectedElements,
    } = getState().reportDesigner.panelControl.drillThrough;

    message = {
      sequenceId: DESIGNER_SEQUENCE_ID,
      requestId: `seq-${DESIGNER_SEQUENCE_ID}-req-${nextRequestId()}`,
      parentSequenceId,
      parentSelectedElements,
      detailList: false,
      drillThrough: true,
      legacyReport: false,
      adhoc: true,
    };
  } else {
    const wp = getReportWorkspacePayload(
      getState().reportDesigner.panelControl.sourceSequenceId,
      getState(),
    );
    const sandbox = wp?.sandbox?.path ? wp.sandbox : null;
    let parentSelectedElements: string[];

    if (wp && wp.drillThrough) {
      if (wp.live) {
        // We need to find out the parent current selection
        const parentWP = getReportWorkspacePayload(wp.parentSequenceId, getState());
        parentSelectedElements = parentWP.selectedElements;
      } else {
        parentSelectedElements = wp.parentSelectedElements;
      }
    }

    message = {
      sequenceId: DESIGNER_SEQUENCE_ID,
      requestId: `seq-${DESIGNER_SEQUENCE_ID}-req-${nextRequestId()}`,
      parentSequenceId: wp?.drillThrough ? wp.parentSequenceId : DESIGNER_SEQUENCE_ID,
      parentSelectedElements,
      detailList: false,
      drillThrough: wp?.drillThrough,
      legacyReport: false,
      adhoc: true,
      sandbox,
    };
  }

  dispatch(reportLoading(DESIGNER_SEQUENCE_ID));

  dispatch(
    sendReportUpdateMessage(
      message,
      ReportUpdateHighlightMode.REPORT_CHANGE,
      ReportRequestStatus.GENERATE,
    ),
  );

  dispatch(setHasGenerated(true));
};

export const enqueueSnackbar = (type: NotificationLevel, message: string) => {
  const variant: string = type;
  let autoHideDuration: number;
  let persist = false;
  switch (type) {
    case NotificationLevel.WARN:
      autoHideDuration = 10000;
      break;
    case NotificationLevel.ERROR:
      console.error(message);
      autoHideDuration = undefined;
      persist = true;
      break;
    case NotificationLevel.INFO:
      autoHideDuration = 3000;
      break;
    case NotificationLevel.SUCCESS:
    default:
      autoHideDuration = 5000;
      break;
  }
  return {
    type: ActionTypes.ENQUEUE_SNACKBAR,
    notification: {
      message,
      key: new Date().getTime() + Math.random(),
      options: {
        variant,
        autoHideDuration,
        persist,
      },
    },
  };
};

export const closeSnackbar = key => ({
  type: ActionTypes.CLOSE_SNACKBAR,
  dismissAll: !key, // dismiss all if no key has been defined
  key,
});

export const removeSnackbar = key => ({
  type: ActionTypes.REMOVE_SNACKBAR,
  key,
});

export const openFolderDrawer = (type: DrawerType, initialProps: Partial<FolderDrawerState> = {}) =>
  ({
    type: ActionTypes.OPEN_FOLDER_DRAWER,
    payload: {
      ...initialProps,
      type,
      sequenceId: initialProps.sequenceId ?? DESIGNER_SEQUENCE_ID,
    } as Partial<FolderDrawerState>,
  } as const);

export const closeFolderDrawer = () =>
  ({
    type: ActionTypes.CLOSE_FOLDER_DRAWER,
  } as const);

export const exportDrawerItem = (item?: FolderListRow): AppThunk => (dispatch, getState) => {
  const path = (item ?? getState().drawers.folderDrawer.selectedFolderItem)?.props.path;
  const type = getExportType(getState().drawers.folderDrawer.type ?? null);
  if (!path || !type) {
    return;
  }

  triggerJsonExport(path, type).catch(({ message }: Error) => {
    dispatch(enqueueSnackbar(NotificationLevel.ERROR, message));
  });
};

export const setSelectedWorkspaceAsDefault = (item?: FolderListRow): AppThunk => (
  dispatch,
  getState,
) => {
  const path = (item ?? getState().drawers.folderDrawer.selectedFolderItem)?.props.path;
  const defaultWorkspace = getState().user.userInfo.userPreferences?.defaultWorkspace;

  dispatch(updateDefaultWorkspace(path === defaultWorkspace ? null : path));
};

export const setSelectedFolderItem = (item: FolderListRow) =>
  ({
    type: ActionTypes.SET_SELECTED_FOLDER_ITEM,
    payload: item,
  } as const);

export const setApplyButtonDisabled = (disabled: boolean) =>
  ({
    type: ActionTypes.SET_APPLY_BUTTON_DISABLED,
    payload: disabled,
  } as const);

export const disableIfSelectionNotVisible = (dataSource: NodeWithParentId[]): AppThunk => (
  dispatch,
  getState,
) => {
  const { selectedFolderItem } = getState().drawers.folderDrawer;
  if (!selectedFolderItem || dataSource.find(f => f.id === selectedFolderItem.id))
    dispatch(setApplyButtonDisabled(false));
  else dispatch(setApplyButtonDisabled(true));
};

// If `path` is falsy, the default workspace will be cleared; otherwise, it will be set.
export const updateDefaultWorkspace = (
  path: string | null,
  dontFetchUserInfo?: boolean,
): AppThunk => dispatch => {
  axios
    .post(
      path
        ? `${baseUrl}api/defaultWorkspace?path=${encodeURIComponent(path)}`
        : `${baseUrl}api/defaultWorkspace`,
    )
    .then(() => {
      dispatch({
        type: ActionTypes.UPDATE_DEFAULT_WORKSPACE,
        payload: path ?? '',
      });
      if (!path && !dontFetchUserInfo) {
        dispatch(fetchUserInfo());
      }

      dispatch(
        enqueueSnackbar(NotificationLevel.INFO, `Default workspace ${path ? 'set' : 'cleared'}`),
      );
    })
    .catch(error => {
      dispatch(
        enqueueSnackbar(
          NotificationLevel.ERROR,
          `Unable to ${path ? 'set' : 'clear'} default workspace: ${getErrorMessage(error)}`,
        ),
      );
    });
};

/* *** DX Grid *** */

export const setDxHiddenColumnNames = (sequenceId: number, hiddenColumnNames: string[]) => ({
  type: ActionTypes.SET_DX_HIDDEN_COLUMN_NAMES,
  payload: {
    sequenceId,
    hiddenColumnNames,
  },
});

export const addAdHocReportToWorkspace = (sequenceId: number): AppThunk => (dispatch, getState) => {
  const { drillThrough } = getState().reportDesigner.panelControl;
  dispatch(
    addReportToWorkspace(
      null,
      !!drillThrough,
      false,
      drillThrough?.parentSequenceId ?? DESIGNER_SEQUENCE_ID,
      false,
      true,
      drillThrough?.parentSelectedElements || null,
      drillThrough?.newTab || false,
      getState().report.reportDefinition[DESIGNER_SEQUENCE_ID],
      sequenceId,
      true,
    ),
  );
};

export const setAllChars = (chars: Characteristic[]) =>
  ({
    type: ActionTypes.SET_ALL_CHARS,
    payload: chars,
  } as const);

export const loadAllChars = (): AppThunk => (dispatch, getState) => {
  isDesignerOpen(getState()) &&
    axios
      .get<Characteristic[]>(
        `${baseUrl}api/availableCharacteristics?reportableType=${
          getState().report.reportDefinition?.[DESIGNER_SEQUENCE_ID]?.reportableType
        }`,
      )
      .then(response => {
        const noGroupingChars = response.data.filter(
          c => !isGroupingLayer(c) && !isCharCustomGrouping(c),
        );
        dispatch(
          setAllChars(
            response.data
              .map(c => ({ ...c, modifier: Modifier.PORT }))
              .concat(
                noGroupingChars.map(c => ({
                  ...c,
                  name: c.benchName || c.name,
                  modifier: Modifier.BENCH,
                })),
              )
              .concat(
                noGroupingChars.map(c => ({
                  ...c,
                  name: c.diffName || c.name,
                  modifier: Modifier.DIFF,
                })),
              )
              .concat(
                noGroupingChars.map(c => ({
                  ...c,
                  name: c.benchName || c.name,
                  modifier: Modifier.BENCH2,
                })),
              )
              .concat(
                noGroupingChars.map(c => ({
                  ...c,
                  name: c.diffName || c.name,
                  modifier: Modifier.DIFF2,
                })),
              )
              .map(char => ({ ...char, draggableId: v4() })),
          ),
        );

        dispatch(updateCustomGroupingNames());
      })
      .catch((error: Error) =>
        dispatch(
          enqueueSnackbar(
            NotificationLevel.ERROR,
            `Failed to load characteristics from server: ${error.message}`,
          ),
        ),
      );
};

// Update the names in the chars in the report definition when a CG has been renamed
const updateCustomGroupingNames = (): AppThunk => (dispatch, getState) => {
  const { chars, verticalChars, horizontalChars } = getState().report.reportDefinition[
    DESIGNER_SEQUENCE_ID
  ];

  const renamer = customGroupingRenamer(
    getState().reportDesigner.panelControl.allChars.filter(
      a => a.charId === GroupingLayerId.CUSTOM_GROUPING,
    ),
  );

  dispatch(
    setReportDefinitionCharacteristics(
      chars,
      verticalChars.map(renamer),
      horizontalChars.map(renamer),
    ),
  );
};

const customGroupingRenamer = (allCustomGroupings: Characteristic[]) => (
  v: LayerDefinition,
): LayerDefinition =>
  v.customGrouping && v.layerId === GroupingLayerId.CUSTOM_GROUPING
    ? ({
        ...v,
        customGrouping: {
          ...v.customGrouping,
          name:
            allCustomGroupings.find(a => a.id === v.customGrouping.id)?.name ||
            v.customGrouping.name,
        },
      } as LayerDefinition) // TODO this is a crime against TypeScript
    : v;

export const addFilter = (char: Characteristic, endIndex: number) =>
  ({
    type: ActionTypes.ADD_FILTER,
    payload: { char, endIndex },
  } as const);

export const removeFilter = (charId: number) =>
  ({
    type: ActionTypes.REMOVE_FILTER,
    payload: charId,
  } as const);

export const removeAllFilters = () => ({ type: ActionTypes.REMOVE_ALL_FILTERS } as const);

export const reorderFilter = (startIndex: number, endIndex: number) =>
  ({
    type: ActionTypes.REORDER_FILTER,
    payload: { startIndex, endIndex },
  } as const);

export const updateFilterType = (charId: number, filterType: FilterType) =>
  ({
    type: ActionTypes.UPDATE_FILTER_TYPE,
    payload: { charId, filterType },
  } as const);

export const setSelectedFilterOptions = (charId: number, options: OptionTypes[]) =>
  ({
    type: ActionTypes.SET_SELECTED_FILTER_OPTIONS,
    payload: { charId, options },
  } as const);

export const setNot = (charId: number, value: boolean) =>
  ({
    type: ActionTypes.SET_NOT,
    payload: { charId, value },
  } as const);

export const setNull = (charId: number, value: boolean) =>
  ({
    type: ActionTypes.SET_NULL,
    payload: { charId, value },
  } as const);

export const fetchFilterOptions = (
  setFilterOptions: (filterOptions: FilterOptions) => void,
): AppThunk => async (dispatch, getState) =>
  axios
    .post<FilterOptions>(`${baseUrl}api/filterValues`, {
      reportDefinition: getState().report.reportDefinition[DESIGNER_SEQUENCE_ID],
      asOfDate: getState().user.selectedDateContext,
      selectedPortfolioIds: getState().workspace.data?.selectedPortfolioNodeIds,
      selectedAdhocPortfolioIds: getState().workspace.data?.selectedAdHocPortfolioNames,
      selectedBenchmarkIds: getState().workspace.data?.selectedBenchmarkPortfolioNames,
    })
    .then(response => setFilterOptions(response.data))
    .catch(error =>
      dispatch(
        enqueueSnackbar(
          NotificationLevel.ERROR,
          `Unable to retrieve filter options: ${getErrorMessage(error)}`,
        ),
      ),
    );

export const copyPreviousStatesIntoTabs = (
  getScenarioSets: (scenariosConfig: ScenariosConfig) => void,
): AppThunk => (dispatch, getState) => {
  const def = getState().report.reportDefinition[DESIGNER_SEQUENCE_ID];
  const scenariosConfig = def?.scenariosConfig || createDefaultConfig();
  dispatch({
    reducerId: 'designer',
    type: ActionTypes.COPY_SCENARIOS_CONFIG,
    payload: scenariosConfig,
  });
  dispatch({
    type: ActionTypes.COPY_SETTINGS,
    payload: def?.settings || createDefaultSettings(),
  });
  dispatch({
    type: ActionTypes.COPY_BENCHMARKS,
    payload: def?.benchmarks || defaultReportDesignerBenchmarks,
  });

  getScenarioSets(scenariosConfig);
};

// This enforces that the propertyName is a valid property of ScenariosConfig, and that
// propertyValue is the right type for that property.
// https://stackoverflow.com/q/64242893/400765
type ScenariosConfigActionSpecific<K extends keyof ScenariosConfig> = {
  reducerId: ScenariosConfigReducerId;
  type: typeof ActionTypes.SET_SCENARIO_CONFIG_PROPERTY;
  payload: {
    propertyName: K;
    propertyValue: ScenariosConfig[K];
  };
};

type ScenariosConfigActionMap = {
  [K in keyof ScenariosConfig]: ScenariosConfigActionSpecific<K>;
};

export type ScenariosConfigAction = ScenariosConfigActionMap[keyof ScenariosConfig];

export const setScenarioConfigProperty = <K extends keyof ScenariosConfig>(
  reducerId: ScenariosConfigReducerId,
  propertyName: K,
  propertyValue: ScenariosConfig[K],
): ScenariosConfigActionSpecific<K> =>
  ({
    reducerId,
    type: ActionTypes.SET_SCENARIO_CONFIG_PROPERTY,
    payload: { propertyName, propertyValue },
  } as const);

export const setSetting = <K extends keyof Settings>(
  propertyName: K,
  propertyValue: Settings[K],
) => ({ type: ActionTypes.SET_SETTING, payload: { propertyName, propertyValue } });

export const setBenchmark = <K extends keyof Benchmark>(
  index: number,
  propertyName: K,
  propertyValue: Benchmark[K],
) => ({ type: ActionTypes.SET_BENCHMARK, payload: { index, propertyName, propertyValue } });

export const setBenchmarkDateContextRun = (index: number, id: string): AppThunk => (
  dispatch,
  getState,
) =>
  dispatch(
    setBenchmark(index, 'dateContextOption', {
      id,
      date: getState().reportDesigner.benchmarks[index].dateContextOption.date,
    }),
  );

export const setReportDesignerSettings = (settings: Settings) => ({
  type: ActionTypes.SET_REPORT_DESIGNER_SETTINGS,
  payload: { settings },
});

export const setDesignerWorkspacePayload = (workspacePayload: WorkspacePayload) =>
  ({
    type: ActionTypes.SET_DESIGNER_WORKSPACE_PAYLOAD,
    payload: workspacePayload,
  } as const);

export const setReportDesignerBenchmarks = (benchmarks: Benchmark[]) => ({
  type: ActionTypes.SET_REPORT_DESIGNER_BENCHMARKS,
  payload: { benchmarks },
});

export const setReportDesignerScenarioConfig = (scenariosConfig: ScenariosConfig) =>
  ({
    type: ActionTypes.SET_REPORT_DESIGNER_SCENARIO_CONFIG,
    payload: scenariosConfig,
    name: 'designer',
  } as const);

export const openBenchmarkPortfolioDrawer = (sequenceId: number) =>
  ({ type: ActionTypes.OPEN_BENCHMARK_PORTFOLIO_DRAWER, payload: sequenceId } as const);

export const closeBenchmarkPortfolioDrawer = () =>
  ({ type: ActionTypes.CLOSE_BENCHMARK_PORTFOLIO_DRAWER } as const);

export const openReportPortfolioDrawer = (sequenceId: number) =>
  ({ type: ActionTypes.OPEN_REPORT_PORTFOLIO_DRAWER, payload: sequenceId } as const);

export const closeReportPortfolioDrawer = () =>
  ({ type: ActionTypes.CLOSE_REPORT_PORTFOLIO_DRAWER } as const);

export const openReportEntitiesDrawer = (sequenceId: number) =>
  ({ type: ActionTypes.OPEN_REPORT_ENTITIES_DRAWER, payload: sequenceId } as const);

export const closeReportEntitiesDrawer = () =>
  ({ type: ActionTypes.CLOSE_REPORT_ENTITIES_DRAWER } as const);

export const openProxyDrawer = (
  sequenceId: number,
  positionToEdit?: string,
  initialPosition?: ProxyConfigDto,
) =>
  ({
    type: ActionTypes.OPEN_PROXY_DRAWER,
    payload: { sequenceId, positionToEdit, initialPosition },
  } as const);

export const _closeProxyDrawer = () =>
  ({
    type: ActionTypes.CLOSE_PROXY_DRAWER,
  } as const);

export const closeProxyDrawer = (): AppThunk => dispatch => {
  dispatch(_closeProxyDrawer());
  dispatch(setFoliaSyntaxGuideOpen(false));
};

export const setUserComponentPermissions = (permissions: ComponentPermission) =>
  ({ type: ActionTypes.UPDATE_USER_COMPONENT_PERMISSION, payload: permissions } as const);

export const fetchUserComponentPermission = (): AppThunk => (dispatch, getState) => {
  if (isOidcClientEnabled || isPsbcPortalEnabled) {
    // this can be null if the token is being/scheduled to be refreshed or if it is just before it expires
    const expIn =
      getState().user?.expiresIn?.() ??
      (getState().user?.expiresAtMs ? (getState().user?.expiresAtMs - Date.now()) / 1000 : null);

    const before = isOidcClientEnabled
      ? oidcClientRefreshSecondsBeforeExpire
      : psbcPortalRefreshSecondsBeforeExpire;

    if (!expIn || expIn <= before) {
      // don't re-request if within the token refresh window
      return;
    }
  }
  axios
    .get(`${baseUrl}api/userComponentPermissions`)
    .then(response => dispatch(setUserComponentPermissions(response.data)))
    .catch(
      error =>
        wsConnected() &&
        dispatch(
          enqueueSnackbar(
            NotificationLevel.ERROR,
            `Warning: Unable to retrieve userComponentPermissions: ${getErrorMessage(error)}`,
          ),
        ),
    );
};

const postExpandedPreference = (endpoint: string, payload: string[]): AppThunk => dispatch =>
  axios
    .post(`${baseUrl}api/${endpoint}`, payload)
    .catch(() =>
      dispatch(
        enqueueSnackbar(NotificationLevel.ERROR, 'An error occurred while saving preferences.'),
      ),
    );

export const setActivePortfolioSelection = (selectedPortfolios: SelectedPortfolios | null) =>
  ({
    type: ActionTypes.SET_ACTIVE_PORTFOLIO_SELECTION,
    payload: selectedPortfolios,
  } as const);

export const _setDefaultPortfolioSelection = (selectedPortfolios: SelectedPortfolios | null) =>
  ({ type: ActionTypes.SET_DEFAULT_PORTFOLIO_SELECTION, payload: selectedPortfolios } as const);

export const setDefaultPortfolioSelection = (
  selectedPortfolios: SelectedPortfolios | null,
): AppThunk => dispatch => {
  axios
    .post(`${baseUrl}api/selectedPortfolios`, selectedPortfolios)
    .then(() => {
      dispatch(_setDefaultPortfolioSelection(selectedPortfolios));
    })
    .catch(error => {
      dispatch(
        enqueueSnackbar(
          NotificationLevel.ERROR,
          `Warning: Unable to update default portfolio selection: ${getErrorMessage(error)}`,
        ),
      );
    });
};

export const setExpandedPortfolios = (payload: string[]) =>
  ({ type: ActionTypes.SET_EXPANDED_PORTFOLIOS, payload } as const);

export const requestSetExpandedPortfolios = (
  dataSource: NodeWithParentId[],
  payload: string[],
): AppThunk => dispatch => {
  const filterPayload =
    payload.length !== 0
      ? payload.filter(item => {
          const node = dataSource.find(node => node.id === item);
          return node?.parentId === 0 || payload.includes(`${node?.parentId}`);
        })
      : [];

  dispatch(setExpandedPortfolios(filterPayload));
  dispatch(postExpandedPreference('expandedPortfolios', filterPayload));
};

export const setExpandedAdminPortfolios = (payload: string[]) =>
  ({ type: ActionTypes.SET_EXPANDED_ADMIN_PORTFOLIOS, payload } as const);

export const requestSetExpandedAdminPortfolios = (
  dataSource: NodeWithParentId[],
  payload: string[],
): AppThunk => dispatch => {
  const filterPayload =
    payload.length !== 0
      ? payload.filter(item => {
          const node = dataSource.find(node => node.id === item);
          return node?.parentId === 0 || payload.includes(`${node?.parentId}`);
        })
      : [];

  dispatch(setExpandedAdminPortfolios(filterPayload));
  dispatch(postExpandedPreference('expandedAdminPortfolios', filterPayload));
};

export const setExpandedReportFolders = (payload: string[]) =>
  ({ type: ActionTypes.SET_EXPANDED_REPORT_FOLDERS, payload } as const);

export const requestSetExpandedReportFolders = (payload: string[]): AppThunk => dispatch => {
  dispatch(setExpandedReportFolders(payload));
  dispatch(postExpandedPreference('expandedReportFolders', payload));
};

export const setExpandedWorkspaceFolders = (payload: string[]) =>
  ({ type: ActionTypes.SET_EXPANDED_WORKSPACE_FOLDERS, payload } as const);

export const requestSetExpandedWorkspaceFolders = (payload: string[]): AppThunk => dispatch => {
  dispatch(setExpandedWorkspaceFolders(payload));
  dispatch(postExpandedPreference('expandedWorkspaceFolders', payload));
};

export const setDrawerWidths = (payload: DrawerWidths) =>
  ({ type: ActionTypes.SET_DRAWER_WIDTHS, payload } as const);

export const requestSetDrawerWidths = (drawerId: string, width: number): AppThunk => (
  dispatch,
  getState,
) => {
  const drawerWidths: DrawerWidths = {
    ...(getState().user.userInfo?.userPreferences?.drawerWidths || {}),
    [drawerId]: width,
  };
  dispatch(setDrawerWidths(drawerWidths));
  axios
    .post(`${baseUrl}api/drawerWidths`, drawerWidths)
    .catch(() =>
      dispatch(
        enqueueSnackbar(NotificationLevel.ERROR, 'An error occurred while saving drawer widths.'),
      ),
    );
};

export const generateMetadataReport = (metadataChars: number[]): AppThunk => (
  dispatch,
  getState,
) => {
  dispatch(
    setReportDefinition(METADATA_SEQUENCE_ID, {
      ...defaultMetadataReportDefinition,
      chars: metadataChars.map(charId => ({ charId, modifier: 0 })),
    }),
  );

  dispatch(
    sendReportUpdateMessage(
      createMetadataRequest(getMetadataSandbox(getState())),
      ReportUpdateHighlightMode.REPORT_CHANGE,
      ReportRequestStatus.GENERATE,
      true,
    ),
  );
};

export const updateMetadataSort = (sort: Sort[] = createDefaultSort()): AppThunk => (
  dispatch,
  getState,
) => {
  dispatch(updateAndSendSortDefinition(METADATA_SEQUENCE_ID, sort));
};

export const updateMetadataSandbox = (path: string | null): AppThunk => (dispatch, getState) => {
  dispatch(setSandbox(getSandbox(getState(), path)));
  dispatch(
    sendReportUpdateMessage(
      createMetadataRequest(getMetadataSandbox(getState())),
      ReportUpdateHighlightMode.REPORT_CHANGE,
      ReportRequestStatus.REGENERATING,
      true,
    ),
  );
};

const createMetadataRequest = (sandbox?: Sandbox | null): AdhocReportRequest => ({
  sequenceId: METADATA_SEQUENCE_ID,
  requestId: `seq-${METADATA_SEQUENCE_ID}-req-${nextRequestId()}`,
  legacyReport: false,
  adhoc: true,
  ...(sandbox ? { sandbox } : {}),
});

const getAllChildren = (
  relationships: ParentToChildRelationship,
  sequenceId: number,
  workspaceState: WorkspaceData,
) =>
  (relationships[sequenceId] ?? []).reduce<number[]>((ids, childId) => {
    const tabId = findTabId(childId, workspaceState);
    const reportWorkspacePayload = Object.values(workspaceState.tabs[tabId].reports).find(
      report => report.sequenceId === childId,
    );
    return reportWorkspacePayload.live
      ? [...ids, childId, ...getAllChildren(relationships, childId, workspaceState)]
      : [];
  }, []);
