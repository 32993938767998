import { createObject } from '../../shared/utils';
import {
  CharacteristicGrouping,
  CharacteristicGroupingStrategy,
  GroupingStrategy,
  GroupingStrategyType,
} from './groupingStrategy';

export enum GroupingTreeErrorType {
  // Global scope
  TREE_HEIGHT_ZERO,

  // Sibling scope
  DUPLICATE_SIBLING_NAME,

  // `CharacteristicStrategy` local scope
  NO_GROUPING, // Not possible until if/when we support multiple groupings
  NO_CHARACTERISTIC,
  INVALID_BREAKPOINTS,
}

export const getMessage = (() => {
  const errorMessages = {
    [GroupingTreeErrorType.TREE_HEIGHT_ZERO]: 'Total node cannot be empty',
    [GroupingTreeErrorType.DUPLICATE_SIBLING_NAME]: 'Siblings cannot share a name',
    [GroupingTreeErrorType.NO_GROUPING]: 'At least one grouping must be specified',
    [GroupingTreeErrorType.NO_CHARACTERISTIC]: 'A characteristic must be specified',
    [GroupingTreeErrorType.INVALID_BREAKPOINTS]:
      'This characteristic must have breakpoints that are not empty',
  };

  return (errorType: GroupingTreeErrorType) => errorMessages[errorType];
})();

export interface GroupingTreeErrors {
  globalErrors: GroupingTreeErrorType[];
  nodeErrors: {
    [nodeId: string]: GroupingTreeErrorType[];
  };
}

export const createTrivialErrors = (): GroupingTreeErrors => ({
  globalErrors: [],
  nodeErrors: {},
});

export const createNodeErrorsForIds = (
  ids: string[] = [],
  ...errorTypes: GroupingTreeErrorType[]
): GroupingTreeErrors['nodeErrors'] =>
  ids.reduce<GroupingTreeErrors['nodeErrors']>(
    (nodeErrors, id) => ({
      ...nodeErrors,
      [id]: errorTypes,
    }),
    {},
  );

export const combineNodeErrors = (
  ...nodeErrors: Array<GroupingTreeErrors['nodeErrors']>
): GroupingTreeErrors['nodeErrors'] =>
  nodeErrors.length === 0
    ? {}
    : createObject({
        items: Object.keys(nodeErrors[0]),
        createValue: id => nodeErrors.flatMap(errors => errors[id] ?? []),
      });

export const isValid = ({ globalErrors, nodeErrors }: GroupingTreeErrors) =>
  globalErrors.length === 0 &&
  Object.values(nodeErrors).every(errorTypes => errorTypes.length === 0);

/**
 * `GroupingStrategy` validation
 */

export const validateGroupingStrategy = (
  strategy: GroupingStrategy | null,
): GroupingTreeErrorType[] => {
  if (strategy === null) {
    return [];
  }

  switch (strategy.type) {
    case GroupingStrategyType.PREDICATE: {
      return [];
    }

    case GroupingStrategyType.CHARACTERISTIC: {
      return validateCharacteristicStrategy(strategy);
    }

    case GroupingStrategyType.REMAINDER: {
      return [];
    }
  }
};

export const validateCharacteristicStrategy = (strategy: CharacteristicGroupingStrategy) =>
  strategy.groupings.length === 0
    ? [GroupingTreeErrorType.NO_GROUPING]
    : validateGrouping(strategy.groupings[0]);

export const validateGrouping = ({
  characteristicId,
  breakpoints,
}: CharacteristicGrouping): GroupingTreeErrorType[] =>
  characteristicId === null
    ? [GroupingTreeErrorType.NO_CHARACTERISTIC]
    : breakpoints?.includes('')
    ? [GroupingTreeErrorType.INVALID_BREAKPOINTS]
    : [];
