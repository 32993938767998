import { Location, LocationState } from 'history';
import { useLocation } from 'react-router-dom';

// `useHistory` becomes desynced from `window.location` during the
// OIDC login process, so we manually shim in the correct URL.
const useNormalizedLocation = <S = LocationState>(): Location<S> => ({
  ...useLocation<S>(),
  pathname: window.location.pathname,
});

export default useNormalizedLocation;
