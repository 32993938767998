import { FC, useEffect, useState } from 'react';
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  XAxisProps,
  YAxis,
  YAxisProps,
} from 'recharts';
import { BaseAxisProps } from 'recharts/types/util/types';
import { paletteM50 as colors } from '../shared/colorPalettes';
import { legendWrapperStyles } from '../shared/constants';
import { DataConverters } from '../shared/dataConverters';
import { ReportConvertedData, ReportProps } from '../shared/dataTypes';
import { getExtrema, useChartOpacity } from './helpers/chartingHelpers';

const XYLineChartViz: FC<ReportProps> = props => {
  const [opacity, , onChartEnter, onChartLeave] = useChartOpacity({});
  const [reportConvertedData, setReportConvertedData] = useState<ReportConvertedData>();

  useEffect(() => setReportConvertedData(DataConverters.LINE_GRAPH(props.reportRawData)), [
    props.reportRawData,
  ]);

  if (props.width <= 0 || props.height <= 0 || !reportConvertedData) {
    return null;
  }

  const independentDataKey = reportConvertedData.groupingHeader.name;

  const axisProps: Record<'independent' | 'dependent', BaseAxisProps> = {
    independent: {
      type: 'number',
      dataKey: independentDataKey,
      domain: getExtrema(reportConvertedData.rows.map(datum => datum[independentDataKey])),
    },
    dependent: {
      type: 'number',
    },
  };

  const { xAxisProps, yAxisProps } = {
    xAxisProps: (!props.isInverted ? axisProps.independent : axisProps.dependent) as XAxisProps,
    yAxisProps: (!props.isInverted ? axisProps.dependent : axisProps.independent) as YAxisProps,
  };

  const lineCharts = reportConvertedData.headers.map((el, index) => (
    <Line
      key={index}
      type='monotone'
      dataKey={el}
      stroke={colors[index]}
      strokeOpacity={opacity[el] || 1}
    />
  ));

  return (
    <LineChart
      layout={props.isInverted ? 'vertical' : 'horizontal'}
      width={props.width - 50}
      height={props.height - 50}
      data={reportConvertedData.rows}
      margin={{ left: 30 }}
      onClick={event =>
        event &&
        props.onElementClick(
          event.activePayload[0].payload[`${event.activePayload[0].name}rowcol`],
        )(event)
      }
    >
      <CartesianGrid strokeDasharray='3 3' />
      <XAxis {...xAxisProps} />
      <YAxis {...yAxisProps} />
      <Tooltip formatter={(value, name, props) => props.payload[`${name}Formatted`]} />
      <Legend
        layout='vertical'
        align='right'
        verticalAlign='top'
        wrapperStyle={legendWrapperStyles.vertical}
        onMouseEnter={onChartEnter(reportConvertedData.headers)}
        onMouseLeave={onChartLeave}
      />
      {lineCharts}
    </LineChart>
  );
};

export default XYLineChartViz;
