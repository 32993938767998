import { DrawerState } from '../shared/dataTypes';
import { openProxyDrawer, _closeProxyDrawer } from './ActionCreators';
import { CLOSE_PROXY_DRAWER, OPEN_PROXY_DRAWER } from './ActionTypes';

type Action = ReturnType<typeof openProxyDrawer> | ReturnType<typeof _closeProxyDrawer>;

export const proxyDrawer = (
  state: DrawerState = { open: false, sequenceId: undefined },
  action: Action,
): DrawerState => {
  switch (action.type) {
    case OPEN_PROXY_DRAWER:
      return {
        open: true,
        sequenceId: action.payload.sequenceId,
        positionToEdit: action.payload.positionToEdit,
        initialPosition: action.payload.initialPosition,
      };
    case CLOSE_PROXY_DRAWER:
      return {
        open: false,
        sequenceId: undefined,
        positionToEdit: undefined,
        initialPosition: undefined,
      };
    default:
      return state;
  }
};
