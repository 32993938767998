import { RowField } from 'algo-react-dataviz';
import { Cell, Node, ReportRawDataNode } from '../shared/dataTypes';
import { AppState } from './configureStore';
import { getLastRow } from './ReportActionCreators';

export const needsFirst = (n: Node<Cell[]>, _expandedRowIds?: string[]): boolean => {
  const expandedRowIds = _expandedRowIds || n.props?.expandedRowIds;

  return (
    (0 < n.props?.numChildren && n.props?.rowIdStart !== n.children?.[0]?.payload[0][0]) ||
    (0 < n.children?.length &&
      (expandedRowIds || []).includes(n.children[0].payload[0][RowField.ROW_HASH]) &&
      needsFirst(n.children[0], expandedRowIds))
  );
};

const needsLast = (n: Node<Cell[]>) => {
  const lastRow = getLastRow(n);
  const parentOfLastRow = findNode(n, node => node.children?.some(c => c.id === lastRow.id));

  const lastRowNeedsChildren =
    n.props.expandedRowIds?.includes(lastRow.payload[0][RowField.ROW_HASH]) &&
    lastRow.props.numChildren > (lastRow.children?.length ?? 0);

  const lastRowNeedsSiblings =
    parentOfLastRow.props.rowIdStop !== lastRow.payload[0][RowField.ROW_HASH];

  return lastRowNeedsChildren || lastRowNeedsSiblings;
};

export const needsFirstLastData = (root: Node<Cell[]>) => ({
  first: needsFirst(root),
  last: needsLast(root),
});

export const treeSize = (root: Node<Cell[]>) => {
  let count: number = 0;
  if (root) {
    const children: Array<Node<Cell[]>> = root.children;
    const length: number = children?.length;
    if (-1 < length) {
      count += length;
      for (let i: number = 0; i < length; ++i) {
        const child: Node<Cell[]> = children[i];
        if (child) {
          if (0 < child.children?.length) {
            count += treeSize(child);
          }
        } else {
          // remove null/undefined element from count
          --count;
        }
      }
    }
  }
  return count;
};

const mergeChildren = (aOrig: Node<Cell[]>[], bOrig: Node<Cell[]>[]): Node<Cell[]>[] => {
  // Both a and b must be sorted in order of id. When there is an id found in both a and b, the
  // properties of a will be included in the returned value; the properties of b will be discarded.

  const returnable: Node<Cell[]>[] = [];
  const a = [...(aOrig || [])];
  const b = [...(bOrig || [])];

  while (a.length || b.length) {
    if (!a.length) {
      // Push all of b onto returnable and empty b. This is the last time through the loop.
      returnable.push(...b);
      b.splice(0, b.length);
    } else if (!b.length) {
      // Push all of a onto returnable and empty a. This is the last time through the loop.
      returnable.push(...a);
      a.splice(0, a.length);
    } else if (a[0].id === b[0].id) {
      // Remove a[0] and b[0] from a and b, recursively merge them, and add them to returnable.
      const a0 = a.shift();
      const b0 = b.shift();
      const firstItemWithChildrenMerged =
        a0.children?.length || b0.children?.length
          ? { ...a0, children: mergeChildren(a0.children, b0.children) }
          : a0;

      returnable.push(firstItemWithChildrenMerged);
    } else if (a[0].id < b[0].id) {
      // Remove a[0] from a and add it to returnable.
      returnable.push(a.shift());
    } else {
      // Remove b[0] from b and add it to returnable.
      returnable.push(b.shift());
    }
  }

  return returnable;
};

export const merge = (state: Node<Cell[]>, payload: ReportRawDataNode) => ({
  ...payload.data,
  children: mergeChildren(state.children, payload.data.children),
});

interface RowInfo {
  index: number;
  childrenIds: number[];
}

// Get a list of all the row indexes in memory, in order.
export const getAllRowIndexes = (data: Node<Cell[]>): RowInfo[] => [
  { index: data.id, childrenIds: data.children?.map(c => c.id) || [] },
  ...(data.children?.flatMap(c => getAllRowIndexes(c)) || []),
];

export const getAllRowUuids = (data: Node<Cell[]>): string[] => [
  ...(data.payload ? [data.payload[0][RowField.ROW_HASH]] : []),
  ...(data.children?.flatMap(c => getAllRowUuids(c)) || []),
];

export const getNumRows = (data: Node<Cell[]>): number =>
  data.children?.reduce<number>((acc, cur) => acc + getNumRows(cur), data.payload ? 1 : 0) || 1;

export const filterNode = (data: Node<Cell[]>, idsToExclude: number[]): Node<Cell[]> => ({
  ...data,
  children: data.children
    ?.filter(c => !idsToExclude.includes(c.id))
    .map(c => filterNode(c, idsToExclude)),
});

export const filterNodeCondition = (
  data: Node<Cell[]>,
  includeId: (rowId: number) => boolean,
): Node<Cell[]> => ({
  ...data,
  children: data.children?.filter(c => includeId(c.id)).map(c => filterNodeCondition(c, includeId)),
});

export const getEndOfIncompleteChildList = (
  data: Node<Cell[]>,
  direction: 'last' | 'first',
): Node<Cell[]> => {
  if (data.children?.length > 0) {
    const limitChild = data.children[direction === 'last' ? data.children.length - 1 : 0];
    const rowIdLimit = direction === 'last' ? data.props?.rowIdStop : data.props?.rowIdStart;

    return rowIdLimit !== limitChild.payload[0][RowField.ROW_HASH] && !limitChild.children?.length
      ? limitChild
      : data.children?.reduce(
          (acc, cur) => acc ?? getEndOfIncompleteChildList(cur, direction),
          undefined,
        );
  }
};

export const getNodeById = (data: Node<Cell[]>, rowId: string): Node<Cell[]> =>
  `${data.payload?.[0][0]}` === rowId
    ? data
    : data.children?.map(c => getNodeById(c, rowId)).find(c => c);

const findNode = (data: Node<Cell[]>, match: (n: Node<Cell[]>) => boolean): Node<Cell[]> =>
  match(data) ? data : data.children?.map(c => findNode(c, match)).find(c => c);

export const removeFromEnd = (data: Node<Cell[]>, n: number) =>
  filterNode(
    data,
    getAllRowIndexes(data)
      .slice(-1 * n)
      .map(c => c.index),
  );

const removeFirstLeafNode = (data: Node<Cell[]>) => {
  // WARNING: Mutates function parameter
  // For performance reasons, this function mutates data rather than using functional style.
  if (!data.children[0]?.children?.length) {
    // data.children[0] is a leaf node. Remove it.
    data.children.shift();
  } else {
    // data.children[0] is not a leaf node. Recursively remove the first leaf node in it.
    return removeFirstLeafNode(data.children[0]);
  }
};

export const removeFromBeginning = (dataOrig: Node<Cell[]>, n: number): Node<Cell[]> => {
  // For performance reasons, this function mutates data rather than using functional style.
  const data: Node<Cell[]> = JSON.parse(JSON.stringify(dataOrig));

  for (let i = 0; i < n; i++) {
    removeFirstLeafNode(data);
  }

  return data;
};

export const removeChildRows = (data: Node<Cell[]>, rowHash: string): Node<Cell[]> => ({
  ...data,
  props: {
    ...data.props,
    expandedRowIds:
      data.id === 0 ? data.props.expandedRowIds.filter(e => e !== rowHash) : undefined,
  },
  children:
    data.payload?.[0][RowField.ROW_HASH] === rowHash
      ? undefined
      : data.children?.map(c => removeChildRows(c, rowHash)),
});

export const getRowInfo = (state: AppState, sequenceId: number) => {
  const data = state.report.reportData[sequenceId]?.raw?.data;
  const ids = data ? getAllRowIndexes(data).filter(d => d.index !== 0) : [];

  return {
    ...ids.reduce(
      (acc, cur) => ({
        lowest: Math.min(acc.lowest, cur.index),
        highest: Math.max(acc.highest, cur.index),
      }),
      { lowest: Number.MAX_VALUE, highest: Number.MIN_VALUE },
    ),
    n: ids.length,
  };
};

export const isDataChunkingEnabled = (state: AppState) =>
  !!state.user.userInfo.serverConfigs?.blockSize;
