import { loader, Monaco, useMonaco } from '@monaco-editor/react';
import * as monaco from 'monaco-editor';
import { FC, PropsWithChildren, useEffect, useMemo, useRef, useState } from 'react';
import { MemoizedMonacoContext } from './useMemoizedMonaco';

// Avoid external CDN by specifying local package
loader.config({ monaco });

const MemoizedMonacoProvider: FC<PropsWithChildren> = props => {
  const monaco = useMonaco();
  const [isInitialized, setIsInitialized] = useState(false);
  const memoizedRef = useRef<Monaco | null>(null);

  useEffect(() => {
    if (!!monaco && memoizedRef.current === null) {
      setIsInitialized(true);
      memoizedRef.current = monaco;
    }
  }, [monaco]);

  // Re-evaluated exactly once when `isInitialized` is set
  const value: Monaco = useMemo(() => (isInitialized ? memoizedRef.current : null), [
    isInitialized,
  ]);

  return (
    <MemoizedMonacoContext.Provider {...{ value }}>{props.children}</MemoizedMonacoContext.Provider>
  );
};

export default MemoizedMonacoProvider;
