import deepEqual from 'deep-equal';
import { DESIGNER_SEQUENCE_ID } from '../../shared/constants';
import { isReservedSequenceId, resolveDefaultReportDefinition } from '../../shared/utils';
import { AppState } from '../configureStore';

export const isDesignerDirty = (state: AppState) => {
  const currentDefinition = state.report.reportDefinition[DESIGNER_SEQUENCE_ID];
  const matchingWorkspaceDefinition = Object.entries(state.report.reportDefinition).find(
    ([key, def]) => !isReservedSequenceId(Number(key)) && def.id === currentDefinition.id,
  );
  const baseReportDefinition = matchingWorkspaceDefinition
    ? matchingWorkspaceDefinition[1]
    : state.reportDesigner.panelControl.originalDetachedReport
    ? state.reportDesigner.panelControl?.originalDetachedReport
    : resolveDefaultReportDefinition(state);

  return !deepEqual(currentDefinition, baseReportDefinition);
};

export const getFilter = (state: AppState) => (charId: number) =>
  state.report.reportDefinition[DESIGNER_SEQUENCE_ID]?.filters.find(f => f.charId === charId);
