import {
  Action,
  combineReducers,
  configureStore,
  ThunkAction,
  ThunkDispatch,
} from '@reduxjs/toolkit';
import { Client, StompConfig } from '@stomp/stompjs';
import { TypedUseSelectorHook, useSelector } from 'react-redux';
import logger from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import {
  devEnvironment,
  nodesTopic,
  publishCSVTopic,
  publishEditTopic,
  publishPrintReportTopic,
  publishProgressTopic,
  publishProxyTopic,
  publishTopic,
  wsBaseUrl,
  WS_MAX_PENDING_REQUESTS,
} from '../components/shared/environment';
import {
  CancelReportGeneration,
  ChangeReportSequenceIdRequest,
  CSVRequest,
  EditRequest,
  NodeRequest,
  ProgressInfoRequest,
  ProxyRequest,
  QueuedMessage,
  ReportEditRequest,
  ReportNicknameRequest,
  ReportPrecisionUpdateRequest,
  ReportPrintRequest,
  ReportReleaseRequest,
  ReportRequest,
  ReportSortRequest,
} from '../shared/dataTypes';
import { clientUuid } from '../shared/utils';
import * as ActionTypes from './ActionTypes';
import admin from './admin';
import aScriptEditorReducer from './aScriptEditor/reducer';
import { benchmarkPortfolioDrawer } from './benchmarkPortfolioDrawer';
import { benchmarks } from './benchmarks';
import customGroupingReducer from './custom-grouping/reducer';
import designerPanelReducer from './designer/panel/reducer';
import { designerWorkspacePayload } from './designerWorkspacePayload';
import { folderDrawer } from './folderDrawer';
import metadataReducer from './metadata/reducer';
import { notifications } from './notifications';
import positionReducer from './position-drawer/reducer';
import progressReducer from './progress/reducer';
import { proxyDrawer } from './proxyDrawer';
import { report } from './report';
import reportScenarioDrawerReducer from './report-scenario-drawer/reportScenarioDrawer';
import { reportEntitiesDrawer } from './reportEntitiesDrawer';
import { reportPortfolioDrawer } from './reportPortfolioDrawer';
import wsSagas from './reportSaga';
import { createScenariosConfigReducer } from './scenariosConfig';
import { settings } from './settings';
import { sidePanel } from './sidePanel';
import uiReducer from './ui/reducer';
import { user } from './user';
import { workspace } from './workspace';

export const sagaMiddleware = createSagaMiddleware();

let sock: Client = null;

const WS_SEND_RETRIES = 5;
const WS_SEND_BACKOFF = 100;

const queuedMessages: QueuedMessage[] = [];

export const sendStompMessage = (
  message:
    | ReportRequest
    | EditRequest
    | ReportReleaseRequest
    | ChangeReportSequenceIdRequest
    | ReportSortRequest
    | ReportPrecisionUpdateRequest
    | ReportNicknameRequest
    | CancelReportGeneration,
  tk: string,
  numPending?: number,
) =>
  new Promise<void>((resolve, reject) => {
    if (numPending && numPending > WS_MAX_PENDING_REQUESTS) {
      // more than max pending messages in flight. queue this one up.
      queuedMessages.push({ message, resolve, reject });
    } else {
      // less than max pending messages in flight. try to send out immediately.
      // not clear if the try catch here is necessary (it boils down to how sock.publish works in case of error)
      // two-zero-four-five filed to follow-up on this
      try {
        const topic = message.requestType === 'edit' ? publishEditTopic : publishTopic;

        sendWSMessage(`${topic}${clientUuid}`, message, tk);
        resolve();
      } catch (e) {
        reject(e);
      }
    }
  });

export const sendCSVWSMessage = (
  message: CSVRequest,
  tk: string,
  retriesLeft = WS_SEND_RETRIES,
) => {
  sendWSMessage(`${publishCSVTopic}${clientUuid}`, message, tk, retriesLeft);
};

export const sendPrintReportWSMessage = (
  message: ReportPrintRequest,
  tk: string,
  retriesLeft = WS_SEND_RETRIES,
) => {
  sendWSMessage(`${publishPrintReportTopic}${clientUuid}`, message, tk, retriesLeft);
};

export const sendProxyMessage = (
  message: ProxyRequest,
  tk: string,
  retriesLeft = WS_SEND_RETRIES,
) => {
  sendWSMessage(`${publishProxyTopic}${clientUuid}`, message, tk, retriesLeft);
};

export const sendEditMessage = (
  message: EditRequest,
  tk: string,
  retriesLeft = WS_SEND_RETRIES,
) => {
  sendWSMessage(`${publishEditTopic}${clientUuid}`, message, tk, retriesLeft);
};

export const sendProgressInfoMessage = (message: ProgressInfoRequest, tk: string) => {
  sendWSMessage(`${publishProgressTopic}${clientUuid}`, message, tk);
};

const sendWSMessage = (
  destination: string,
  message:
    | ReportRequest
    | ReportEditRequest
    | ReportReleaseRequest
    | ReportPrintRequest
    | ChangeReportSequenceIdRequest
    | ReportSortRequest
    | ReportPrecisionUpdateRequest
    | ReportNicknameRequest
    | ProxyRequest
    | CSVRequest
    | EditRequest
    | CancelReportGeneration
    | ProgressInfoRequest,
  tk: string,
  retriesLeft = WS_SEND_RETRIES,
) => {
  if (sock.connected) {
    sock.publish({
      destination,
      body: JSON.stringify({ ...message, token: tk }),
    });
  } else {
    console.warn('Socket not connected');
    if (retriesLeft > 0) {
      const backoffDuration = WS_SEND_BACKOFF * (WS_SEND_RETRIES - retriesLeft + 1);
      console.warn(`Will retry in ${backoffDuration}ms ...`);
      window.setTimeout(() => {
        sendWSMessage(destination, message, tk, --retriesLeft);
      }, backoffDuration);
    } else {
      console.error('Socket disconnected when trying to send message');
    }
  }
};

export const sendNodeStompMessage = (message: NodeRequest, token: string, numPending?: number) =>
  new Promise<void>((resolve, reject) => {
    // not clear if the try catch here is necessary (it boils down to how sock.publish works in case of error)
    // two-zero-four-five filed to follow-up on this
    try {
      sendNodeWSMessage(message, token);
      resolve();
    } catch (e) {
      reject(e);
    }
  });

const sendNodeWSMessage = (message: NodeRequest, tk: string, retriesLeft = WS_SEND_RETRIES) => {
  if (sock.connected) {
    sock.publish({
      destination: `${nodesTopic}${clientUuid}`,
      body: JSON.stringify({ ...message, token: tk }),
    });
  } else {
    console.warn('Socket not connected');
    if (retriesLeft > 0) {
      const backoffDuration = WS_SEND_BACKOFF * (WS_SEND_RETRIES - retriesLeft + 1);
      console.warn(`Will retry in ${backoffDuration}ms ...`);
      window.setTimeout(() => {
        sendNodeWSMessage(message, tk, --retriesLeft);
      }, backoffDuration);
    } else {
      console.error('Socket disconnected when trying to send message');
    }
  }
};

export const sendNextQueuedMessage = (tk: string) => {
  const queuedMsg: QueuedMessage = queuedMessages.shift();

  if (queuedMsg) {
    // not clear if the try catch here is necessary (it boils down to how sock.publish works in case of error)
    // two-zero-four-five filed to follow-up on this
    try {
      sendWSMessage(`${publishTopic}${clientUuid}`, queuedMsg.message, tk);
      queuedMsg.resolve();
    } catch (e) {
      queuedMsg.reject(e);
    }
  }
};

export const connectWS = () => {
  const stompConfig: StompConfig = {
    brokerURL: `${wsBaseUrl}stomp-backstage`,
    reconnectDelay: 5000,
    heartbeatIncoming: 10000,
    heartbeatOutgoing: 0,
  };

  // first make sure the socket is not already connected
  // if it is, deactivate it first
  if (sock && sock.active) {
    sock.deactivate();
  }

  sock = new Client(stompConfig);
  sagaMiddleware.run(wsSagas(sock));
};

export const disconnectWS = () => {
  if (sock?.active) {
    sock.deactivate();
  } else {
    console.warn('Socket alread inactive when tried to disconnect');
  }
};

const appReducer = combineReducers({
  admin,
  drawers: combineReducers({
    folderDrawer,
    benchmarkPortfolioDrawer,
    reportPortfolioDrawer,
    reportEntitiesDrawer,
    proxyDrawer,
    positionDrawer: positionReducer,
    reportScenarioDrawer: combineReducers({
      drawer: reportScenarioDrawerReducer,
      scenariosConfig: createScenariosConfigReducer('workspace'),
    }),
  }),
  workspace,
  report,
  reportDesigner: combineReducers({
    panelControl: designerPanelReducer,
    scenariosConfig: createScenariosConfigReducer('designer'),
    settings,
    benchmarks,
    designerWorkspacePayload,
  }),
  sidePanel,
  user,
  notifications,
  metadata: metadataReducer,
  progress: progressReducer,
  ui: uiReducer,
  aScriptEditor: aScriptEditorReducer,
  customGrouping: customGroupingReducer,
});

const rootReducer = (state: ReturnType<typeof appReducer>, action: Action) =>
  appReducer(action.type === ActionTypes.USER_LOGOUT ? undefined : state, action);

export const createStore = () =>
  configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware => [
      ...getDefaultMiddleware(),
      sagaMiddleware,
      ...(devEnvironment ? [logger] : []),
    ],
  });

export type AppState = ReturnType<typeof appReducer>;
export const useAppSelector: TypedUseSelectorHook<AppState> = useSelector;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  AppState,
  unknown,
  Action<string>
>;
export type AppThunkDispatch = ThunkDispatch<AppState, undefined, Action>;
