// Generated from src/lang/ascript/AScriptLexer.g4 by ANTLR 4.9.0-SNAPSHOT


import { ATN } from "antlr4ts/atn/ATN";
import { ATNDeserializer } from "antlr4ts/atn/ATNDeserializer";
import { CharStream } from "antlr4ts/CharStream";
import { Lexer } from "antlr4ts/Lexer";
import { LexerATNSimulator } from "antlr4ts/atn/LexerATNSimulator";
import { NotNull } from "antlr4ts/Decorators";
import { Override } from "antlr4ts/Decorators";
import { RuleContext } from "antlr4ts/RuleContext";
import { Vocabulary } from "antlr4ts/Vocabulary";
import { VocabularyImpl } from "antlr4ts/VocabularyImpl";

import * as Utils from "antlr4ts/misc/Utils";


export class AScriptLexer extends Lexer {
	public static readonly COMMENT = 1;
	public static readonly KW_IF = 2;
	public static readonly KW_ELSE = 3;
	public static readonly KW_WHILE = 4;
	public static readonly KW_RETURN = 5;
	public static readonly KW_CONTINUE = 6;
	public static readonly KW_PRINT = 7;
	public static readonly KW_MATH = 8;
	public static readonly KW_IMPORT = 9;
	public static readonly KW_REQUIRES = 10;
	public static readonly KW_KEY = 11;
	public static readonly KW_TOTAL = 12;
	public static readonly KW_VALUE_KEY = 13;
	public static readonly KW_GET = 14;
	public static readonly KW_KEY_MODIFIER = 15;
	public static readonly KW_SUBSTR = 16;
	public static readonly KW_R_ASSIGN = 17;
	public static readonly KW_ARM_ASSIGN = 18;
	public static readonly KW_R_BLOCK_BEGIN = 19;
	public static readonly KW_DOUBLE_VECTOR = 20;
	public static readonly KEY_EXPRESSION = 21;
	public static readonly TOTAL_EXPRESSION = 22;
	public static readonly VALUE_KEY_EXPRESSION = 23;
	public static readonly INSTANCE_KEY_EXPRESSION = 24;
	public static readonly KEY_ACCESSOR = 25;
	public static readonly SUBSTR_EXPRESSION = 26;
	public static readonly KW_NATIVE_FUNCTION = 27;
	public static readonly KW_NATIVE_INSTANCE_METHOD = 28;
	public static readonly MATH_METHOD = 29;
	public static readonly CAST_OPERATOR = 30;
	public static readonly TYPE = 31;
	public static readonly DOUBLE_LITERAL = 32;
	public static readonly INT_LITERAL = 33;
	public static readonly BOOLEAN_LITERAL = 34;
	public static readonly STRING_LITERAL = 35;
	public static readonly IDENTIFIER = 36;
	public static readonly SEMICOLON = 37;
	public static readonly PERIOD = 38;
	public static readonly EQUAL_SIGN = 39;
	public static readonly COMMA = 40;
	public static readonly COLON = 41;
	public static readonly NUMBER_SIGN = 42;
	public static readonly DOUBLE_QUOTE = 43;
	public static readonly NEW_LINE = 44;
	public static readonly L_PAREN = 45;
	public static readonly R_PAREN = 46;
	public static readonly L_BRACKET = 47;
	public static readonly R_BRACKET = 48;
	public static readonly L_BRACE = 49;
	public static readonly R_BRACE = 50;
	public static readonly LOGICAL_OR = 51;
	public static readonly LOGICAL_AND = 52;
	public static readonly BITWISE_OR = 53;
	public static readonly BITWISE_XOR = 54;
	public static readonly BITWISE_AND = 55;
	public static readonly COMP_EQ = 56;
	public static readonly COMP_NEQ = 57;
	public static readonly COMP_LT = 58;
	public static readonly COMP_GT = 59;
	public static readonly COMP_LEQ = 60;
	public static readonly COMP_GEQ = 61;
	public static readonly ARITH_ADD = 62;
	public static readonly ARITH_SUBTRACT = 63;
	public static readonly ARITH_MULTIPLY = 64;
	public static readonly ARITH_DIVIDE = 65;
	public static readonly ARITH_MOD = 66;
	public static readonly BITWISE_COMPLEMENT = 67;
	public static readonly LOGICAL_NOT = 68;
	public static readonly INCREMENT = 69;
	public static readonly DECREMENT = 70;
	public static readonly WS = 71;
	public static readonly UNRECOGNIZED = 72;
	public static readonly KW_AS = 73;
	public static readonly IMPORT_PARAMETERIZATION = 74;
	public static readonly IMPORT_ID = 75;
	public static readonly IMPORT_LANGLE = 76;
	public static readonly IMPORT_RANGLE = 77;
	public static readonly IMPORT_PERIOD = 78;
	public static readonly IMPORT_SEMICOLON = 79;
	public static readonly KW_R_BLOCK_END = 80;
	public static readonly R_NEW_LINE = 81;
	public static readonly R_BLOCK_CHAR = 82;
	public static readonly IMPORT = 1;
	public static readonly R_BLOCK = 2;

	// tslint:disable:no-trailing-whitespace
	public static readonly channelNames: string[] = [
		"DEFAULT_TOKEN_CHANNEL", "HIDDEN",
	];

	// tslint:disable:no-trailing-whitespace
	public static readonly modeNames: string[] = [
		"DEFAULT_MODE", "IMPORT", "R_BLOCK",
	];

	public static readonly ruleNames: string[] = [
		"COMMENT", "KW_IF", "KW_ELSE", "KW_WHILE", "KW_RETURN", "KW_CONTINUE", 
		"KW_PRINT", "KW_MATH", "KW_IMPORT", "KW_REQUIRES", "KW_KEY", "KW_TOTAL", 
		"KW_VALUE_KEY", "KW_GET", "KW_KEY_MODIFIER", "KW_SUBSTR", "KW_R_ASSIGN", 
		"KW_ARM_ASSIGN", "KW_R_BLOCK_BEGIN", "KW_DOUBLE_VECTOR", "KEY_EXPRESSION", 
		"TOTAL_EXPRESSION", "VALUE_KEY_EXPRESSION", "INSTANCE_KEY_EXPRESSION", 
		"KEY_ACCESSOR", "SUBSTR_EXPRESSION", "SUBSTR_ARG", "KW_NATIVE_FUNCTION", 
		"KW_NATIVE_INSTANCE_METHOD", "MATH_METHOD", "CAST_OPERATOR", "TYPE", "CUSTOM_VAR_TYPE", 
		"DOUBLE_LITERAL", "INT_LITERAL", "BOOLEAN_LITERAL", "STRING_LITERAL", 
		"IDENTIFIER", "LETTER", "DIGIT", "UNDERSCORE", "SEMICOLON", "PERIOD", 
		"EQUAL_SIGN", "COMMA", "COLON", "NUMBER_SIGN", "DOUBLE_QUOTE", "NEW_LINE", 
		"L_PAREN", "R_PAREN", "L_BRACKET", "R_BRACKET", "L_BRACE", "R_BRACE", 
		"NOT_R_PAREN", "NOT_R_BRACKET", "NOT_R_BRACE", "NOT_NEW_LINE", "NOT_DOUBLE_QUOTE", 
		"LOGICAL_OR", "LOGICAL_AND", "BITWISE_OR", "BITWISE_XOR", "BITWISE_AND", 
		"COMP_EQ", "COMP_NEQ", "COMP_LT", "COMP_GT", "COMP_LEQ", "COMP_GEQ", "ARITH_ADD", 
		"ARITH_SUBTRACT", "ARITH_MULTIPLY", "ARITH_DIVIDE", "ARITH_MOD", "BITWISE_COMPLEMENT", 
		"LOGICAL_NOT", "INCREMENT", "DECREMENT", "WS", "UNRECOGNIZED", "KW_AS", 
		"IMPORT_PARAMETERIZATION", "IMPORT_ID", "IMPORT_LANGLE", "IMPORT_RANGLE", 
		"IMPORT_PERIOD", "IMPORT_SEMICOLON", "KW_R_BLOCK_END", "R_NEW_LINE", "R_BLOCK_CHAR",
	];

	private static readonly _LITERAL_NAMES: Array<string | undefined> = [
		undefined, undefined, "'if'", "'else'", "'while'", "'return'", "'continue'", 
		"'print'", "'Math'", "'import'", "'requires'", "'key'", "'total'", "'ValueKey'", 
		"'get'", undefined, "'substr'", "'RAssign'", "'ArmAssign'", "'RBegin'", 
		"'double_vector'", undefined, undefined, undefined, undefined, undefined, 
		undefined, undefined, undefined, undefined, undefined, undefined, undefined, 
		undefined, undefined, undefined, undefined, undefined, undefined, "'='", 
		"','", "':'", "'#'", "'\"'", undefined, "'('", "')'", "'['", "']'", "'{'", 
		"'}'", "'||'", "'&&'", "'|'", "'^'", "'&'", "'=='", "'!='", undefined, 
		undefined, "'<='", "'>='", "'+'", "'-'", "'*'", "'/'", "'%'", "'~'", "'!'", 
		"'++'", "'--'", undefined, undefined, "'as'", undefined, undefined, undefined, 
		undefined, undefined, undefined, "'REnd'",
	];
	private static readonly _SYMBOLIC_NAMES: Array<string | undefined> = [
		undefined, "COMMENT", "KW_IF", "KW_ELSE", "KW_WHILE", "KW_RETURN", "KW_CONTINUE", 
		"KW_PRINT", "KW_MATH", "KW_IMPORT", "KW_REQUIRES", "KW_KEY", "KW_TOTAL", 
		"KW_VALUE_KEY", "KW_GET", "KW_KEY_MODIFIER", "KW_SUBSTR", "KW_R_ASSIGN", 
		"KW_ARM_ASSIGN", "KW_R_BLOCK_BEGIN", "KW_DOUBLE_VECTOR", "KEY_EXPRESSION", 
		"TOTAL_EXPRESSION", "VALUE_KEY_EXPRESSION", "INSTANCE_KEY_EXPRESSION", 
		"KEY_ACCESSOR", "SUBSTR_EXPRESSION", "KW_NATIVE_FUNCTION", "KW_NATIVE_INSTANCE_METHOD", 
		"MATH_METHOD", "CAST_OPERATOR", "TYPE", "DOUBLE_LITERAL", "INT_LITERAL", 
		"BOOLEAN_LITERAL", "STRING_LITERAL", "IDENTIFIER", "SEMICOLON", "PERIOD", 
		"EQUAL_SIGN", "COMMA", "COLON", "NUMBER_SIGN", "DOUBLE_QUOTE", "NEW_LINE", 
		"L_PAREN", "R_PAREN", "L_BRACKET", "R_BRACKET", "L_BRACE", "R_BRACE", 
		"LOGICAL_OR", "LOGICAL_AND", "BITWISE_OR", "BITWISE_XOR", "BITWISE_AND", 
		"COMP_EQ", "COMP_NEQ", "COMP_LT", "COMP_GT", "COMP_LEQ", "COMP_GEQ", "ARITH_ADD", 
		"ARITH_SUBTRACT", "ARITH_MULTIPLY", "ARITH_DIVIDE", "ARITH_MOD", "BITWISE_COMPLEMENT", 
		"LOGICAL_NOT", "INCREMENT", "DECREMENT", "WS", "UNRECOGNIZED", "KW_AS", 
		"IMPORT_PARAMETERIZATION", "IMPORT_ID", "IMPORT_LANGLE", "IMPORT_RANGLE", 
		"IMPORT_PERIOD", "IMPORT_SEMICOLON", "KW_R_BLOCK_END", "R_NEW_LINE", "R_BLOCK_CHAR",
	];
	public static readonly VOCABULARY: Vocabulary = new VocabularyImpl(AScriptLexer._LITERAL_NAMES, AScriptLexer._SYMBOLIC_NAMES, []);

	// @Override
	// @NotNull
	public get vocabulary(): Vocabulary {
		return AScriptLexer.VOCABULARY;
	}
	// tslint:enable:no-trailing-whitespace


	constructor(input: CharStream) {
		super(input);
		this._interp = new LexerATNSimulator(AScriptLexer._ATN, this);
	}

	// @Override
	public get grammarFileName(): string { return "AScriptLexer.g4"; }

	// @Override
	public get ruleNames(): string[] { return AScriptLexer.ruleNames; }

	// @Override
	public get serializedATN(): string { return AScriptLexer._serializedATN; }

	// @Override
	public get channelNames(): string[] { return AScriptLexer.channelNames; }

	// @Override
	public get modeNames(): string[] { return AScriptLexer.modeNames; }

	private static readonly _serializedATNSegments: number = 2;
	private static readonly _serializedATNSegment0: string =
		"\x03\uC91D\uCABA\u058D\uAFBA\u4F53\u0607\uEA8B\uC241\x02T\u0347\b\x01" +
		"\b\x01\b\x01\x04\x02\t\x02\x04\x03\t\x03\x04\x04\t\x04\x04\x05\t\x05\x04" +
		"\x06\t\x06\x04\x07\t\x07\x04\b\t\b\x04\t\t\t\x04\n\t\n\x04\v\t\v\x04\f" +
		"\t\f\x04\r\t\r\x04\x0E\t\x0E\x04\x0F\t\x0F\x04\x10\t\x10\x04\x11\t\x11" +
		"\x04\x12\t\x12\x04\x13\t\x13\x04\x14\t\x14\x04\x15\t\x15\x04\x16\t\x16" +
		"\x04\x17\t\x17\x04\x18\t\x18\x04\x19\t\x19\x04\x1A\t\x1A\x04\x1B\t\x1B" +
		"\x04\x1C\t\x1C\x04\x1D\t\x1D\x04\x1E\t\x1E\x04\x1F\t\x1F\x04 \t \x04!" +
		"\t!\x04\"\t\"\x04#\t#\x04$\t$\x04%\t%\x04&\t&\x04\'\t\'\x04(\t(\x04)\t" +
		")\x04*\t*\x04+\t+\x04,\t,\x04-\t-\x04.\t.\x04/\t/\x040\t0\x041\t1\x04" +
		"2\t2\x043\t3\x044\t4\x045\t5\x046\t6\x047\t7\x048\t8\x049\t9\x04:\t:\x04" +
		";\t;\x04<\t<\x04=\t=\x04>\t>\x04?\t?\x04@\t@\x04A\tA\x04B\tB\x04C\tC\x04" +
		"D\tD\x04E\tE\x04F\tF\x04G\tG\x04H\tH\x04I\tI\x04J\tJ\x04K\tK\x04L\tL\x04" +
		"M\tM\x04N\tN\x04O\tO\x04P\tP\x04Q\tQ\x04R\tR\x04S\tS\x04T\tT\x04U\tU\x04" +
		"V\tV\x04W\tW\x04X\tX\x04Y\tY\x04Z\tZ\x04[\t[\x04\\\t\\\x04]\t]\x03\x02" +
		"\x03\x02\x07\x02\xC0\n\x02\f\x02\x0E\x02\xC3\v\x02\x03\x03\x03\x03\x03" +
		"\x03\x03\x04\x03\x04\x03\x04\x03\x04\x03\x04\x03\x05\x03\x05\x03\x05\x03" +
		"\x05\x03\x05\x03\x05\x03\x06\x03\x06\x03\x06\x03\x06\x03\x06\x03\x06\x03" +
		"\x06\x03\x07\x03\x07\x03\x07\x03\x07\x03\x07\x03\x07\x03\x07\x03\x07\x03" +
		"\x07\x03\b\x03\b\x03\b\x03\b\x03\b\x03\b\x03\t\x03\t\x03\t\x03\t\x03\t" +
		"\x03\n\x03\n\x03\n\x03\n\x03\n\x03\n\x03\n\x03\n\x03\n\x03\v\x03\v\x03" +
		"\v\x03\v\x03\v\x03\v\x03\v\x03\v\x03\v\x03\f\x03\f\x03\f\x03\f\x03\r\x03" +
		"\r\x03\r\x03\r\x03\r\x03\r\x03\x0E\x03\x0E\x03\x0E\x03\x0E\x03\x0E\x03" +
		"\x0E\x03\x0E\x03\x0E\x03\x0E\x03\x0F\x03\x0F\x03\x0F\x03\x0F\x03\x10\x03" +
		"\x10\x03\x10\x03\x10\x03\x10\x03\x10\x03\x10\x03\x10\x03\x10\x03\x10\x03" +
		"\x10\x03\x10\x03\x10\x03\x10\x03\x10\x03\x10\x03\x10\x03\x10\x03\x10\x03" +
		"\x10\x03\x10\x03\x10\x03\x10\x03\x10\x05\x10\u012F\n\x10\x03\x11\x03\x11" +
		"\x03\x11\x03\x11\x03\x11\x03\x11\x03\x11\x03\x12\x03\x12\x03\x12\x03\x12" +
		"\x03\x12\x03\x12\x03\x12\x03\x12\x03\x13\x03\x13\x03\x13\x03\x13\x03\x13" +
		"\x03\x13\x03\x13\x03\x13\x03\x13\x03\x13\x03\x14\x03\x14\x03\x14\x03\x14" +
		"\x03\x14\x03\x14\x03\x14\x03\x14\x03\x14\x03\x15\x03\x15\x03\x15\x03\x15" +
		"\x03\x15\x03\x15\x03\x15\x03\x15\x03\x15\x03\x15\x03\x15\x03\x15\x03\x15" +
		"\x03\x15\x03\x16\x03\x16\x03\x16\x03\x17\x03\x17\x03\x17\x03\x18\x03\x18" +
		"\x03\x18\x03\x19\x03\x19\x03\x19\x03\x19\x03\x19\x03\x1A\x03\x1A\x06\x1A" +
		"\u0171\n\x1A\r\x1A\x0E\x1A\u0172\x03\x1A\x03\x1A\x03\x1A\x03\x1A\x07\x1A" +
		"\u0179\n\x1A\f\x1A\x0E\x1A\u017C\v\x1A\x03\x1B\x03\x1B\x03\x1B\x03\x1B" +
		"\x03\x1B\x03\x1B\x03\x1B\x03\x1B\x03\x1B\x03\x1C\x03\x1C\x06\x1C\u0189" +
		"\n\x1C\r\x1C\x0E\x1C\u018A\x03\x1D\x03\x1D\x03\x1D\x03\x1D\x03\x1D\x03" +
		"\x1D\x03\x1D\x03\x1D\x03\x1D\x03\x1D\x03\x1D\x03\x1D\x03\x1D\x03\x1D\x03" +
		"\x1D\x03\x1D\x03\x1D\x03\x1D\x03\x1D\x03\x1D\x03\x1D\x03\x1D\x03\x1D\x03" +
		"\x1D\x03\x1D\x03\x1D\x03\x1D\x03\x1D\x03\x1D\x03\x1D\x03\x1D\x03\x1D\x03" +
		"\x1D\x03\x1D\x03\x1D\x03\x1D\x03\x1D\x03\x1D\x03\x1D\x03\x1D\x03\x1D\x03" +
		"\x1D\x03\x1D\x03\x1D\x03\x1D\x03\x1D\x03\x1D\x03\x1D\x03\x1D\x03\x1D\x03" +
		"\x1D\x03\x1D\x03\x1D\x03\x1D\x03\x1D\x03\x1D\x03\x1D\x03\x1D\x03\x1D\x03" +
		"\x1D\x03\x1D\x03\x1D\x03\x1D\x03\x1D\x03\x1D\x03\x1D\x03\x1D\x03\x1D\x03" +
		"\x1D\x03\x1D\x03\x1D\x03\x1D\x03\x1D\x03\x1D\x03\x1D\x03\x1D\x03\x1D\x03" +
		"\x1D\x03\x1D\x03\x1D\x03\x1D\x03\x1D\x03\x1D\x03\x1D\x03\x1D\x03\x1D\x03" +
		"\x1D\x03\x1D\x03\x1D\x03\x1D\x03\x1D\x03\x1D\x03\x1D\x03\x1D\x03\x1D\x03" +
		"\x1D\x03\x1D\x03\x1D\x03\x1D\x03\x1D\x03\x1D\x03\x1D\x05\x1D\u01F3\n\x1D" +
		"\x03\x1E\x03\x1E\x03\x1E\x03\x1E\x03\x1E\x03\x1E\x03\x1E\x03\x1E\x03\x1E" +
		"\x03\x1E\x03\x1E\x03\x1E\x03\x1E\x03\x1E\x03\x1E\x03\x1E\x03\x1E\x03\x1E" +
		"\x03\x1E\x03\x1E\x03\x1E\x03\x1E\x03\x1E\x03\x1E\x03\x1E\x03\x1E\x03\x1E" +
		"\x03\x1E\x03\x1E\x03\x1E\x03\x1E\x03\x1E\x03\x1E\x03\x1E\x03\x1E\x03\x1E" +
		"\x03\x1E\x03\x1E\x03\x1E\x03\x1E\x03\x1E\x03\x1E\x03\x1E\x03\x1E\x03\x1E" +
		"\x03\x1E\x03\x1E\x03\x1E\x03\x1E\x03\x1E\x03\x1E\x03\x1E\x03\x1E\x03\x1E" +
		"\x03\x1E\x03\x1E\x03\x1E\x03\x1E\x03\x1E\x05\x1E\u0230\n\x1E\x03\x1F\x03" +
		"\x1F\x03\x1F\x03\x1F\x03 \x03 \x03 \x03 \x03!\x03!\x03!\x03!\x03!\x03" +
		"!\x03!\x03!\x03!\x03!\x03!\x03!\x03!\x03!\x03!\x03!\x03!\x03!\x03!\x03" +
		"!\x03!\x03!\x03!\x03!\x03!\x03!\x03!\x03!\x03!\x03!\x03!\x03!\x03!\x03" +
		"!\x03!\x03!\x03!\x03!\x03!\x03!\x03!\x03!\x03!\x03!\x05!\u0266\n!\x03" +
		"\"\x03\"\x03\"\x03\"\x03\"\x03\"\x03\"\x03\"\x03\"\x03\"\x03\"\x03\"\x03" +
		"\"\x03\"\x03\"\x05\"\u0277\n\"\x03#\x06#\u027A\n#\r#\x0E#\u027B\x03#\x03" +
		"#\x07#\u0280\n#\f#\x0E#\u0283\v#\x03#\x07#\u0286\n#\f#\x0E#\u0289\v#\x03" +
		"#\x03#\x06#\u028D\n#\r#\x0E#\u028E\x05#\u0291\n#\x03$\x06$\u0294\n$\r" +
		"$\x0E$\u0295\x03%\x03%\x03%\x03%\x03%\x03%\x03%\x03%\x03%\x05%\u02A1\n" +
		"%\x03&\x03&\x07&\u02A5\n&\f&\x0E&\u02A8\v&\x03&\x03&\x03\'\x03\'\x05\'" +
		"\u02AE\n\'\x03\'\x03\'\x03\'\x07\'\u02B3\n\'\f\'\x0E\'\u02B6\v\'\x03(" +
		"\x03(\x03)\x03)\x03*\x03*\x03+\x03+\x03,\x03,\x03-\x03-\x03.\x03.\x03" +
		"/\x03/\x030\x030\x031\x031\x032\x052\u02CD\n2\x032\x032\x033\x033\x03" +
		"4\x034\x035\x035\x036\x036\x037\x037\x038\x038\x039\x039\x03:\x03:\x03" +
		";\x03;\x03<\x03<\x03=\x03=\x03>\x03>\x03>\x03?\x03?\x03?\x03@\x03@\x03" +
		"A\x03A\x03B\x03B\x03C\x03C\x03C\x03D\x03D\x03D\x03E\x03E\x03F\x03F\x03" +
		"G\x03G\x03G\x03H\x03H\x03H\x03I\x03I\x03J\x03J\x03K\x03K\x03L\x03L\x03" +
		"M\x03M\x03N\x03N\x03O\x03O\x03P\x03P\x03P\x03Q\x03Q\x03Q\x03R\x06R\u0318" +
		"\nR\rR\x0ER\u0319\x03R\x03R\x03S\x03S\x03T\x03T\x03T\x03U\x03U\x03U\x03" +
		"U\x03V\x03V\x07V\u0329\nV\fV\x0EV\u032C\vV\x03W\x03W\x03X\x03X\x03Y\x03" +
		"Y\x03Z\x03Z\x03Z\x03Z\x03[\x03[\x03[\x03[\x03[\x03[\x03[\x03\\\x05\\\u0340" +
		"\n\\\x03\\\x03\\\x03\\\x03\\\x03]\x03]\x02\x02\x02^\x05\x02\x03\x07\x02" +
		"\x04\t\x02\x05\v\x02\x06\r\x02\x07\x0F\x02\b\x11\x02\t\x13\x02\n\x15\x02" +
		"\v\x17\x02\f\x19\x02\r\x1B\x02\x0E\x1D\x02\x0F\x1F\x02\x10!\x02\x11#\x02" +
		"\x12%\x02\x13\'\x02\x14)\x02\x15+\x02\x16-\x02\x17/\x02\x181\x02\x193" +
		"\x02\x1A5\x02\x1B7\x02\x1C9\x02\x02;\x02\x1D=\x02\x1E?\x02\x1FA\x02 C" +
		"\x02!E\x02\x02G\x02\"I\x02#K\x02$M\x02%O\x02&Q\x02\x02S\x02\x02U\x02\x02" +
		"W\x02\'Y\x02([\x02)]\x02*_\x02+a\x02,c\x02-e\x02.g\x02/i\x020k\x021m\x02" +
		"2o\x023q\x024s\x02\x02u\x02\x02w\x02\x02y\x02\x02{\x02\x02}\x025\x7F\x02" +
		"6\x81\x027\x83\x028\x85\x029\x87\x02:\x89\x02;\x8B\x02<\x8D\x02=\x8F\x02" +
		">\x91\x02?\x93\x02@\x95\x02A\x97\x02B\x99\x02C\x9B\x02D\x9D\x02E\x9F\x02" +
		"F\xA1\x02G\xA3\x02H\xA5\x02I\xA7\x02J\xA9\x02K\xAB\x02L\xAD\x02M\xAF\x02" +
		"N\xB1\x02O\xB3\x02P\xB5\x02Q\xB7\x02R\xB9\x02S\xBB\x02T\x05\x02\x03\x04" +
		"\r\x03\x023;\x03\x022;\x04\x02C\\c|\x03\x02++\x03\x02__\x03\x02\x7F\x7F" +
		"\x03\x02\f\f\x03\x02$$\x04\x02\v\v\"\"\x05\x02C\\aac|\x06\x022;C\\aac" +
		"|\x02\u0367\x02\x05\x03\x02\x02\x02\x02\x07\x03\x02\x02\x02\x02\t\x03" +
		"\x02\x02\x02\x02\v\x03\x02\x02\x02\x02\r\x03\x02\x02\x02\x02\x0F\x03\x02" +
		"\x02\x02\x02\x11\x03\x02\x02\x02\x02\x13\x03\x02\x02\x02\x02\x15\x03\x02" +
		"\x02\x02\x02\x17\x03\x02\x02\x02\x02\x19\x03\x02\x02\x02\x02\x1B\x03\x02" +
		"\x02\x02\x02\x1D\x03\x02\x02\x02\x02\x1F\x03\x02\x02\x02\x02!\x03\x02" +
		"\x02\x02\x02#\x03\x02\x02\x02\x02%\x03\x02\x02\x02\x02\'\x03\x02\x02\x02" +
		"\x02)\x03\x02\x02\x02\x02+\x03\x02\x02\x02\x02-\x03\x02\x02\x02\x02/\x03" +
		"\x02\x02\x02\x021\x03\x02\x02\x02\x023\x03\x02\x02\x02\x025\x03\x02\x02" +
		"\x02\x027\x03\x02\x02\x02\x02;\x03\x02\x02\x02\x02=\x03\x02\x02\x02\x02" +
		"?\x03\x02\x02\x02\x02A\x03\x02\x02\x02\x02C\x03\x02\x02\x02\x02G\x03\x02" +
		"\x02\x02\x02I\x03\x02\x02\x02\x02K\x03\x02\x02\x02\x02M\x03\x02\x02\x02" +
		"\x02O\x03\x02\x02\x02\x02W\x03\x02\x02\x02\x02Y\x03\x02\x02\x02\x02[\x03" +
		"\x02\x02\x02\x02]\x03\x02\x02\x02\x02_\x03\x02\x02\x02\x02a\x03\x02\x02" +
		"\x02\x02c\x03\x02\x02\x02\x02e\x03\x02\x02\x02\x02g\x03\x02\x02\x02\x02" +
		"i\x03\x02\x02\x02\x02k\x03\x02\x02\x02\x02m\x03\x02\x02\x02\x02o\x03\x02" +
		"\x02\x02\x02q\x03\x02\x02\x02\x02}\x03\x02\x02\x02\x02\x7F\x03\x02\x02" +
		"\x02\x02\x81\x03\x02\x02\x02\x02\x83\x03\x02\x02\x02\x02\x85\x03\x02\x02" +
		"\x02\x02\x87\x03\x02\x02\x02\x02\x89\x03\x02\x02\x02\x02\x8B\x03\x02\x02" +
		"\x02\x02\x8D\x03\x02\x02\x02\x02\x8F\x03\x02\x02\x02\x02\x91\x03\x02\x02" +
		"\x02\x02\x93\x03\x02\x02\x02\x02\x95\x03\x02\x02\x02\x02\x97\x03\x02\x02" +
		"\x02\x02\x99\x03\x02\x02\x02\x02\x9B\x03\x02\x02\x02\x02\x9D\x03\x02\x02" +
		"\x02\x02\x9F\x03\x02\x02\x02\x02\xA1\x03\x02\x02\x02\x02\xA3\x03\x02\x02" +
		"\x02\x02\xA5\x03\x02\x02\x02\x02\xA7\x03\x02\x02\x02\x03\xA9\x03\x02\x02" +
		"\x02\x03\xAB\x03\x02\x02\x02\x03\xAD\x03\x02\x02\x02\x03\xAF\x03\x02\x02" +
		"\x02\x03\xB1\x03\x02\x02\x02\x03\xB3\x03\x02\x02\x02\x03\xB5\x03\x02\x02" +
		"\x02\x04\xB7\x03\x02\x02\x02\x04\xB9\x03\x02\x02\x02\x04\xBB\x03\x02\x02" +
		"\x02\x05\xBD\x03\x02\x02\x02\x07\xC4\x03\x02\x02\x02\t\xC7\x03\x02\x02" +
		"\x02\v\xCC\x03\x02\x02\x02\r\xD2\x03\x02\x02\x02\x0F\xD9\x03\x02\x02\x02" +
		"\x11\xE2\x03\x02\x02\x02\x13\xE8\x03\x02\x02\x02\x15\xED\x03\x02\x02\x02" +
		"\x17\xF6\x03\x02\x02\x02\x19\xFF\x03\x02\x02\x02\x1B\u0103\x03\x02\x02" +
		"\x02\x1D\u0109\x03\x02\x02\x02\x1F\u0112\x03\x02\x02\x02!\u012E\x03\x02" +
		"\x02\x02#\u0130\x03\x02\x02\x02%\u0137\x03\x02\x02\x02\'\u013F\x03\x02" +
		"\x02\x02)\u0149\x03\x02\x02\x02+\u0152\x03\x02\x02\x02-\u0160\x03\x02" +
		"\x02\x02/\u0163\x03\x02\x02\x021\u0166\x03\x02\x02\x023\u0169\x03\x02" +
		"\x02\x025\u016E\x03\x02\x02\x027\u017D\x03\x02\x02\x029\u0188\x03\x02" +
		"\x02\x02;\u01F2\x03\x02\x02\x02=\u022F\x03\x02\x02\x02?\u0231\x03\x02" +
		"\x02\x02A\u0235\x03\x02\x02\x02C\u0265\x03\x02\x02\x02E\u0267\x03\x02" +
		"\x02\x02G\u0290\x03\x02\x02\x02I\u0293\x03\x02\x02\x02K\u02A0\x03\x02" +
		"\x02\x02M\u02A2\x03\x02\x02\x02O\u02AD\x03\x02\x02\x02Q\u02B7\x03\x02" +
		"\x02\x02S\u02B9\x03\x02\x02\x02U\u02BB\x03\x02\x02\x02W\u02BD\x03\x02" +
		"\x02\x02Y\u02BF\x03\x02\x02\x02[\u02C1\x03\x02\x02\x02]\u02C3\x03\x02" +
		"\x02\x02_\u02C5\x03\x02\x02\x02a\u02C7\x03\x02\x02\x02c\u02C9\x03\x02" +
		"\x02\x02e\u02CC\x03\x02\x02\x02g\u02D0\x03\x02\x02\x02i\u02D2\x03\x02" +
		"\x02\x02k\u02D4\x03\x02\x02\x02m\u02D6\x03\x02\x02\x02o\u02D8\x03\x02" +
		"\x02\x02q\u02DA\x03\x02\x02\x02s\u02DC\x03\x02\x02\x02u\u02DE\x03\x02" +
		"\x02\x02w\u02E0\x03\x02\x02\x02y\u02E2\x03\x02\x02\x02{\u02E4\x03\x02" +
		"\x02\x02}\u02E6\x03\x02\x02\x02\x7F\u02E9\x03\x02\x02\x02\x81\u02EC\x03" +
		"\x02\x02\x02\x83\u02EE\x03\x02\x02\x02\x85\u02F0\x03\x02\x02\x02\x87\u02F2" +
		"\x03\x02\x02\x02\x89\u02F5\x03\x02\x02\x02\x8B\u02F8\x03\x02\x02\x02\x8D" +
		"\u02FA\x03\x02\x02\x02\x8F\u02FC\x03\x02\x02\x02\x91\u02FF\x03\x02\x02" +
		"\x02\x93\u0302\x03\x02\x02\x02\x95\u0304\x03\x02\x02\x02\x97\u0306\x03" +
		"\x02\x02\x02\x99\u0308\x03\x02\x02\x02\x9B\u030A\x03\x02\x02\x02\x9D\u030C" +
		"\x03\x02\x02\x02\x9F\u030E\x03\x02\x02\x02\xA1\u0310\x03\x02\x02\x02\xA3" +
		"\u0313\x03\x02\x02\x02\xA5\u0317\x03\x02\x02\x02\xA7\u031D\x03\x02\x02" +
		"\x02\xA9\u031F\x03\x02\x02\x02\xAB\u0322\x03\x02\x02\x02\xAD\u0326\x03" +
		"\x02\x02\x02\xAF\u032D\x03\x02\x02\x02\xB1\u032F\x03\x02\x02\x02\xB3\u0331" +
		"\x03\x02\x02\x02\xB5\u0333\x03\x02\x02\x02\xB7\u0337\x03\x02\x02\x02\xB9" +
		"\u033F\x03\x02\x02\x02\xBB\u0345\x03\x02\x02\x02\xBD\xC1\x05a0\x02\xBE" +
		"\xC0\x05y<\x02\xBF\xBE\x03\x02\x02\x02\xC0\xC3\x03\x02\x02\x02\xC1\xBF" +
		"\x03\x02\x02\x02\xC1\xC2\x03\x02\x02\x02\xC2\x06\x03\x02\x02\x02\xC3\xC1" +
		"\x03\x02\x02\x02\xC4\xC5\x07k\x02\x02\xC5\xC6\x07h\x02\x02\xC6\b\x03\x02" +
		"\x02\x02\xC7\xC8\x07g\x02\x02\xC8\xC9\x07n\x02\x02\xC9\xCA\x07u\x02\x02" +
		"\xCA\xCB\x07g\x02\x02\xCB\n\x03\x02\x02\x02\xCC\xCD\x07y\x02\x02\xCD\xCE" +
		"\x07j\x02\x02\xCE\xCF\x07k\x02\x02\xCF\xD0\x07n\x02\x02\xD0\xD1\x07g\x02" +
		"\x02\xD1\f\x03\x02\x02\x02\xD2\xD3\x07t\x02\x02\xD3\xD4\x07g\x02\x02\xD4" +
		"\xD5\x07v\x02\x02\xD5\xD6\x07w\x02\x02\xD6\xD7\x07t\x02\x02\xD7\xD8\x07" +
		"p\x02\x02\xD8\x0E\x03\x02\x02\x02\xD9\xDA\x07e\x02\x02\xDA\xDB\x07q\x02" +
		"\x02\xDB\xDC\x07p\x02\x02\xDC\xDD\x07v\x02\x02\xDD\xDE\x07k\x02\x02\xDE" +
		"\xDF\x07p\x02\x02\xDF\xE0\x07w\x02\x02\xE0\xE1\x07g\x02\x02\xE1\x10\x03" +
		"\x02\x02\x02\xE2\xE3\x07r\x02\x02\xE3\xE4\x07t\x02\x02\xE4\xE5\x07k\x02" +
		"\x02\xE5\xE6\x07p\x02\x02\xE6\xE7\x07v\x02\x02\xE7\x12\x03\x02\x02\x02" +
		"\xE8\xE9\x07O\x02\x02\xE9\xEA\x07c\x02\x02\xEA\xEB\x07v\x02\x02\xEB\xEC" +
		"\x07j\x02\x02\xEC\x14\x03\x02\x02\x02\xED\xEE\x07k\x02\x02\xEE\xEF\x07" +
		"o\x02\x02\xEF\xF0\x07r\x02\x02\xF0\xF1\x07q\x02\x02\xF1\xF2\x07t\x02\x02" +
		"\xF2\xF3\x07v\x02\x02\xF3\xF4\x03\x02\x02\x02\xF4\xF5\b\n\x02\x02\xF5" +
		"\x16\x03\x02\x02\x02\xF6\xF7\x07t\x02\x02\xF7\xF8\x07g\x02\x02\xF8\xF9" +
		"\x07s\x02\x02\xF9\xFA\x07w\x02\x02\xFA\xFB\x07k\x02\x02\xFB\xFC\x07t\x02" +
		"\x02\xFC\xFD\x07g\x02\x02\xFD\xFE\x07u\x02\x02\xFE\x18\x03\x02\x02\x02" +
		"\xFF\u0100\x07m\x02\x02\u0100\u0101\x07g\x02\x02\u0101\u0102\x07{\x02" +
		"\x02\u0102\x1A\x03\x02\x02\x02\u0103\u0104\x07v\x02\x02\u0104\u0105\x07" +
		"q\x02\x02\u0105\u0106\x07v\x02\x02\u0106\u0107\x07c\x02\x02\u0107\u0108" +
		"\x07n\x02\x02\u0108\x1C\x03\x02\x02\x02\u0109\u010A\x07X\x02\x02\u010A" +
		"\u010B\x07c\x02\x02\u010B\u010C\x07n\x02\x02\u010C\u010D\x07w\x02\x02" +
		"\u010D\u010E\x07g\x02\x02\u010E\u010F\x07M\x02\x02\u010F\u0110\x07g\x02" +
		"\x02\u0110\u0111\x07{\x02\x02\u0111\x1E\x03\x02\x02\x02\u0112\u0113\x07" +
		"i\x02\x02\u0113\u0114\x07g\x02\x02\u0114\u0115\x07v\x02\x02\u0115 \x03" +
		"\x02\x02\x02\u0116\u0117\x07r\x02\x02\u0117\u0118\x07q\x02\x02\u0118\u0119" +
		"\x07t\x02\x02\u0119\u012F\x07v\x02\x02\u011A\u011B\x07d\x02\x02\u011B" +
		"\u011C\x07g\x02\x02\u011C\u011D\x07p\x02\x02\u011D\u011E\x07e\x02\x02" +
		"\u011E\u012F\x07j\x02\x02\u011F\u0120\x07d\x02\x02\u0120\u0121\x07g\x02" +
		"\x02\u0121\u0122\x07p\x02\x02\u0122\u0123\x07e\x02\x02\u0123\u0124\x07" +
		"j\x02\x02\u0124\u012F\x074\x02\x02\u0125\u0126\x07f\x02\x02\u0126\u0127" +
		"\x07k\x02\x02\u0127\u0128\x07h\x02\x02\u0128\u012F\x07h\x02\x02\u0129" +
		"\u012A\x07f\x02\x02\u012A\u012B\x07k\x02\x02\u012B\u012C\x07h\x02\x02" +
		"\u012C\u012D\x07h\x02\x02\u012D\u012F\x074\x02\x02\u012E\u0116\x03\x02" +
		"\x02\x02\u012E\u011A\x03\x02\x02\x02\u012E\u011F\x03\x02\x02\x02\u012E" +
		"\u0125\x03\x02\x02\x02\u012E\u0129\x03\x02\x02\x02\u012F\"\x03\x02\x02" +
		"\x02\u0130\u0131\x07u\x02\x02\u0131\u0132\x07w\x02\x02\u0132\u0133\x07" +
		"d\x02\x02\u0133\u0134\x07u\x02\x02\u0134\u0135\x07v\x02\x02\u0135\u0136" +
		"\x07t\x02\x02\u0136$\x03\x02\x02\x02\u0137\u0138\x07T\x02\x02\u0138\u0139" +
		"\x07C\x02\x02\u0139\u013A\x07u\x02\x02\u013A\u013B\x07u\x02\x02\u013B" +
		"\u013C\x07k\x02\x02\u013C\u013D\x07i\x02\x02\u013D\u013E\x07p\x02\x02" +
		"\u013E&\x03\x02\x02\x02\u013F\u0140\x07C\x02\x02\u0140\u0141\x07t\x02" +
		"\x02\u0141\u0142\x07o\x02\x02\u0142\u0143\x07C\x02\x02\u0143\u0144\x07" +
		"u\x02\x02\u0144\u0145\x07u\x02\x02\u0145\u0146\x07k\x02\x02\u0146\u0147" +
		"\x07i\x02\x02\u0147\u0148\x07p\x02\x02\u0148(\x03\x02\x02\x02\u0149\u014A" +
		"\x07T\x02\x02\u014A\u014B\x07D\x02\x02\u014B\u014C\x07g\x02\x02\u014C" +
		"\u014D\x07i\x02\x02\u014D\u014E\x07k\x02\x02\u014E\u014F\x07p\x02\x02" +
		"\u014F\u0150\x03\x02\x02\x02\u0150\u0151\b\x14\x03\x02\u0151*\x03\x02" +
		"\x02\x02\u0152\u0153\x07f\x02\x02\u0153\u0154\x07q\x02\x02\u0154\u0155" +
		"\x07w\x02\x02\u0155\u0156\x07d\x02\x02\u0156\u0157\x07n\x02\x02\u0157" +
		"\u0158\x07g\x02\x02\u0158\u0159\x07a\x02\x02\u0159\u015A\x07x\x02\x02" +
		"\u015A\u015B\x07g\x02\x02\u015B\u015C\x07e\x02\x02\u015C\u015D\x07v\x02" +
		"\x02\u015D\u015E\x07q\x02\x02\u015E\u015F\x07t\x02\x02\u015F,\x03\x02" +
		"\x02\x02\u0160\u0161\x05\x19\f\x02\u0161\u0162\x055\x1A\x02\u0162.\x03" +
		"\x02\x02\x02\u0163\u0164\x05\x1B\r\x02\u0164\u0165\x055\x1A\x02\u0165" +
		"0\x03\x02\x02\x02\u0166\u0167\x05\x1D\x0E\x02\u0167\u0168\x055\x1A\x02" +
		"\u01682\x03\x02\x02\x02\u0169\u016A\x05O\'\x02\u016A\u016B\x05Y,\x02\u016B" +
		"\u016C\x05\x1F\x0F\x02\u016C\u016D\x055\x1A\x02\u016D4\x03\x02\x02\x02" +
		"\u016E\u0170\x05k5\x02\u016F\u0171\x05u:\x02\u0170\u016F\x03\x02\x02\x02" +
		"\u0171\u0172\x03\x02\x02\x02\u0172\u0170\x03\x02\x02\x02\u0172\u0173\x03" +
		"\x02\x02\x02\u0173\u0174\x03\x02\x02\x02\u0174\u017A\x05m6\x02\u0175\u0176" +
		"\x05Y,\x02\u0176\u0177\x05!\x10\x02\u0177\u0179\x03\x02\x02\x02\u0178" +
		"\u0175\x03\x02\x02\x02\u0179\u017C\x03\x02\x02\x02\u017A\u0178\x03\x02" +
		"\x02\x02\u017A\u017B\x03\x02\x02\x02\u017B6\x03\x02\x02\x02\u017C\u017A" +
		"\x03\x02\x02\x02\u017D\u017E\x05#\x11\x02\u017E\u017F\x05g3\x02\u017F" +
		"\u0180\x059\x1C\x02\u0180\u0181\x05].\x02\u0181\u0182\x059\x1C\x02\u0182" +
		"\u0183\x05].\x02\u0183\u0184\x059\x1C\x02\u0184\u0185\x05i4\x02\u0185" +
		"8\x03\x02\x02\x02\u0186\u0189\x05Q(\x02\u0187\u0189\x05S)\x02\u0188\u0186" +
		"\x03\x02\x02\x02\u0188\u0187\x03\x02\x02\x02\u0189\u018A\x03\x02\x02\x02" +
		"\u018A\u0188\x03\x02\x02\x02\u018A\u018B\x03\x02\x02\x02\u018B:\x03\x02" +
		"\x02\x02\u018C\u018D\x07i\x02\x02\u018D\u018E\x07g\x02\x02\u018E\u018F" +
		"\x07v\x02\x02\u018F\u0190\x07P\x02\x02\u0190\u0191\x07q\x02\x02\u0191" +
		"\u0192\x07f\x02\x02\u0192\u0193\x07g\x02\x02\u0193\u01F3\x07u\x02\x02" +
		"\u0194\u0195\x07i\x02\x02\u0195\u0196\x07g\x02\x02\u0196\u0197\x07v\x02" +
		"\x02\u0197\u0198\x07C\x02\x02\u0198\u0199\x07i\x02\x02\u0199\u019A\x07" +
		"i\x02\x02\u019A\u019B\x07K\x02\x02\u019B\u019C\x07p\x02\x02\u019C\u019D" +
		"\x07h\x02\x02\u019D\u01F3\x07q\x02\x02\u019E\u019F\x07i\x02\x02\u019F" +
		"\u01A0\x07g\x02\x02\u01A0\u01A1\x07v\x02\x02\u01A1\u01A2\x07G\x02\x02" +
		"\u01A2\u01A3\x07p\x02\x02\u01A3\u01A4\x07v\x02\x02\u01A4\u01A5\x07k\x02" +
		"\x02\u01A5\u01A6\x07v\x02\x02\u01A6\u01A7\x07k\x02\x02\u01A7\u01A8\x07" +
		"g\x02\x02\u01A8\u01F3\x07u\x02\x02\u01A9\u01AA\x07i\x02\x02\u01AA\u01AB" +
		"\x07g\x02\x02\u01AB\u01AC\x07v\x02\x02\u01AC\u01AD\x07C\x02\x02\u01AD" +
		"\u01AE\x07i\x02\x02\u01AE\u01AF\x07i\x02\x02\u01AF\u01B0\x07F\x02\x02" +
		"\u01B0\u01B1\x07c\x02\x02\u01B1\u01B2\x07v\x02\x02\u01B2\u01B3\x07c\x02" +
		"\x02\u01B3\u01B4\x07H\x02\x02\u01B4\u01B5\x07k\x02\x02\u01B5\u01B6\x07" +
		"g\x02\x02\u01B6\u01B7\x07n\x02\x02\u01B7\u01F3\x07f\x02\x02\u01B8\u01B9" +
		"\x07j\x02\x02\u01B9\u01BA\x07c\x02\x02\u01BA\u01BB\x07u\x02\x02\u01BB" +
		"\u01BC\x07P\x02\x02\u01BC\u01BD\x07q\x02\x02\u01BD\u01BE\x07C\x02\x02" +
		"\u01BE\u01BF\x07i\x02\x02\u01BF\u01C0\x07i\x02\x02\u01C0\u01C1\x07F\x02" +
		"\x02\u01C1\u01C2\x07c\x02\x02\u01C2\u01C3\x07v\x02\x02\u01C3\u01F3\x07" +
		"c\x02\x02\u01C4\u01C5\x07k\x02\x02\u01C5\u01C6\x07u\x02\x02\u01C6\u01C7" +
		"\x07C\x02\x02\u01C7\u01C8\x07i\x02\x02\u01C8\u01C9\x07i\x02\x02\u01C9" +
		"\u01CA\x07T\x02\x02\u01CA\u01CB\x07q\x02\x02\u01CB\u01CC\x07q\x02\x02" +
		"\u01CC\u01F3\x07v\x02\x02\u01CD\u01CE\x07k\x02\x02\u01CE\u01CF\x07u\x02" +
		"\x02\u01CF\u01D0\x07C\x02\x02\u01D0\u01D1\x07i\x02\x02\u01D1\u01D2\x07" +
		"i\x02\x02\u01D2\u01D3\x07N\x02\x02\u01D3\u01D4\x07g\x02\x02\u01D4\u01D5" +
		"\x07c\x02\x02\u01D5\u01F3\x07h\x02\x02\u01D6\u01D7\x07k\x02\x02\u01D7" +
		"\u01D8\x07u\x02\x02\u01D8\u01D9\x07C\x02\x02\u01D9\u01DA\x07i\x02\x02" +
		"\u01DA\u01DB\x07i\x02\x02\u01DB\u01DC\x07t\x02\x02\u01DC\u01DD\x07g\x02" +
		"\x02\u01DD\u01DE\x07i\x02\x02\u01DE\u01DF\x07c\x02\x02\u01DF\u01E0\x07" +
		"v\x02\x02\u01E0\u01E1\x07k\x02\x02\u01E1\u01E2\x07p\x02\x02\u01E2\u01E3" +
		"\x07i\x02\x02\u01E3\u01E4\x07F\x02\x02\u01E4\u01E5\x07c\x02\x02\u01E5" +
		"\u01E6\x07v\x02\x02\u01E6\u01E7\x07c\x02\x02\u01E7\u01E8\x07U\x02\x02" +
		"\u01E8\u01E9\x07g\x02\x02\u01E9\u01EA\x07v\x02\x02\u01EA\u01EB\x07G\x02" +
		"\x02\u01EB\u01EC\x07p\x02\x02\u01EC\u01ED\x07v\x02\x02\u01ED\u01EE\x07" +
		"k\x02\x02\u01EE\u01EF\x07v\x02";
	private static readonly _serializedATNSegment1: string =
		"\x02\u01EF\u01F0\x07k\x02\x02\u01F0\u01F1\x07g\x02\x02\u01F1\u01F3\x07" +
		"u\x02\x02\u01F2\u018C\x03\x02\x02\x02\u01F2\u0194\x03\x02\x02\x02\u01F2" +
		"\u019E\x03\x02\x02\x02\u01F2\u01A9\x03\x02\x02\x02\u01F2\u01B8\x03\x02" +
		"\x02\x02\u01F2\u01C4\x03\x02\x02\x02\u01F2\u01CD\x03\x02\x02\x02\u01F2" +
		"\u01D6\x03\x02\x02\x02\u01F3<\x03\x02\x02\x02\u01F4\u01F5\x07i\x02\x02" +
		"\u01F5\u01F6\x07g\x02\x02\u01F6\u01F7\x07v\x02\x02\u01F7\u01F8\x07C\x02" +
		"\x02\u01F8\u01F9\x07i\x02\x02\u01F9\u01FA\x07i\x02\x02\u01FA\u01FB\x07" +
		"U\x02\x02\u01FB\u01FC\x07v\x02\x02\u01FC\u01FD\x07t\x02\x02\u01FD\u01FE" +
		"\x07k\x02\x02\u01FE\u01FF\x07p\x02\x02\u01FF\u0200\x07i\x02\x02\u0200" +
		"\u0201\x07X\x02\x02\u0201\u0202\x07c\x02\x02\u0202\u0203\x07n\x02\x02" +
		"\u0203\u0204\x07w\x02\x02\u0204\u0230\x07g\x02\x02\u0205\u0206\x07i\x02" +
		"\x02\u0206\u0207\x07g\x02\x02\u0207\u0208\x07v\x02\x02\u0208\u0209\x07" +
		"C\x02\x02\u0209\u020A\x07i\x02\x02\u020A\u020B\x07i\x02\x02\u020B\u020C" +
		"\x07F\x02\x02\u020C\u020D\x07q\x02\x02\u020D\u020E\x07w\x02\x02\u020E" +
		"\u020F\x07d\x02\x02\u020F\u0210\x07n\x02\x02\u0210\u0211\x07g\x02\x02" +
		"\u0211\u0212\x07X\x02\x02\u0212\u0213\x07c\x02\x02\u0213\u0214\x07n\x02" +
		"\x02\u0214\u0215\x07w\x02\x02\u0215\u0230\x07g\x02\x02\u0216\u0217\x07" +
		"i\x02\x02\u0217\u0218\x07g\x02\x02\u0218\u0219\x07v\x02\x02\u0219\u021A" +
		"\x07C\x02\x02\u021A\u021B\x07i\x02\x02\u021B\u021C\x07i\x02\x02\u021C" +
		"\u021D\x07K\x02\x02\u021D\u021E\x07p\x02\x02\u021E\u021F\x07v\x02\x02" +
		"\u021F\u0220\x07X\x02\x02\u0220\u0221\x07c\x02\x02\u0221\u0222\x07n\x02" +
		"\x02\u0222\u0223\x07w\x02\x02\u0223\u0230\x07g\x02\x02\u0224\u0225\x07" +
		"j\x02\x02\u0225\u0226\x07c\x02\x02\u0226\u0227\x07u\x02\x02\u0227\u0228" +
		"\x07P\x02\x02\u0228\u0229\x07g\x02\x02\u0229\u022A\x07z\x02\x02\u022A" +
		"\u0230\x07v\x02\x02\u022B\u022C\x07p\x02\x02\u022C\u022D\x07g\x02\x02" +
		"\u022D\u022E\x07z\x02\x02\u022E\u0230\x07v\x02\x02\u022F\u01F4\x03\x02" +
		"\x02\x02\u022F\u0205\x03\x02\x02\x02\u022F\u0216\x03\x02\x02\x02\u022F" +
		"\u0224\x03\x02\x02\x02\u022F\u022B\x03\x02\x02\x02\u0230>\x03\x02\x02" +
		"\x02\u0231\u0232\x05\x13\t\x02\u0232\u0233\x05Y,\x02\u0233\u0234\x05O" +
		"\'\x02\u0234@\x03\x02\x02\x02\u0235\u0236\x05g3\x02\u0236\u0237\x05O\'" +
		"\x02\u0237\u0238\x05i4\x02\u0238B\x03\x02\x02\x02\u0239\u023A\x07d\x02" +
		"\x02\u023A\u023B\x07q\x02\x02\u023B\u023C\x07q\x02\x02\u023C\u023D\x07" +
		"n\x02\x02\u023D\u023E\x07g\x02\x02\u023E\u023F\x07c\x02\x02\u023F\u0266" +
		"\x07p\x02\x02\u0240\u0241\x07k\x02\x02\u0241\u0242\x07p\x02\x02\u0242" +
		"\u0266\x07v\x02\x02\u0243\u0244\x07f\x02\x02\u0244\u0245\x07q\x02\x02" +
		"\u0245\u0246\x07w\x02\x02\u0246\u0247\x07d\x02\x02\u0247\u0248\x07n\x02" +
		"\x02\u0248\u0266\x07g\x02\x02\u0249\u024A\x07u\x02\x02\u024A\u024B\x07" +
		"v\x02\x02\u024B\u024C\x07t\x02\x02\u024C\u024D\x07k\x02\x02\u024D\u024E" +
		"\x07p\x02\x02\u024E\u0266\x07i\x02\x02\u024F\u0250\x07C\x02\x02\u0250" +
		"\u0251\x07i\x02\x02\u0251\u0252\x07i\x02\x02\u0252\u0253\x07P\x02\x02" +
		"\u0253\u0254\x07q\x02\x02\u0254\u0255\x07f\x02\x02\u0255\u0256\x07g\x02" +
		"\x02\u0256\u0266\x07u\x02\x02\u0257\u0258\x07C\x02\x02\u0258\u0259\x07" +
		"i\x02\x02\u0259\u025A\x07i\x02\x02\u025A\u025B\x07K\x02\x02\u025B\u025C" +
		"\x07p\x02\x02\u025C\u025D\x07h\x02\x02\u025D\u0266\x07q\x02\x02\u025E" +
		"\u025F\x07C\x02\x02\u025F\u0260\x07i\x02\x02\u0260\u0261\x07i\x02\x02" +
		"\u0261\u0262\x07T\x02\x02\u0262\u0263\x07q\x02\x02\u0263\u0266\x07y\x02" +
		"\x02\u0264\u0266\x05E\"\x02\u0265\u0239\x03\x02\x02\x02\u0265\u0240\x03" +
		"\x02\x02\x02\u0265\u0243\x03\x02\x02\x02\u0265\u0249\x03\x02\x02\x02\u0265" +
		"\u024F\x03\x02\x02\x02\u0265\u0257\x03\x02\x02\x02\u0265\u025E\x03\x02" +
		"\x02\x02\u0265\u0264\x03\x02\x02\x02\u0266D\x03\x02\x02\x02\u0267\u0268" +
		"\x07E\x02\x02\u0268\u0269\x07w\x02\x02\u0269\u026A\x07u\x02\x02\u026A" +
		"\u026B\x07v\x02\x02\u026B\u026C\x07q\x02\x02\u026C\u026D\x07o\x02\x02" +
		"\u026D\u026E\x07X\x02\x02\u026E\u026F\x07c\x02\x02\u026F\u0270\x07t\x02" +
		"\x02\u0270\u0276\x03\x02\x02\x02\u0271\u0277\t\x02\x02\x02\u0272\u0273" +
		"\x073\x02\x02\u0273\u0277\t\x03\x02\x02\u0274\u0275\x074\x02\x02\u0275" +
		"\u0277\x072\x02\x02\u0276\u0271\x03\x02\x02\x02\u0276\u0272\x03\x02\x02" +
		"\x02\u0276\u0274\x03\x02\x02\x02\u0277F\x03\x02\x02\x02\u0278\u027A\x05" +
		"S)\x02\u0279\u0278\x03\x02\x02\x02\u027A\u027B\x03\x02\x02\x02\u027B\u0279" +
		"\x03\x02\x02\x02\u027B\u027C\x03\x02\x02\x02\u027C\u027D\x03\x02\x02\x02" +
		"\u027D\u0281\x05Y,\x02\u027E\u0280\x05S)\x02\u027F\u027E\x03\x02\x02\x02" +
		"\u0280\u0283\x03\x02\x02\x02\u0281\u027F\x03\x02\x02\x02\u0281\u0282\x03" +
		"\x02\x02\x02\u0282\u0291\x03\x02\x02\x02\u0283\u0281\x03\x02\x02\x02\u0284" +
		"\u0286\x05S)\x02\u0285\u0284\x03\x02\x02\x02\u0286\u0289\x03\x02\x02\x02" +
		"\u0287\u0285\x03\x02\x02\x02\u0287\u0288\x03\x02\x02\x02\u0288\u028A\x03" +
		"\x02\x02\x02\u0289\u0287\x03\x02\x02\x02\u028A\u028C\x05Y,\x02\u028B\u028D" +
		"\x05S)\x02\u028C\u028B\x03\x02\x02\x02\u028D\u028E\x03\x02\x02\x02\u028E" +
		"\u028C\x03\x02\x02\x02\u028E\u028F\x03\x02\x02\x02\u028F\u0291\x03\x02" +
		"\x02\x02\u0290\u0279\x03\x02\x02\x02\u0290\u0287\x03\x02\x02\x02\u0291" +
		"H\x03\x02\x02\x02\u0292\u0294\x05S)\x02\u0293\u0292\x03\x02\x02\x02\u0294" +
		"\u0295\x03\x02\x02\x02\u0295\u0293\x03\x02\x02\x02\u0295\u0296\x03\x02" +
		"\x02\x02\u0296J\x03\x02\x02\x02\u0297\u0298\x07v\x02\x02\u0298\u0299\x07" +
		"t\x02\x02\u0299\u029A\x07w\x02\x02\u029A\u02A1\x07g\x02\x02\u029B\u029C" +
		"\x07h\x02\x02\u029C\u029D\x07c\x02\x02\u029D\u029E\x07n\x02\x02\u029E" +
		"\u029F\x07u\x02\x02\u029F\u02A1\x07g\x02\x02\u02A0\u0297\x03\x02\x02\x02" +
		"\u02A0\u029B\x03\x02\x02\x02\u02A1L\x03\x02\x02\x02\u02A2\u02A6\x05c1" +
		"\x02\u02A3\u02A5\x05{=\x02\u02A4\u02A3\x03\x02\x02\x02\u02A5\u02A8\x03" +
		"\x02\x02\x02\u02A6\u02A4\x03\x02\x02\x02\u02A6\u02A7\x03\x02\x02\x02\u02A7" +
		"\u02A9\x03\x02\x02\x02\u02A8\u02A6\x03\x02\x02\x02\u02A9\u02AA\x05c1\x02" +
		"\u02AAN\x03\x02\x02\x02\u02AB\u02AE\x05Q(\x02\u02AC\u02AE\x05U*\x02\u02AD" +
		"\u02AB\x03\x02\x02\x02\u02AD\u02AC\x03\x02\x02\x02\u02AE\u02B4\x03\x02" +
		"\x02\x02\u02AF\u02B3\x05Q(\x02\u02B0\u02B3\x05S)\x02\u02B1\u02B3\x05U" +
		"*\x02\u02B2\u02AF\x03\x02\x02\x02\u02B2\u02B0\x03\x02\x02\x02\u02B2\u02B1" +
		"\x03\x02\x02\x02\u02B3\u02B6\x03\x02\x02\x02\u02B4\u02B2\x03\x02\x02\x02" +
		"\u02B4\u02B5\x03\x02\x02\x02\u02B5P\x03\x02\x02\x02\u02B6\u02B4\x03\x02" +
		"\x02\x02\u02B7\u02B8\t\x04\x02\x02\u02B8R\x03\x02\x02\x02\u02B9\u02BA" +
		"\t\x03\x02\x02\u02BAT\x03\x02\x02\x02\u02BB\u02BC\x07a\x02\x02\u02BCV" +
		"\x03\x02\x02\x02\u02BD\u02BE\x07=\x02\x02\u02BEX\x03\x02\x02\x02\u02BF" +
		"\u02C0\x070\x02\x02\u02C0Z\x03\x02\x02\x02\u02C1\u02C2\x07?\x02\x02\u02C2" +
		"\\\x03\x02\x02\x02\u02C3\u02C4\x07.\x02\x02\u02C4^\x03\x02\x02\x02\u02C5" +
		"\u02C6\x07<\x02\x02\u02C6`\x03\x02\x02\x02\u02C7\u02C8\x07%\x02\x02\u02C8" +
		"b\x03\x02\x02\x02\u02C9\u02CA\x07$\x02\x02\u02CAd\x03\x02\x02\x02\u02CB" +
		"\u02CD\x07\x0F\x02\x02\u02CC\u02CB\x03\x02\x02\x02\u02CC\u02CD\x03\x02" +
		"\x02\x02\u02CD\u02CE\x03\x02\x02\x02\u02CE\u02CF\x07\f\x02\x02\u02CFf" +
		"\x03\x02\x02\x02\u02D0\u02D1\x07*\x02\x02\u02D1h\x03\x02\x02\x02\u02D2" +
		"\u02D3\x07+\x02\x02\u02D3j\x03\x02\x02\x02\u02D4\u02D5\x07]\x02\x02\u02D5" +
		"l\x03\x02\x02\x02\u02D6\u02D7\x07_\x02\x02\u02D7n\x03\x02\x02\x02\u02D8" +
		"\u02D9\x07}\x02\x02\u02D9p\x03\x02\x02\x02\u02DA\u02DB\x07\x7F\x02\x02" +
		"\u02DBr\x03\x02\x02\x02\u02DC\u02DD\n\x05\x02\x02\u02DDt\x03\x02\x02\x02" +
		"\u02DE\u02DF\n\x06\x02\x02\u02DFv\x03\x02\x02\x02\u02E0\u02E1\n\x07\x02" +
		"\x02\u02E1x\x03\x02\x02\x02\u02E2\u02E3\n\b\x02\x02\u02E3z\x03\x02\x02" +
		"\x02\u02E4\u02E5\n\t\x02\x02\u02E5|\x03\x02\x02\x02\u02E6\u02E7\x07~\x02" +
		"\x02\u02E7\u02E8\x07~\x02\x02\u02E8~\x03\x02\x02\x02\u02E9\u02EA\x07(" +
		"\x02\x02\u02EA\u02EB\x07(\x02\x02\u02EB\x80\x03\x02\x02\x02\u02EC\u02ED" +
		"\x07~\x02\x02\u02ED\x82\x03\x02\x02\x02\u02EE\u02EF\x07`\x02\x02\u02EF" +
		"\x84\x03\x02\x02\x02\u02F0\u02F1\x07(\x02\x02\u02F1\x86\x03\x02\x02\x02" +
		"\u02F2\u02F3\x07?\x02\x02\u02F3\u02F4\x07?\x02\x02\u02F4\x88\x03\x02\x02" +
		"\x02\u02F5\u02F6\x07#\x02\x02\u02F6\u02F7\x07?\x02\x02\u02F7\x8A\x03\x02" +
		"\x02\x02\u02F8\u02F9\x07>\x02\x02\u02F9\x8C\x03\x02\x02\x02\u02FA\u02FB" +
		"\x07@\x02\x02\u02FB\x8E\x03\x02\x02\x02\u02FC\u02FD\x07>\x02\x02\u02FD" +
		"\u02FE\x07?\x02\x02\u02FE\x90\x03\x02\x02\x02\u02FF\u0300\x07@\x02\x02" +
		"\u0300\u0301\x07?\x02\x02\u0301\x92\x03\x02\x02\x02\u0302\u0303\x07-\x02" +
		"\x02\u0303\x94\x03\x02\x02\x02\u0304\u0305\x07/\x02\x02\u0305\x96\x03" +
		"\x02\x02\x02\u0306\u0307\x07,\x02\x02\u0307\x98\x03\x02\x02\x02\u0308" +
		"\u0309\x071\x02\x02\u0309\x9A\x03\x02\x02\x02\u030A\u030B\x07\'\x02\x02" +
		"\u030B\x9C\x03\x02\x02\x02\u030C\u030D\x07\x80\x02\x02\u030D\x9E\x03\x02" +
		"\x02\x02\u030E\u030F\x07#\x02\x02\u030F\xA0\x03\x02\x02\x02\u0310\u0311" +
		"\x07-\x02\x02\u0311\u0312\x07-\x02\x02\u0312\xA2\x03\x02\x02\x02\u0313" +
		"\u0314\x07/\x02\x02\u0314\u0315\x07/\x02\x02\u0315\xA4\x03\x02\x02\x02" +
		"\u0316\u0318\t\n\x02\x02\u0317\u0316\x03\x02\x02\x02\u0318\u0319\x03\x02" +
		"\x02\x02\u0319\u0317\x03\x02\x02\x02\u0319\u031A\x03\x02\x02\x02\u031A" +
		"\u031B\x03\x02\x02\x02\u031B\u031C\bR\x04\x02\u031C\xA6\x03\x02\x02\x02" +
		"\u031D\u031E\v\x02\x02\x02\u031E\xA8\x03\x02\x02\x02\u031F\u0320\x07c" +
		"\x02\x02\u0320\u0321\x07u\x02\x02\u0321\xAA\x03\x02\x02\x02\u0322\u0323" +
		"\x05\xAFW\x02\u0323\u0324\x05\xADV\x02\u0324\u0325\x05\xB1X\x02\u0325" +
		"\xAC\x03\x02\x02\x02\u0326\u032A\t\v\x02\x02\u0327\u0329\t\f\x02\x02\u0328" +
		"\u0327\x03\x02\x02\x02\u0329\u032C\x03\x02\x02\x02\u032A\u0328\x03\x02" +
		"\x02\x02\u032A\u032B\x03\x02\x02\x02\u032B\xAE\x03\x02\x02\x02\u032C\u032A" +
		"\x03\x02\x02\x02\u032D\u032E\x07>\x02\x02\u032E\xB0\x03\x02\x02\x02\u032F" +
		"\u0330\x07@\x02\x02\u0330\xB2\x03\x02\x02\x02\u0331\u0332\x070\x02\x02" +
		"\u0332\xB4\x03\x02\x02\x02\u0333\u0334\x07=\x02\x02\u0334\u0335\x03\x02" +
		"\x02\x02\u0335\u0336\bZ\x05\x02\u0336\xB6\x03\x02\x02\x02\u0337\u0338" +
		"\x07T\x02\x02\u0338\u0339\x07G\x02\x02\u0339\u033A\x07p\x02\x02\u033A" +
		"\u033B\x07f\x02\x02\u033B\u033C\x03\x02\x02\x02\u033C\u033D\b[\x05\x02" +
		"\u033D\xB8\x03\x02\x02\x02\u033E\u0340\x07\x0F\x02\x02\u033F\u033E\x03" +
		"\x02\x02\x02\u033F\u0340\x03\x02\x02\x02\u0340\u0341\x03\x02\x02\x02\u0341" +
		"\u0342\x07\f\x02\x02\u0342\u0343\x03\x02\x02\x02\u0343\u0344\b\\\x04\x02" +
		"\u0344\xBA\x03\x02\x02\x02\u0345\u0346\v\x02\x02\x02\u0346\xBC\x03\x02" +
		"\x02\x02\x1E\x02\x03\x04\xC1\u012E\u0172\u017A\u0188\u018A\u01F2\u022F" +
		"\u0265\u0276\u027B\u0281\u0287\u028E\u0290\u0295\u02A0\u02A6\u02AD\u02B2" +
		"\u02B4\u02CC\u0319\u032A\u033F\x06\x04\x03\x02\x04\x04\x02\b\x02\x02\x04" +
		"\x02\x02";
	public static readonly _serializedATN: string = Utils.join(
		[
			AScriptLexer._serializedATNSegment0,
			AScriptLexer._serializedATNSegment1,
		],
		"",
	);
	public static __ATN: ATN;
	public static get _ATN(): ATN {
		if (!AScriptLexer.__ATN) {
			AScriptLexer.__ATN = new ATNDeserializer().deserialize(Utils.toCharArray(AScriptLexer._serializedATN));
		}

		return AScriptLexer.__ATN;
	}

}

