import { Modifier } from 'algo-react-dataviz';
import { DraggableLocation } from 'react-beautiful-dnd';
import { v4 } from 'uuid';
import { Characteristic, DroppableState } from '../../../shared/dataTypes';
import { GroupingLayerId } from './groupingLayerId';

export const AVAILABLE_CHARS = 'AVAILABLE_CHARS';
export const CHARACTERISTICS_ID = 'chars';
export const VERTICAL_ID = 'verticalChars';
export const FILTER_CHARS = 'filterChars';
export const HORIZONTAL_ID = 'horizontalChars';

export const reorder = <T>(list: T[], startIndex: number, endIndex: number): T[] => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const copy = (
  source: Characteristic[],
  destination: Characteristic[],
  droppableSource: DraggableLocation,
  droppableDestination: DraggableLocation,
) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const item = sourceClone[droppableSource.index];

  destClone.splice(droppableDestination.index, 0, item);
  return destClone;
};

export const move: (
  source: Characteristic[],
  destination: Characteristic[],
  droppableSource: DraggableLocation,
  droppableDestination: DraggableLocation,
) => DroppableState = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};

export const defaultHorizChar: Characteristic = {
  name: 'Characteristics',
  charId: GroupingLayerId.CHARACTERISTIC,
  classes: null,
  isGroupingLayer: true,
  draggableId: v4(),
  dataType: null,
  modifier: Modifier.PORT,
};

export const initialDroppableState: DroppableState = {
  chars: [],
  verticalChars: [],
  horizontalChars: [defaultHorizChar],
  filterChars: [],
};
