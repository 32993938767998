import axios from 'axios';
import { baseUrl } from '../shared/environment';

export const GetTakerGrouperData = () => GetGrouperData('TakerHierarchyGrouper');

export const GetProductSetHierarchyGrouperData = () => GetGrouperData('ProductSetHierarchyGrouper');

export const GetEntityHierarchyGrouperData = () => GetGrouperData('EntityHierarchyGrouper');

const GetGrouperData = (grouperName: string) => {
  return axios.get(`${baseUrl}api/grouperData`, {
    params: { grouperName: grouperName },
  });
};
