import { v4 as uuid } from 'uuid';
import { createObject, mapObject, moveItem } from '../../shared/utils';
import { GroupingNode } from './customGrouping';
import { GroupingStrategy, GroupingStrategyType } from './groupingStrategy';
import {
  combineNodeErrors,
  createNodeErrorsForIds,
  GroupingTreeErrors,
  GroupingTreeErrorType,
  validateGroupingStrategy,
} from './groupingTreeErrors';

/**
 * This is a wrapper to augment a `GroupingNode` with lots of logical
 * methods for manipulating it. We use `node.serialize()` to get back
 * a simple object for the Redux store.
 *
 * For all of these methods, `path: string[]` refers to a sequence
 * of descendant IDs specifying the relative path to the target node
 * -- in particular, `[]` targets the root node.
 */
interface IGroupingNodeController extends GroupingNode {
  // Narrow this type to enforce this interface on them too
  readonly children: IGroupingNodeController[];

  clone: (preserveIds: boolean) => IGroupingNodeController;
  serialize: () => GroupingNode;
  toString: () => string;
  toLabel: () => string;

  getPath: (id: string) => string[] | null;
  getPaths: (ids: string[]) => string[][];
  getParentPath: (id: string) => string[] | null;
  getNodeById: (id: string) => GroupingNodeController | null;
  getAllIds: () => string[];
  getAllNodes: () => GroupingNodeController[];

  isRootNode: (id: string) => boolean;
  isLeafNode: (id: string) => boolean;

  getNode: (path: string[]) => IGroupingNodeController | null;
  getNodeId: (path: string[]) => string | null;
  addNode: (path: string[], node?: Partial<GroupingNode>) => IGroupingNodeController;
  deleteNode: (path: string[]) => IGroupingNodeController | null;
  deleteNodes: (paths: string[][]) => IGroupingNodeController | null;

  // Like get/add, but scrubs IDs, and supports multiselect
  copyNodes: (paths: string[][]) => IGroupingNodeController[];
  pasteNodes: (path: string[], nodes: IGroupingNodeController[]) => IGroupingNodeController;

  moveChildrenUp: (parentPath: string[], childIds: string[]) => IGroupingNodeController;
  moveChildrenDown: (parentPath: string[], childIds: string[]) => IGroupingNodeController;

  setName: (path: string[], name: string) => IGroupingNodeController;
  setGroupingStrategy: (
    path: string[],
    groupingStrategy: GroupingStrategy | null,
  ) => IGroupingNodeController;

  allowCreateNode: (selectedIds: string[], strategyType: GroupingStrategyType) => boolean;
  allowCreateAnyNode: (selectedIds: string[]) => boolean;
  allowCreatePredicateNode: (selectedIds: string[]) => boolean;
  allowCreateCharacteristicNode: (selectedIds: string[]) => boolean;
  allowCreateRemainderNode: (selectedIds: string[]) => boolean;
  allowRename: (selectedIds: string[]) => boolean;
  allowMoveUp: (selectedIds: string[]) => boolean;
  allowMoveDown: (selectedIds: string[]) => boolean;
  allowDelete: (selectedIds: string[]) => boolean;
  allowCopy: (selectedIds: string[]) => boolean;
  allowPaste: (selectedIds: string[], nodes: IGroupingNodeController[]) => boolean;

  onCreateNode: (id: string, strategyType: GroupingStrategyType) => GroupingNodeController;
  onRename: (selectedIds: string[], name: string) => IGroupingNodeController;
  onMoveUp: (selectedIds: string[]) => IGroupingNodeController;
  onMoveDown: (selectedIds: string[]) => IGroupingNodeController;
  onDelete: (selectedIds: string[]) => IGroupingNodeController;
  onCopy: (selectedIds: string[]) => IGroupingNodeController[];
  onPaste: (selectedIds: string[], nodes: IGroupingNodeController[]) => IGroupingNodeController;

  getErrors: () => GroupingTreeErrors;
}

export type PathsById = Record<string, string[]>;

const DEFAULT_NODE_NAME = 'Untitled';
const CHARACTERISTIC_STRATEGY_NODE_NAME = 'Normal Grouping';
class GroupingNodeController implements IGroupingNodeController {
  private readonly pathsById: PathsById;

  private constructor(
    readonly id: string,
    readonly name: string,
    readonly groupingStrategy: GroupingStrategy | null,
    readonly children: GroupingNodeController[],
  ) {
    this.pathsById = children.reduce<PathsById>(
      (pathsById, child) => ({
        ...pathsById,
        ...mapObject({
          initialObject: child.pathsById,
          mapValue: path => [id, ...path],
        }),
        [child.id]: [id, child.id],
      }),
      {
        [id]: [id],
      },
    );
  }

  static create = (
    {
      id,
      name = DEFAULT_NODE_NAME,
      groupingStrategy = null,
      children = [],
    }: Partial<GroupingNode> = {},
    preserveIds = true,
  ): GroupingNodeController =>
    new GroupingNodeController(
      (preserveIds && id) || uuid(),
      name,
      groupingStrategy,
      children.map(child => GroupingNodeController.create(child, preserveIds)),
    );

  static fromOperations = (
    operations: Array<(node: GroupingNodeController) => GroupingNodeController>,
    initialNode = GroupingNodeController.create(),
  ) => operations.reduce((node, nextOperation) => nextOperation(node), initialNode);

  static serialize = ({
    id,
    name,
    groupingStrategy,
    children,
  }: GroupingNodeController): GroupingNode => ({
    id,
    name,
    groupingStrategy,
    children: children.map(GroupingNodeController.serialize),
  });

  /**
   * This wraps a simple function in logic to crawl the
   * tree and execute it only along the specified path.
   */
  private static doByPath = <T extends unknown[]>(
    doToSelf: (node: GroupingNodeController, ...args: T) => GroupingNodeController | null,
  ) => (
    node: GroupingNodeController,
    path: string[],
    ...args: T
  ): GroupingNodeController | null => {
    if (path.length === 0) {
      return doToSelf(node, ...args);
    }

    const [childId, ...restIds] = path;
    return GroupingNodeController.create({
      ...node,
      children: node.children
        .map(child =>
          child.id !== childId
            ? child
            : GroupingNodeController.doByPath(doToSelf)(child, restIds, ...args),
        )
        .filter(child => child !== null),
    });
  };

  private static getNode = (
    node: GroupingNodeController | null,
    path: string[] | null,
    preserveIds = true,
  ): GroupingNodeController | null => {
    if (node === null || path === null) {
      return null;
    }

    if (path.length === 0) {
      return node.clone(preserveIds);
    }

    const [childId, ...restIds] = path;
    return GroupingNodeController.getNode(
      node.children.find(({ id }) => id === childId) ?? null,
      restIds,
      preserveIds,
    );
  };

  private static addNode = GroupingNodeController.doByPath(
    (node: GroupingNodeController, newNode?: Partial<GroupingNode>) =>
      GroupingNodeController.create({
        ...node,
        children: [...node.children, GroupingNodeController.create(newNode)],
      }),
  );

  private static deleteNode = GroupingNodeController.doByPath(
    (_node: GroupingNodeController) => null,
  );

  private static deleteNodes = (node: GroupingNodeController, paths: string[][]) =>
    paths.reduce(GroupingNodeController.deleteNode, node);

  private static copyNodes = (node: GroupingNodeController, paths: string[][]) =>
    paths.map(path => GroupingNodeController.getNode(node, path, false));

  private static pasteNodes = GroupingNodeController.doByPath(
    (node: GroupingNodeController, newNodes: GroupingNodeController[]) =>
      GroupingNodeController.create({
        ...node,
        children: [
          ...node.children,
          ...newNodes.map(newNode => GroupingNodeController.create(newNode, false)),
        ],
      }),
  );

  private static moveChildrenUp = GroupingNodeController.doByPath(
    (node: GroupingNodeController, childIds: string[]) =>
      GroupingNodeController.create({
        ...node,
        children: childIds
          .map(childId => node.children.findIndex(({ id }) => id === childId))
          .filter(index => ![-1, 0].includes(index))
          .sort()
          .reduce((acc, nextIndex) => moveItem(acc, nextIndex, nextIndex - 1), node.children),
      }),
  );

  private static moveChildrenDown = GroupingNodeController.doByPath(
    (node: GroupingNodeController, childIds: string[]) =>
      GroupingNodeController.create({
        ...node,
        children: childIds
          .map(childId => node.children.findIndex(({ id }) => id === childId))
          .filter(index => ![-1, node.children.length - 1].includes(index))
          .sort()
          .reduceRight((acc, nextIndex) => moveItem(acc, nextIndex, nextIndex + 1), node.children),
      }),
  );

  private static setName = GroupingNodeController.doByPath(
    (node: GroupingNodeController, name: string) =>
      GroupingNodeController.create({
        ...node,
        name,
      }),
  );

  private static setGroupingStrategy = GroupingNodeController.doByPath(
    (node: GroupingNodeController, groupingStrategy: GroupingStrategy | null) =>
      GroupingNodeController.create({
        ...node,
        groupingStrategy,
      }),
  );

  clone = (preserveIds = true) => GroupingNodeController.create(this, preserveIds);
  serialize = () => GroupingNodeController.serialize(this);
  toString = () => `GroupingNodeController(${JSON.stringify(this.serialize(), null, 2)})`;
  toLabel = () => `Node[${this.id.slice(0, 5)}-${this.name}]`;

  hasNode = (id: string) => !!this.pathsById[id];
  getPath = (id: string) => this.pathsById[id]?.slice(1) ?? null;
  getPaths = (ids: string[]) => ids.map(this.getPath).filter(path => path !== null);
  getParentPath = (id: string) => this.getPath(id)?.slice(0, -1) ?? null;
  getNodeById = (id: string) => this.getNode(this.getPath(id));
  getNameById = (id: string) => this.getNode(this.getPath(id))?.name ?? null;
  getParentById = (id: string) => this.getNode(this.getParentPath(id));
  getAllIds = () => Object.keys(this.pathsById);
  getAllNodes = () => this.getAllIds().map(this.getNodeById);

  isRootNode = (id: string) => this.getPath(id)?.length === 0;
  isLeafNode = (id: string) => this.getNodeById(id)?.children.length === 0;

  getNode = (path: string[]) => GroupingNodeController.getNode(this, path);
  getNodeId = (path: string[]) => this.getNode(path)?.id ?? null;
  addNode = (path: string[], newNode?: Partial<GroupingNode>) =>
    GroupingNodeController.addNode(this, path, newNode);
  deleteNode = (path: string[]) => GroupingNodeController.deleteNode(this, path);
  deleteNodes = (paths: string[][]) => GroupingNodeController.deleteNodes(this, paths);

  copyNodes = (paths: string[][]) => GroupingNodeController.copyNodes(this, paths);
  pasteNodes = (path: string[], nodes: GroupingNodeController[]) =>
    GroupingNodeController.pasteNodes(this, path, nodes);

  moveChildrenUp = (parentPath: string[], childIds: string[]) =>
    GroupingNodeController.moveChildrenUp(this, parentPath, childIds);
  moveChildrenDown = (parentPath: string[], childIds: string[]) =>
    GroupingNodeController.moveChildrenDown(this, parentPath, childIds);

  setName = (path: string[], name: string) => GroupingNodeController.setName(this, path, name);
  setGroupingStrategy = (path: string[], groupingStrategy: GroupingStrategy | null) =>
    GroupingNodeController.setGroupingStrategy(this, path, groupingStrategy);

  /**
   * Validation helpers
   */
  private isRootNodeSelected = (selectedIds: string[]) =>
    this.getPaths(selectedIds).some(path => path.length === 0);

  private isDirectChildOfRootNode = (id: string) => this.getPath(id).length === 1;

  private hasSibling = (id: string) => (this.getParentById(id)?.children ?? []).length >= 2;

  private anyNodeHasRemainderGrouping = () =>
    this.getAllNodes().some(
      ({ groupingStrategy }) => groupingStrategy?.type === GroupingStrategyType.REMAINDER,
    );

  private anyOtherNodeHasRemainderGrouping = (id: string) =>
    this.getAllNodes().some(
      ({ id: otherId, groupingStrategy }) =>
        otherId !== id && groupingStrategy?.type === GroupingStrategyType.REMAINDER,
    );

  private areSelectedNodesSiblings = (selectedIds: string[]) => {
    if (selectedIds.length === 0) {
      return true;
    }

    const [firstPath, ...restPaths] = this.getPaths(selectedIds);
    return restPaths.every(
      path =>
        path.length === firstPath.length &&
        path.slice(0, -1).every((segment, index) => segment === firstPath[index]),
    );
  };

  // Since we'll have called `areSelectedNodesSiblings` first, we can assume any
  // given child shares a parent with all selected nodes.
  private isFirstSiblingSelected = (selectedIds: string[]) =>
    selectedIds.includes(this.getParentById(selectedIds[0]).children[0].id);
  private isLastSiblingSelected = (selectedIds: string[]) => {
    const siblings = this.getParentById(selectedIds[0]).children ?? [];
    return selectedIds.includes(siblings[siblings.length - 1]?.id);
  };

  private hasCharacteristicGrouping = () =>
    this.groupingStrategy?.type === GroupingStrategyType.CHARACTERISTIC;
  private hasRemainderGrouping = () =>
    this.groupingStrategy?.type === GroupingStrategyType.REMAINDER;

  private isAnyCharacteristicGroupingSelected = (selectedIds: string[]) =>
    selectedIds.map(this.getNodeById).some(node => node.hasCharacteristicGrouping());

  private hasChild = (id: string) => this.getNodeById(id).children.length >= 1;

  private hasChildWithCharacteristicGrouping = (id: string) =>
    this.getNodeById(id).children.some(child => child.hasCharacteristicGrouping());

  /**
   * Return the length of the longest path from this node to a leaf node --
   * in other words, how many generations of children the node has.
   */
  private static getHeight = (node: GroupingNodeController | null) =>
    node === null
      ? null
      : Math.max(0, ...node.children.map(child => GroupingNodeController.getHeight(child) + 1));

  getHeight = () => GroupingNodeController.getHeight(this);

  allowCreateNode = (selectedIds: string[], strategyType: GroupingStrategyType) => {
    switch (strategyType) {
      case GroupingStrategyType.PREDICATE:
        return this.allowCreatePredicateNode(selectedIds);
      case GroupingStrategyType.CHARACTERISTIC:
        return this.allowCreateCharacteristicNode(selectedIds);
      case GroupingStrategyType.REMAINDER:
        return this.allowCreateRemainderNode(selectedIds);
    }
  };

  /**
   * Conditions (shared by each GroupingStrategyType)
   * - Exactly one node is selected
   * - The selected node doesn't have a Remainder or Characteristic grouping
   * - The selected node doesn't have a child with a Characteristic grouping
   */
  allowCreateAnyNode = (selectedIds: string[]) =>
    selectedIds.length === 1 &&
    ![GroupingStrategyType.CHARACTERISTIC, GroupingStrategyType.REMAINDER].includes(
      this.getNodeById(selectedIds[0]).groupingStrategy?.type,
    ) &&
    !this.hasChildWithCharacteristicGrouping(selectedIds[0]);

  /**
   * (No additional conditions)
   */
  allowCreatePredicateNode = (selectedIds: string[]) => this.allowCreateAnyNode(selectedIds);

  /**
   * Conditions
   * - The selected node has a Predicate grouping
   * - The selected node is not the root node
   * - The selected node is a leaf node
   */
  allowCreateCharacteristicNode = (selectedIds: string[]) =>
    this.allowCreateAnyNode(selectedIds) &&
    !this.isRootNode(selectedIds[0]) &&
    !this.hasChild(selectedIds[0]);

  /**
   * Conditions
   * - The selected node is the root node
   * - No node already has a Remainder grouping
   */
  allowCreateRemainderNode = (selectedIds: string[]) =>
    this.allowCreateAnyNode(selectedIds) &&
    this.isRootNode(selectedIds[0]) &&
    !this.anyNodeHasRemainderGrouping();

  onCreateNode = (id: string, strategyType: GroupingStrategyType | null) =>
    this.addNode(this.getPath(id), GroupingNodeController.getDefaultNodeProps(strategyType));

  private static getDefaultNodeProps = (
    strategyType: GroupingStrategyType,
  ): Partial<GroupingNode> => {
    switch (strategyType) {
      case GroupingStrategyType.PREDICATE: {
        return {};
      }

      case GroupingStrategyType.CHARACTERISTIC: {
        return {
          name: CHARACTERISTIC_STRATEGY_NODE_NAME,
          groupingStrategy: {
            type: GroupingStrategyType.CHARACTERISTIC,
            groupings: [
              {
                characteristicId: null,
                breakpoints: null,
              },
            ],
          },
        };
      }

      case GroupingStrategyType.REMAINDER: {
        return {
          groupingStrategy: {
            type: GroupingStrategyType.REMAINDER,
          },
        };
      }
    }
  };

  /**
   * Conditions
   * - Exactly one node is selected
   * - The selected node is not the root node
   * - The selected node does not have a Characteristic grouping
   * - No node is currently being renamed (handled by view)
   */
  allowRename = (selectedIds: string[]) =>
    selectedIds.length === 1 &&
    !this.isRootNodeSelected(selectedIds) &&
    !this.isAnyCharacteristicGroupingSelected(selectedIds);
  onRename = (selectedIds: string[], name: string) =>
    this.setName(this.getPath(selectedIds[0]), name);

  /**
   * Conditions
   * - At least one node is selected
   * - The root node is not selected
   * - All selected nodes are siblings
   * - The first/last sibling is not selected (as appropriate)
   * - No node with a Characteristic grouping is selected
   */
  allowMoveUp = (selectedIds: string[]) =>
    selectedIds.length >= 1 &&
    !this.isRootNodeSelected(selectedIds) &&
    this.areSelectedNodesSiblings(selectedIds) &&
    !this.isFirstSiblingSelected(selectedIds) &&
    !this.isAnyCharacteristicGroupingSelected(selectedIds);
  allowMoveDown = (selectedIds: string[]) =>
    selectedIds.length >= 1 &&
    !this.isRootNodeSelected(selectedIds) &&
    this.areSelectedNodesSiblings(selectedIds) &&
    !this.isLastSiblingSelected(selectedIds) &&
    !this.isAnyCharacteristicGroupingSelected(selectedIds);
  onMoveUp = (selectedIds: string[]) =>
    this.moveChildrenUp(this.getParentPath(selectedIds[0]), selectedIds);
  onMoveDown = (selectedIds: string[]) =>
    this.moveChildrenDown(this.getParentPath(selectedIds[0]), selectedIds);

  /**
   * Conditions
   * - At least one node is selected
   * - The root node is not selected
   * - TODO: If all siblings of a node with a Remainder grouping are selected,
   *   that node must be selected as well (it can't be left an only child).
   *   (address with SD-2455)
   */
  allowDelete = (selectedIds: string[]) =>
    selectedIds.length >= 1 && !this.isRootNodeSelected(selectedIds);
  onDelete = (selectedIds: string[]) => this.deleteNodes(this.getPaths(selectedIds));

  /**
   * Conditions
   * - At least one node is selected
   * - The root node is not selected
   * - All selected nodes are siblings
   * - No node with a Characteristic grouping is selected
   */
  allowCopy = (selectedIds: string[]) =>
    selectedIds.length >= 1 &&
    !this.isRootNodeSelected(selectedIds) &&
    this.areSelectedNodesSiblings(selectedIds) &&
    !this.isAnyCharacteristicGroupingSelected(selectedIds);
  onCopy = (selectedIds: string[]) => this.copyNodes(this.getPaths(selectedIds));

  /**
   * Conditions
   * - Exactly one node is selected
   * - The selected node does not have a Characteristic grouping
   * - The selected node doesn't have a child with a Characteristic grouping
   * - If the root node is selected, no node with a Characteristic
   *   grouping is copied
   *
   * (if Characteristic groupings were copyable, there would be more)
   */
  allowPaste = (selectedIds: string[], nodes: GroupingNodeController[]) =>
    selectedIds.length === 1 &&
    !this.getNodeById(selectedIds[0]).hasCharacteristicGrouping() &&
    !this.hasChildWithCharacteristicGrouping(selectedIds[0]) &&
    (!this.isRootNode(selectedIds[0]) || !nodes.some(node => node.hasCharacteristicGrouping()));

  onPaste = (selectedIds: string[], nodes: GroupingNodeController[]) =>
    this.pasteNodes(this.getPath(selectedIds[0]), nodes);

  getErrors = (): GroupingTreeErrors => ({
    globalErrors: this.validateGlobalErrors(),
    nodeErrors: this.validateNodeErrors(),
  });

  private validateGlobalErrors = (): GroupingTreeErrors['globalErrors'] => [
    ...(this.getNode([]).children.length === 0 ? [GroupingTreeErrorType.TREE_HEIGHT_ZERO] : []),
  ];

  private validateNodeErrors = (): GroupingTreeErrors['nodeErrors'] =>
    combineNodeErrors(
      createNodeErrorsForIds(this.getAllIds()),
      createNodeErrorsForIds(
        this.getDuplicateSiblingNameIds(),
        GroupingTreeErrorType.DUPLICATE_SIBLING_NAME,
      ),
      this.validateNodeStrategyErrors(),
    );

  private validateNodeStrategyErrors = (): GroupingTreeErrors['nodeErrors'] =>
    createObject({
      items: this.getAllNodes(),
      createKey: node => node.id,
      createValue: node => validateGroupingStrategy(node.groupingStrategy),
    });

  /**
   * Returns the IDs of all nodes that share a name with a sibling.
   * This implementation is O(n^2)-ish, could probably be O(n) or
   * O(n ln(n))-ish since you could just crawl the tree one time,
   * but our trees are probably small.
   */
  private getDuplicateSiblingNameIds = () => {
    const allIds = this.getAllIds();
    return allIds.filter(firstId => {
      const firstName = this.getNodeById(firstId).name;
      const firstParentId = this.getParentById(firstId).id;

      return allIds.some(
        secondId =>
          firstId !== secondId &&
          firstName === this.getNodeById(secondId).name &&
          firstParentId === this.getParentById(secondId).id,
      );
    });
  };
}

export default GroupingNodeController;
