import { getNewSort } from '../../model/metadata/sorting';
import { METADATA_SEQUENCE_ID } from '../../shared/constants';
import { AppState } from '../configureStore';

export const getMetadataSort = (state: AppState) =>
  state.report.reportDefinition[METADATA_SEQUENCE_ID]?.sort?.[0] ?? null;

export const getSortCreator = (state: AppState) => (charId: number) =>
  getNewSort(getMetadataSort(state), charId);

export const hasMetadataReport = (state: AppState) =>
  !Object.keys(state.report.reportData[METADATA_SEQUENCE_ID]?.pendingRequests ?? {}).length;

export const getMetadataSandbox = (state: AppState) => state.metadata.sandbox;
export const getMetadataSandboxPath = (state: AppState) => getMetadataSandbox(state)?.path ?? null;
