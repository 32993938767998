import { FC, useEffect, useState } from 'react';
import { Tooltip, Treemap } from 'recharts';
import { paletteC50 as colors } from '../shared/colorPalettes';
import { DataConverters } from '../shared/dataConverters';
import { ReportProps } from '../shared/dataTypes';
import ContentWithElementSelection from './ContentWithElementSelection';

const CustomTooltip = (props: any) => {
  const { active } = props;

  if (active) {
    const { payload } = props;
    return (
      <div>
        {payload[0].payload.name.trim()}: {payload[0].payload.formattedValue}
      </div>
    );
  } else {
    return null;
  }
};

const TreeMapViz: FC<ReportProps> = props => {
  const { reportRawData, width, height, onElementClick, selectedElements } = props;

  const renderElement = props => {
    const { depth, x, y, width, height, index, name, rowcol } = props;

    const selected = selectedElements?.includes(rowcol);

    return (
      <g>
        <rect
          cursor='pointer'
          x={x}
          y={y}
          width={width}
          height={height}
          style={{
            fill: depth < 2 ? colors[index] : 'rgba(255,255,255,0)',
            stroke: selected ? '#000' : '#fff',
            strokeWidth: selected ? 5 / (depth + 1e-10) : 2 / (depth + 1e-10),
            strokeOpacity: 1 / (depth + 1e-10),
          }}
          onClick={onElementClick(rowcol)}
          onContextMenu={onElementClick(rowcol)}
        />
        {depth === 1 ? (
          <text
            x={x + width / 2}
            y={y + height / 2 + 7}
            textAnchor='middle'
            fill='#fff'
            fontSize={14}
          >
            {name}
          </text>
        ) : null}
        {depth === 1 ? (
          <text x={x + 4} y={y + 18} fill='#fff' fontSize={16} fillOpacity={0.9} />
        ) : null}
      </g>
    );
  };

  const [reportConvertedData, setReportCovertedData] = useState(undefined);
  useEffect(() => setReportCovertedData(DataConverters.TREE_MAP(reportRawData)), [reportRawData]);

  if (width <= 0 || height <= 0 || !reportConvertedData) {
    return null;
  }
  const data = reportConvertedData.rows;

  return (
    <Treemap
      width={width}
      height={height}
      data={data}
      dataKey='size'
      // ratio={4/3}
      stroke='#fff'
      fill='#8884d8'
      content={<ContentWithElementSelection colors={colors} renderElement={renderElement} />}
    >
      <Tooltip content={<CustomTooltip />} />
    </Treemap>
  );
};

export default TreeMapViz;
