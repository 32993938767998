import { Reducer } from '@reduxjs/toolkit';
import {
  CANCEL_CLOSE_DESIGNER,
  CONFIRM_CLOSE_DESIGNER,
  INTEND_CLOSE_DESIGNER,
  SET_ACTIVE_PAGE,
  SET_CHANGE_WORKSPACE_MODAL_STATUS,
  SET_FOLIA_SYNTAX_GUIDE_OPEN,
  SET_IS_ABOUT_OPEN,
  SET_IS_REMOTE_CONSOLE_OPEN,
  SET_OVERWRITE_CONFIRMATION_MODAL_STATUS,
  SET_REMOTE_CONSOLE_FOCUS,
} from '../ActionTypes';
import {
  cancelCloseDesigner,
  confirmCloseDesigner,
  intendCloseDesigner,
  setActivePage,
  setChangeWorkspaceModalStatus,
  setFoliaSyntaxGuideOpen,
  setIsAboutOpen,
  setIsRemoteConsoleOpen,
  setOverwriteConfirmationModalStatus,
  setRemoteConsoleFocus,
} from './actionCreators';
import { createDefaultUiState, UiState } from './state';

type Action =
  | ReturnType<typeof setActivePage>
  | ReturnType<typeof setChangeWorkspaceModalStatus>
  | ReturnType<typeof setOverwriteConfirmationModalStatus>
  | ReturnType<typeof intendCloseDesigner>
  | ReturnType<typeof confirmCloseDesigner>
  | ReturnType<typeof cancelCloseDesigner>
  | ReturnType<typeof setIsAboutOpen>
  | ReturnType<typeof setRemoteConsoleFocus>
  | ReturnType<typeof setIsRemoteConsoleOpen>
  | ReturnType<typeof setFoliaSyntaxGuideOpen>;

const reducer: Reducer<UiState, Action> = (state = createDefaultUiState(), action) => {
  switch (action.type) {
    case SET_ACTIVE_PAGE: {
      return {
        ...state,
        activePage: action.payload,
        previousPage: state.activePage,
      };
    }

    /**
     * The different ways this modal can be opened or closed correspond
     * to state changes in a different reducer slice. So, the difference
     * in behavior is handled by the corresponding thunks.
     */
    case SET_CHANGE_WORKSPACE_MODAL_STATUS: {
      return {
        ...state,
        changeWorkspaceModalStatus: action.payload,
      };
    }

    case SET_OVERWRITE_CONFIRMATION_MODAL_STATUS: {
      return {
        ...state,
        overwriteConfirmationModalStatus: action.payload,
      };
    }

    case INTEND_CLOSE_DESIGNER: {
      return {
        ...state,
        intendedPage: action.payload,
        closeDesignerModalOpen: true,
      };
    }

    case CONFIRM_CLOSE_DESIGNER: {
      return {
        ...state,
        activePage: state.intendedPage,
        intendedPage: null,
        closeDesignerModalOpen: false,
      };
    }

    case CANCEL_CLOSE_DESIGNER: {
      return {
        ...state,
        intendedPage: null,
        closeDesignerModalOpen: false,
      };
    }

    case SET_IS_ABOUT_OPEN: {
      return {
        ...state,
        isAboutOpen: action.payload,
      };
    }

    case SET_IS_REMOTE_CONSOLE_OPEN: {
      return {
        ...state,
        isRemoteConsoleOpen: action.payload,
      };
    }

    case SET_REMOTE_CONSOLE_FOCUS: {
      return {
        ...state,
        remoteConsoleFocus: action.payload,
      };
    }

    case SET_FOLIA_SYNTAX_GUIDE_OPEN: {
      return {
        ...state,
        isFoliaSyntaxGuideOpen: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
