// Generated from src/lang/ascript/AScriptParser.g4 by ANTLR 4.9.0-SNAPSHOT


import { ATN } from "antlr4ts/atn/ATN";
import { ATNDeserializer } from "antlr4ts/atn/ATNDeserializer";
import { FailedPredicateException } from "antlr4ts/FailedPredicateException";
import { NotNull } from "antlr4ts/Decorators";
import { NoViableAltException } from "antlr4ts/NoViableAltException";
import { Override } from "antlr4ts/Decorators";
import { Parser } from "antlr4ts/Parser";
import { ParserRuleContext } from "antlr4ts/ParserRuleContext";
import { ParserATNSimulator } from "antlr4ts/atn/ParserATNSimulator";
import { ParseTreeListener } from "antlr4ts/tree/ParseTreeListener";
import { ParseTreeVisitor } from "antlr4ts/tree/ParseTreeVisitor";
import { RecognitionException } from "antlr4ts/RecognitionException";
import { RuleContext } from "antlr4ts/RuleContext";
//import { RuleVersion } from "antlr4ts/RuleVersion";
import { TerminalNode } from "antlr4ts/tree/TerminalNode";
import { Token } from "antlr4ts/Token";
import { TokenStream } from "antlr4ts/TokenStream";
import { Vocabulary } from "antlr4ts/Vocabulary";
import { VocabularyImpl } from "antlr4ts/VocabularyImpl";

import * as Utils from "antlr4ts/misc/Utils";

import { AScriptParserListener } from "./AScriptParserListener";
import { AScriptParserVisitor } from "./AScriptParserVisitor";


export class AScriptParser extends Parser {
	public static readonly COMMENT = 1;
	public static readonly KW_IF = 2;
	public static readonly KW_ELSE = 3;
	public static readonly KW_WHILE = 4;
	public static readonly KW_RETURN = 5;
	public static readonly KW_CONTINUE = 6;
	public static readonly KW_PRINT = 7;
	public static readonly KW_MATH = 8;
	public static readonly KW_IMPORT = 9;
	public static readonly KW_REQUIRES = 10;
	public static readonly KW_KEY = 11;
	public static readonly KW_TOTAL = 12;
	public static readonly KW_VALUE_KEY = 13;
	public static readonly KW_GET = 14;
	public static readonly KW_KEY_MODIFIER = 15;
	public static readonly KW_SUBSTR = 16;
	public static readonly KW_R_ASSIGN = 17;
	public static readonly KW_ARM_ASSIGN = 18;
	public static readonly KW_R_BLOCK_BEGIN = 19;
	public static readonly KW_DOUBLE_VECTOR = 20;
	public static readonly KEY_EXPRESSION = 21;
	public static readonly TOTAL_EXPRESSION = 22;
	public static readonly VALUE_KEY_EXPRESSION = 23;
	public static readonly INSTANCE_KEY_EXPRESSION = 24;
	public static readonly KEY_ACCESSOR = 25;
	public static readonly SUBSTR_EXPRESSION = 26;
	public static readonly KW_NATIVE_FUNCTION = 27;
	public static readonly KW_NATIVE_INSTANCE_METHOD = 28;
	public static readonly MATH_METHOD = 29;
	public static readonly CAST_OPERATOR = 30;
	public static readonly TYPE = 31;
	public static readonly DOUBLE_LITERAL = 32;
	public static readonly INT_LITERAL = 33;
	public static readonly BOOLEAN_LITERAL = 34;
	public static readonly STRING_LITERAL = 35;
	public static readonly IDENTIFIER = 36;
	public static readonly SEMICOLON = 37;
	public static readonly PERIOD = 38;
	public static readonly EQUAL_SIGN = 39;
	public static readonly COMMA = 40;
	public static readonly COLON = 41;
	public static readonly NUMBER_SIGN = 42;
	public static readonly DOUBLE_QUOTE = 43;
	public static readonly NEW_LINE = 44;
	public static readonly L_PAREN = 45;
	public static readonly R_PAREN = 46;
	public static readonly L_BRACKET = 47;
	public static readonly R_BRACKET = 48;
	public static readonly L_BRACE = 49;
	public static readonly R_BRACE = 50;
	public static readonly LOGICAL_OR = 51;
	public static readonly LOGICAL_AND = 52;
	public static readonly BITWISE_OR = 53;
	public static readonly BITWISE_XOR = 54;
	public static readonly BITWISE_AND = 55;
	public static readonly COMP_EQ = 56;
	public static readonly COMP_NEQ = 57;
	public static readonly COMP_LT = 58;
	public static readonly COMP_GT = 59;
	public static readonly COMP_LEQ = 60;
	public static readonly COMP_GEQ = 61;
	public static readonly ARITH_ADD = 62;
	public static readonly ARITH_SUBTRACT = 63;
	public static readonly ARITH_MULTIPLY = 64;
	public static readonly ARITH_DIVIDE = 65;
	public static readonly ARITH_MOD = 66;
	public static readonly BITWISE_COMPLEMENT = 67;
	public static readonly LOGICAL_NOT = 68;
	public static readonly INCREMENT = 69;
	public static readonly DECREMENT = 70;
	public static readonly WS = 71;
	public static readonly UNRECOGNIZED = 72;
	public static readonly KW_AS = 73;
	public static readonly IMPORT_PARAMETERIZATION = 74;
	public static readonly IMPORT_ID = 75;
	public static readonly IMPORT_LANGLE = 76;
	public static readonly IMPORT_RANGLE = 77;
	public static readonly IMPORT_PERIOD = 78;
	public static readonly IMPORT_SEMICOLON = 79;
	public static readonly KW_R_BLOCK_END = 80;
	public static readonly R_NEW_LINE = 81;
	public static readonly R_BLOCK_CHAR = 82;
	public static readonly RULE_compilationUnit = 0;
	public static readonly RULE_singleExpression = 1;
	public static readonly RULE_requiredKeyDeclaration = 2;
	public static readonly RULE_rAssignment = 3;
	public static readonly RULE_armAssignment = 4;
	public static readonly RULE_expression = 5;
	public static readonly RULE_assignment = 6;
	public static readonly RULE_opExpression = 7;
	public static readonly RULE_primaryExpression = 8;
	public static readonly RULE_mathMethodCall = 9;
	public static readonly RULE_nativeFunctionCall = 10;
	public static readonly RULE_nativeInstanceMethodCall = 11;
	public static readonly RULE_customInstanceMethodCall = 12;
	public static readonly RULE_primaryNonKeyExpression = 13;
	public static readonly RULE_varDeclaration = 14;
	public static readonly RULE_literal = 15;
	public static readonly RULE_statement = 16;
	public static readonly RULE_labeledStatement = 17;
	public static readonly RULE_importStatement = 18;
	public static readonly RULE_fullyQualifiedClassName = 19;
	public static readonly RULE_rBlock = 20;
	public static readonly RULE_statementBlockBegin = 21;
	public static readonly RULE_statementBlockEnd = 22;
	public static readonly RULE_ifStatement = 23;
	public static readonly RULE_elseStatement = 24;
	public static readonly RULE_whileStatement = 25;
	public static readonly RULE_returnStatement = 26;
	public static readonly RULE_incrementStatement = 27;
	public static readonly RULE_decrementStatement = 28;
	public static readonly RULE_continueStatement = 29;
	public static readonly RULE_printStatement = 30;
	public static readonly RULE_doubleVector = 31;
	// tslint:disable:no-trailing-whitespace
	public static readonly ruleNames: string[] = [
		"compilationUnit", "singleExpression", "requiredKeyDeclaration", "rAssignment", 
		"armAssignment", "expression", "assignment", "opExpression", "primaryExpression", 
		"mathMethodCall", "nativeFunctionCall", "nativeInstanceMethodCall", "customInstanceMethodCall", 
		"primaryNonKeyExpression", "varDeclaration", "literal", "statement", "labeledStatement", 
		"importStatement", "fullyQualifiedClassName", "rBlock", "statementBlockBegin", 
		"statementBlockEnd", "ifStatement", "elseStatement", "whileStatement", 
		"returnStatement", "incrementStatement", "decrementStatement", "continueStatement", 
		"printStatement", "doubleVector",
	];

	private static readonly _LITERAL_NAMES: Array<string | undefined> = [
		undefined, undefined, "'if'", "'else'", "'while'", "'return'", "'continue'", 
		"'print'", "'Math'", "'import'", "'requires'", "'key'", "'total'", "'ValueKey'", 
		"'get'", undefined, "'substr'", "'RAssign'", "'ArmAssign'", "'RBegin'", 
		"'double_vector'", undefined, undefined, undefined, undefined, undefined, 
		undefined, undefined, undefined, undefined, undefined, undefined, undefined, 
		undefined, undefined, undefined, undefined, undefined, undefined, "'='", 
		"','", "':'", "'#'", "'\"'", undefined, "'('", "')'", "'['", "']'", "'{'", 
		"'}'", "'||'", "'&&'", "'|'", "'^'", "'&'", "'=='", "'!='", undefined, 
		undefined, "'<='", "'>='", "'+'", "'-'", "'*'", "'/'", "'%'", "'~'", "'!'", 
		"'++'", "'--'", undefined, undefined, "'as'", undefined, undefined, undefined, 
		undefined, undefined, undefined, "'REnd'",
	];
	private static readonly _SYMBOLIC_NAMES: Array<string | undefined> = [
		undefined, "COMMENT", "KW_IF", "KW_ELSE", "KW_WHILE", "KW_RETURN", "KW_CONTINUE", 
		"KW_PRINT", "KW_MATH", "KW_IMPORT", "KW_REQUIRES", "KW_KEY", "KW_TOTAL", 
		"KW_VALUE_KEY", "KW_GET", "KW_KEY_MODIFIER", "KW_SUBSTR", "KW_R_ASSIGN", 
		"KW_ARM_ASSIGN", "KW_R_BLOCK_BEGIN", "KW_DOUBLE_VECTOR", "KEY_EXPRESSION", 
		"TOTAL_EXPRESSION", "VALUE_KEY_EXPRESSION", "INSTANCE_KEY_EXPRESSION", 
		"KEY_ACCESSOR", "SUBSTR_EXPRESSION", "KW_NATIVE_FUNCTION", "KW_NATIVE_INSTANCE_METHOD", 
		"MATH_METHOD", "CAST_OPERATOR", "TYPE", "DOUBLE_LITERAL", "INT_LITERAL", 
		"BOOLEAN_LITERAL", "STRING_LITERAL", "IDENTIFIER", "SEMICOLON", "PERIOD", 
		"EQUAL_SIGN", "COMMA", "COLON", "NUMBER_SIGN", "DOUBLE_QUOTE", "NEW_LINE", 
		"L_PAREN", "R_PAREN", "L_BRACKET", "R_BRACKET", "L_BRACE", "R_BRACE", 
		"LOGICAL_OR", "LOGICAL_AND", "BITWISE_OR", "BITWISE_XOR", "BITWISE_AND", 
		"COMP_EQ", "COMP_NEQ", "COMP_LT", "COMP_GT", "COMP_LEQ", "COMP_GEQ", "ARITH_ADD", 
		"ARITH_SUBTRACT", "ARITH_MULTIPLY", "ARITH_DIVIDE", "ARITH_MOD", "BITWISE_COMPLEMENT", 
		"LOGICAL_NOT", "INCREMENT", "DECREMENT", "WS", "UNRECOGNIZED", "KW_AS", 
		"IMPORT_PARAMETERIZATION", "IMPORT_ID", "IMPORT_LANGLE", "IMPORT_RANGLE", 
		"IMPORT_PERIOD", "IMPORT_SEMICOLON", "KW_R_BLOCK_END", "R_NEW_LINE", "R_BLOCK_CHAR",
	];
	public static readonly VOCABULARY: Vocabulary = new VocabularyImpl(AScriptParser._LITERAL_NAMES, AScriptParser._SYMBOLIC_NAMES, []);

	// @Override
	// @NotNull
	public get vocabulary(): Vocabulary {
		return AScriptParser.VOCABULARY;
	}
	// tslint:enable:no-trailing-whitespace

	// @Override
	public get grammarFileName(): string { return "AScriptParser.g4"; }

	// @Override
	public get ruleNames(): string[] { return AScriptParser.ruleNames; }

	// @Override
	public get serializedATN(): string { return AScriptParser._serializedATN; }

	protected createFailedPredicateException(predicate?: string, message?: string): FailedPredicateException {
		return new FailedPredicateException(this, predicate, message);
	}

	constructor(input: TokenStream) {
		super(input);
		this._interp = new ParserATNSimulator(AScriptParser._ATN, this);
	}
	// @RuleVersion(0)
	public compilationUnit(): CompilationUnitContext {
		let _localctx: CompilationUnitContext = new CompilationUnitContext(this._ctx, this.state);
		this.enterRule(_localctx, 0, AScriptParser.RULE_compilationUnit);
		let _la: number;
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 84;
			this._errHandler.sync(this);
			switch ( this.interpreter.adaptivePredict(this._input, 4, this._ctx) ) {
			case 1:
				{
				this.state = 64;
				this.singleExpression();
				this.state = 66;
				this._errHandler.sync(this);
				_la = this._input.LA(1);
				if (_la === AScriptParser.NEW_LINE) {
					{
					this.state = 65;
					this.match(AScriptParser.NEW_LINE);
					}
				}

				}
				break;

			case 2:
				{
				this.state = 81;
				this._errHandler.sync(this);
				_la = this._input.LA(1);
				while ((((_la) & ~0x1F) === 0 && ((1 << _la) & ((1 << AScriptParser.COMMENT) | (1 << AScriptParser.KW_IF) | (1 << AScriptParser.KW_ELSE) | (1 << AScriptParser.KW_WHILE) | (1 << AScriptParser.KW_RETURN) | (1 << AScriptParser.KW_CONTINUE) | (1 << AScriptParser.KW_PRINT) | (1 << AScriptParser.KW_IMPORT) | (1 << AScriptParser.KW_REQUIRES) | (1 << AScriptParser.KW_R_ASSIGN) | (1 << AScriptParser.KW_ARM_ASSIGN) | (1 << AScriptParser.KW_R_BLOCK_BEGIN) | (1 << AScriptParser.KW_DOUBLE_VECTOR) | (1 << AScriptParser.CAST_OPERATOR) | (1 << AScriptParser.TYPE))) !== 0) || ((((_la - 32)) & ~0x1F) === 0 && ((1 << (_la - 32)) & ((1 << (AScriptParser.DOUBLE_LITERAL - 32)) | (1 << (AScriptParser.INT_LITERAL - 32)) | (1 << (AScriptParser.BOOLEAN_LITERAL - 32)) | (1 << (AScriptParser.STRING_LITERAL - 32)) | (1 << (AScriptParser.IDENTIFIER - 32)) | (1 << (AScriptParser.SEMICOLON - 32)) | (1 << (AScriptParser.NEW_LINE - 32)) | (1 << (AScriptParser.L_PAREN - 32)) | (1 << (AScriptParser.L_BRACE - 32)) | (1 << (AScriptParser.R_BRACE - 32)))) !== 0) || _la === AScriptParser.INCREMENT || _la === AScriptParser.DECREMENT) {
					{
					this.state = 79;
					this._errHandler.sync(this);
					switch ( this.interpreter.adaptivePredict(this._input, 2, this._ctx) ) {
					case 1:
						{
						{
						this.state = 72;
						this._errHandler.sync(this);
						switch (this._input.LA(1)) {
						case AScriptParser.KW_REQUIRES:
							{
							this.state = 68;
							this.requiredKeyDeclaration();
							}
							break;
						case AScriptParser.TYPE:
							{
							this.state = 69;
							this.varDeclaration();
							}
							break;
						case AScriptParser.KW_R_ASSIGN:
							{
							this.state = 70;
							this.rAssignment();
							}
							break;
						case AScriptParser.KW_ARM_ASSIGN:
							{
							this.state = 71;
							this.armAssignment();
							}
							break;
						default:
							throw new NoViableAltException(this);
						}
						this.state = 74;
						this.match(AScriptParser.SEMICOLON);
						}
						}
						break;

					case 2:
						{
						this.state = 76;
						this.importStatement();
						}
						break;

					case 3:
						{
						this.state = 77;
						this.statement();
						}
						break;

					case 4:
						{
						this.state = 78;
						this.match(AScriptParser.NEW_LINE);
						}
						break;
					}
					}
					this.state = 83;
					this._errHandler.sync(this);
					_la = this._input.LA(1);
				}
				}
				break;
			}
			this.state = 86;
			this.match(AScriptParser.EOF);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public singleExpression(): SingleExpressionContext {
		let _localctx: SingleExpressionContext = new SingleExpressionContext(this._ctx, this.state);
		this.enterRule(_localctx, 2, AScriptParser.RULE_singleExpression);
		let _la: number;
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 88;
			this.expression();
			this.state = 90;
			this._errHandler.sync(this);
			_la = this._input.LA(1);
			if (_la === AScriptParser.SEMICOLON) {
				{
				this.state = 89;
				this.match(AScriptParser.SEMICOLON);
				}
			}

			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public requiredKeyDeclaration(): RequiredKeyDeclarationContext {
		let _localctx: RequiredKeyDeclarationContext = new RequiredKeyDeclarationContext(this._ctx, this.state);
		this.enterRule(_localctx, 4, AScriptParser.RULE_requiredKeyDeclaration);
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 92;
			this.match(AScriptParser.KW_REQUIRES);
			this.state = 93;
			this.match(AScriptParser.VALUE_KEY_EXPRESSION);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public rAssignment(): RAssignmentContext {
		let _localctx: RAssignmentContext = new RAssignmentContext(this._ctx, this.state);
		this.enterRule(_localctx, 6, AScriptParser.RULE_rAssignment);
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 95;
			this.match(AScriptParser.KW_R_ASSIGN);
			this.state = 96;
			this.match(AScriptParser.L_PAREN);
			this.state = 97;
			this.match(AScriptParser.STRING_LITERAL);
			this.state = 98;
			this.match(AScriptParser.COMMA);
			this.state = 99;
			this.match(AScriptParser.KEY_EXPRESSION);
			this.state = 100;
			this.match(AScriptParser.R_PAREN);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public armAssignment(): ArmAssignmentContext {
		let _localctx: ArmAssignmentContext = new ArmAssignmentContext(this._ctx, this.state);
		this.enterRule(_localctx, 8, AScriptParser.RULE_armAssignment);
		let _la: number;
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 102;
			this.match(AScriptParser.KW_ARM_ASSIGN);
			this.state = 103;
			this.match(AScriptParser.L_PAREN);
			this.state = 104;
			this.match(AScriptParser.STRING_LITERAL);
			this.state = 107;
			this._errHandler.sync(this);
			_la = this._input.LA(1);
			if (_la === AScriptParser.COMMA) {
				{
				this.state = 105;
				this.match(AScriptParser.COMMA);
				this.state = 106;
				this.match(AScriptParser.KEY_EXPRESSION);
				}
			}

			this.state = 109;
			this.match(AScriptParser.R_PAREN);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public expression(): ExpressionContext {
		let _localctx: ExpressionContext = new ExpressionContext(this._ctx, this.state);
		this.enterRule(_localctx, 10, AScriptParser.RULE_expression);
		try {
			this.state = 113;
			this._errHandler.sync(this);
			switch ( this.interpreter.adaptivePredict(this._input, 7, this._ctx) ) {
			case 1:
				this.enterOuterAlt(_localctx, 1);
				{
				this.state = 111;
				this.assignment();
				}
				break;

			case 2:
				this.enterOuterAlt(_localctx, 2);
				{
				this.state = 112;
				this.opExpression(0);
				}
				break;
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public assignment(): AssignmentContext {
		let _localctx: AssignmentContext = new AssignmentContext(this._ctx, this.state);
		this.enterRule(_localctx, 12, AScriptParser.RULE_assignment);
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 117;
			this._errHandler.sync(this);
			switch (this._input.LA(1)) {
			case AScriptParser.CAST_OPERATOR:
			case AScriptParser.DOUBLE_LITERAL:
			case AScriptParser.INT_LITERAL:
			case AScriptParser.BOOLEAN_LITERAL:
			case AScriptParser.STRING_LITERAL:
			case AScriptParser.IDENTIFIER:
			case AScriptParser.L_PAREN:
				{
				this.state = 115;
				this.primaryNonKeyExpression();
				}
				break;
			case AScriptParser.TYPE:
				{
				this.state = 116;
				this.varDeclaration();
				}
				break;
			default:
				throw new NoViableAltException(this);
			}
			this.state = 119;
			this.match(AScriptParser.EQUAL_SIGN);
			this.state = 120;
			this.expression();
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}

	public opExpression(): OpExpressionContext;
	public opExpression(_p: number): OpExpressionContext;
	// @RuleVersion(0)
	public opExpression(_p?: number): OpExpressionContext {
		if (_p === undefined) {
			_p = 0;
		}

		let _parentctx: ParserRuleContext = this._ctx;
		let _parentState: number = this.state;
		let _localctx: OpExpressionContext = new OpExpressionContext(this._ctx, _parentState);
		let _prevctx: OpExpressionContext = _localctx;
		let _startState: number = 14;
		this.enterRecursionRule(_localctx, 14, AScriptParser.RULE_opExpression, _p);
		let _la: number;
		try {
			let _alt: number;
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 126;
			this._errHandler.sync(this);
			switch (this._input.LA(1)) {
			case AScriptParser.KEY_EXPRESSION:
			case AScriptParser.TOTAL_EXPRESSION:
			case AScriptParser.VALUE_KEY_EXPRESSION:
			case AScriptParser.INSTANCE_KEY_EXPRESSION:
			case AScriptParser.SUBSTR_EXPRESSION:
			case AScriptParser.KW_NATIVE_FUNCTION:
			case AScriptParser.MATH_METHOD:
			case AScriptParser.CAST_OPERATOR:
			case AScriptParser.DOUBLE_LITERAL:
			case AScriptParser.INT_LITERAL:
			case AScriptParser.BOOLEAN_LITERAL:
			case AScriptParser.STRING_LITERAL:
			case AScriptParser.IDENTIFIER:
			case AScriptParser.L_PAREN:
				{
				this.state = 123;
				this.primaryExpression();
				}
				break;
			case AScriptParser.ARITH_SUBTRACT:
			case AScriptParser.BITWISE_COMPLEMENT:
			case AScriptParser.LOGICAL_NOT:
				{
				this.state = 124;
				_la = this._input.LA(1);
				if (!(((((_la - 63)) & ~0x1F) === 0 && ((1 << (_la - 63)) & ((1 << (AScriptParser.ARITH_SUBTRACT - 63)) | (1 << (AScriptParser.BITWISE_COMPLEMENT - 63)) | (1 << (AScriptParser.LOGICAL_NOT - 63)))) !== 0))) {
				this._errHandler.recoverInline(this);
				} else {
					if (this._input.LA(1) === Token.EOF) {
						this.matchedEOF = true;
					}

					this._errHandler.reportMatch(this);
					this.consume();
				}
				this.state = 125;
				this.opExpression(10);
				}
				break;
			default:
				throw new NoViableAltException(this);
			}
			this._ctx._stop = this._input.tryLT(-1);
			this.state = 157;
			this._errHandler.sync(this);
			_alt = this.interpreter.adaptivePredict(this._input, 11, this._ctx);
			while (_alt !== 2 && _alt !== ATN.INVALID_ALT_NUMBER) {
				if (_alt === 1) {
					if (this._parseListeners != null) {
						this.triggerExitRuleEvent();
					}
					_prevctx = _localctx;
					{
					this.state = 155;
					this._errHandler.sync(this);
					switch ( this.interpreter.adaptivePredict(this._input, 10, this._ctx) ) {
					case 1:
						{
						_localctx = new OpExpressionContext(_parentctx, _parentState);
						this.pushNewRecursionContext(_localctx, _startState, AScriptParser.RULE_opExpression);
						this.state = 128;
						if (!(this.precpred(this._ctx, 9))) {
							throw this.createFailedPredicateException("this.precpred(this._ctx, 9)");
						}
						this.state = 129;
						_la = this._input.LA(1);
						if (!(((((_la - 64)) & ~0x1F) === 0 && ((1 << (_la - 64)) & ((1 << (AScriptParser.ARITH_MULTIPLY - 64)) | (1 << (AScriptParser.ARITH_DIVIDE - 64)) | (1 << (AScriptParser.ARITH_MOD - 64)))) !== 0))) {
						this._errHandler.recoverInline(this);
						} else {
							if (this._input.LA(1) === Token.EOF) {
								this.matchedEOF = true;
							}

							this._errHandler.reportMatch(this);
							this.consume();
						}
						this.state = 130;
						this.opExpression(10);
						}
						break;

					case 2:
						{
						_localctx = new OpExpressionContext(_parentctx, _parentState);
						this.pushNewRecursionContext(_localctx, _startState, AScriptParser.RULE_opExpression);
						this.state = 131;
						if (!(this.precpred(this._ctx, 8))) {
							throw this.createFailedPredicateException("this.precpred(this._ctx, 8)");
						}
						this.state = 132;
						_la = this._input.LA(1);
						if (!(_la === AScriptParser.ARITH_ADD || _la === AScriptParser.ARITH_SUBTRACT)) {
						this._errHandler.recoverInline(this);
						} else {
							if (this._input.LA(1) === Token.EOF) {
								this.matchedEOF = true;
							}

							this._errHandler.reportMatch(this);
							this.consume();
						}
						this.state = 133;
						this.opExpression(9);
						}
						break;

					case 3:
						{
						_localctx = new OpExpressionContext(_parentctx, _parentState);
						this.pushNewRecursionContext(_localctx, _startState, AScriptParser.RULE_opExpression);
						this.state = 134;
						if (!(this.precpred(this._ctx, 7))) {
							throw this.createFailedPredicateException("this.precpred(this._ctx, 7)");
						}
						this.state = 135;
						_la = this._input.LA(1);
						if (!(((((_la - 58)) & ~0x1F) === 0 && ((1 << (_la - 58)) & ((1 << (AScriptParser.COMP_LT - 58)) | (1 << (AScriptParser.COMP_GT - 58)) | (1 << (AScriptParser.COMP_LEQ - 58)) | (1 << (AScriptParser.COMP_GEQ - 58)))) !== 0))) {
						this._errHandler.recoverInline(this);
						} else {
							if (this._input.LA(1) === Token.EOF) {
								this.matchedEOF = true;
							}

							this._errHandler.reportMatch(this);
							this.consume();
						}
						this.state = 136;
						this.opExpression(8);
						}
						break;

					case 4:
						{
						_localctx = new OpExpressionContext(_parentctx, _parentState);
						this.pushNewRecursionContext(_localctx, _startState, AScriptParser.RULE_opExpression);
						this.state = 137;
						if (!(this.precpred(this._ctx, 6))) {
							throw this.createFailedPredicateException("this.precpred(this._ctx, 6)");
						}
						this.state = 138;
						_la = this._input.LA(1);
						if (!(_la === AScriptParser.COMP_EQ || _la === AScriptParser.COMP_NEQ)) {
						this._errHandler.recoverInline(this);
						} else {
							if (this._input.LA(1) === Token.EOF) {
								this.matchedEOF = true;
							}

							this._errHandler.reportMatch(this);
							this.consume();
						}
						this.state = 139;
						this.opExpression(7);
						}
						break;

					case 5:
						{
						_localctx = new OpExpressionContext(_parentctx, _parentState);
						this.pushNewRecursionContext(_localctx, _startState, AScriptParser.RULE_opExpression);
						this.state = 140;
						if (!(this.precpred(this._ctx, 5))) {
							throw this.createFailedPredicateException("this.precpred(this._ctx, 5)");
						}
						this.state = 141;
						this.match(AScriptParser.BITWISE_AND);
						this.state = 142;
						this.opExpression(6);
						}
						break;

					case 6:
						{
						_localctx = new OpExpressionContext(_parentctx, _parentState);
						this.pushNewRecursionContext(_localctx, _startState, AScriptParser.RULE_opExpression);
						this.state = 143;
						if (!(this.precpred(this._ctx, 4))) {
							throw this.createFailedPredicateException("this.precpred(this._ctx, 4)");
						}
						this.state = 144;
						this.match(AScriptParser.BITWISE_XOR);
						this.state = 145;
						this.opExpression(5);
						}
						break;

					case 7:
						{
						_localctx = new OpExpressionContext(_parentctx, _parentState);
						this.pushNewRecursionContext(_localctx, _startState, AScriptParser.RULE_opExpression);
						this.state = 146;
						if (!(this.precpred(this._ctx, 3))) {
							throw this.createFailedPredicateException("this.precpred(this._ctx, 3)");
						}
						this.state = 147;
						this.match(AScriptParser.BITWISE_OR);
						this.state = 148;
						this.opExpression(4);
						}
						break;

					case 8:
						{
						_localctx = new OpExpressionContext(_parentctx, _parentState);
						this.pushNewRecursionContext(_localctx, _startState, AScriptParser.RULE_opExpression);
						this.state = 149;
						if (!(this.precpred(this._ctx, 2))) {
							throw this.createFailedPredicateException("this.precpred(this._ctx, 2)");
						}
						this.state = 150;
						this.match(AScriptParser.LOGICAL_AND);
						this.state = 151;
						this.opExpression(3);
						}
						break;

					case 9:
						{
						_localctx = new OpExpressionContext(_parentctx, _parentState);
						this.pushNewRecursionContext(_localctx, _startState, AScriptParser.RULE_opExpression);
						this.state = 152;
						if (!(this.precpred(this._ctx, 1))) {
							throw this.createFailedPredicateException("this.precpred(this._ctx, 1)");
						}
						this.state = 153;
						this.match(AScriptParser.LOGICAL_OR);
						this.state = 154;
						this.opExpression(2);
						}
						break;
					}
					}
				}
				this.state = 159;
				this._errHandler.sync(this);
				_alt = this.interpreter.adaptivePredict(this._input, 11, this._ctx);
			}
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.unrollRecursionContexts(_parentctx);
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public primaryExpression(): PrimaryExpressionContext {
		let _localctx: PrimaryExpressionContext = new PrimaryExpressionContext(this._ctx, this.state);
		this.enterRule(_localctx, 16, AScriptParser.RULE_primaryExpression);
		let _la: number;
		try {
			this.state = 176;
			this._errHandler.sync(this);
			switch ( this.interpreter.adaptivePredict(this._input, 14, this._ctx) ) {
			case 1:
				this.enterOuterAlt(_localctx, 1);
				{
				this.state = 160;
				this.primaryNonKeyExpression();
				}
				break;

			case 2:
				this.enterOuterAlt(_localctx, 2);
				{
				this.state = 162;
				this._errHandler.sync(this);
				_la = this._input.LA(1);
				if (_la === AScriptParser.CAST_OPERATOR) {
					{
					this.state = 161;
					this.match(AScriptParser.CAST_OPERATOR);
					}
				}

				this.state = 164;
				this.match(AScriptParser.KEY_EXPRESSION);
				}
				break;

			case 3:
				this.enterOuterAlt(_localctx, 3);
				{
				this.state = 165;
				this.match(AScriptParser.VALUE_KEY_EXPRESSION);
				}
				break;

			case 4:
				this.enterOuterAlt(_localctx, 4);
				{
				this.state = 166;
				this.match(AScriptParser.TOTAL_EXPRESSION);
				}
				break;

			case 5:
				this.enterOuterAlt(_localctx, 5);
				{
				this.state = 167;
				this.match(AScriptParser.INSTANCE_KEY_EXPRESSION);
				}
				break;

			case 6:
				this.enterOuterAlt(_localctx, 6);
				{
				this.state = 168;
				this.match(AScriptParser.SUBSTR_EXPRESSION);
				}
				break;

			case 7:
				this.enterOuterAlt(_localctx, 7);
				{
				this.state = 169;
				this.mathMethodCall();
				}
				break;

			case 8:
				this.enterOuterAlt(_localctx, 8);
				{
				this.state = 170;
				this.nativeFunctionCall();
				}
				break;

			case 9:
				this.enterOuterAlt(_localctx, 9);
				{
				this.state = 171;
				this.nativeInstanceMethodCall();
				}
				break;

			case 10:
				this.enterOuterAlt(_localctx, 10);
				{
				this.state = 173;
				this._errHandler.sync(this);
				_la = this._input.LA(1);
				if (_la === AScriptParser.CAST_OPERATOR) {
					{
					this.state = 172;
					this.match(AScriptParser.CAST_OPERATOR);
					}
				}

				this.state = 175;
				this.customInstanceMethodCall();
				}
				break;
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public mathMethodCall(): MathMethodCallContext {
		let _localctx: MathMethodCallContext = new MathMethodCallContext(this._ctx, this.state);
		this.enterRule(_localctx, 18, AScriptParser.RULE_mathMethodCall);
		let _la: number;
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 178;
			this.match(AScriptParser.MATH_METHOD);
			this.state = 179;
			this.match(AScriptParser.L_PAREN);
			this.state = 185;
			this._errHandler.sync(this);
			_la = this._input.LA(1);
			if (((((_la - 21)) & ~0x1F) === 0 && ((1 << (_la - 21)) & ((1 << (AScriptParser.KEY_EXPRESSION - 21)) | (1 << (AScriptParser.TOTAL_EXPRESSION - 21)) | (1 << (AScriptParser.VALUE_KEY_EXPRESSION - 21)) | (1 << (AScriptParser.INSTANCE_KEY_EXPRESSION - 21)) | (1 << (AScriptParser.SUBSTR_EXPRESSION - 21)) | (1 << (AScriptParser.KW_NATIVE_FUNCTION - 21)) | (1 << (AScriptParser.MATH_METHOD - 21)) | (1 << (AScriptParser.CAST_OPERATOR - 21)) | (1 << (AScriptParser.TYPE - 21)) | (1 << (AScriptParser.DOUBLE_LITERAL - 21)) | (1 << (AScriptParser.INT_LITERAL - 21)) | (1 << (AScriptParser.BOOLEAN_LITERAL - 21)) | (1 << (AScriptParser.STRING_LITERAL - 21)) | (1 << (AScriptParser.IDENTIFIER - 21)) | (1 << (AScriptParser.L_PAREN - 21)))) !== 0) || ((((_la - 63)) & ~0x1F) === 0 && ((1 << (_la - 63)) & ((1 << (AScriptParser.ARITH_SUBTRACT - 63)) | (1 << (AScriptParser.BITWISE_COMPLEMENT - 63)) | (1 << (AScriptParser.LOGICAL_NOT - 63)))) !== 0)) {
				{
				this.state = 180;
				this.expression();
				this.state = 183;
				this._errHandler.sync(this);
				_la = this._input.LA(1);
				if (_la === AScriptParser.COMMA) {
					{
					this.state = 181;
					this.match(AScriptParser.COMMA);
					this.state = 182;
					this.expression();
					}
				}

				}
			}

			this.state = 187;
			this.match(AScriptParser.R_PAREN);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public nativeFunctionCall(): NativeFunctionCallContext {
		let _localctx: NativeFunctionCallContext = new NativeFunctionCallContext(this._ctx, this.state);
		this.enterRule(_localctx, 20, AScriptParser.RULE_nativeFunctionCall);
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 189;
			this.match(AScriptParser.KW_NATIVE_FUNCTION);
			this.state = 190;
			this.match(AScriptParser.L_PAREN);
			this.state = 191;
			this.match(AScriptParser.R_PAREN);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public nativeInstanceMethodCall(): NativeInstanceMethodCallContext {
		let _localctx: NativeInstanceMethodCallContext = new NativeInstanceMethodCallContext(this._ctx, this.state);
		this.enterRule(_localctx, 22, AScriptParser.RULE_nativeInstanceMethodCall);
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 193;
			this.match(AScriptParser.IDENTIFIER);
			this.state = 194;
			this.match(AScriptParser.PERIOD);
			this.state = 195;
			this.match(AScriptParser.KW_NATIVE_INSTANCE_METHOD);
			this.state = 196;
			this.match(AScriptParser.L_PAREN);
			this.state = 197;
			this.match(AScriptParser.R_PAREN);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public customInstanceMethodCall(): CustomInstanceMethodCallContext {
		let _localctx: CustomInstanceMethodCallContext = new CustomInstanceMethodCallContext(this._ctx, this.state);
		this.enterRule(_localctx, 24, AScriptParser.RULE_customInstanceMethodCall);
		let _la: number;
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 199;
			this.match(AScriptParser.IDENTIFIER);
			this.state = 200;
			this.match(AScriptParser.PERIOD);
			this.state = 201;
			this.match(AScriptParser.IDENTIFIER);
			this.state = 202;
			this.match(AScriptParser.L_PAREN);
			this.state = 211;
			this._errHandler.sync(this);
			_la = this._input.LA(1);
			if (((((_la - 21)) & ~0x1F) === 0 && ((1 << (_la - 21)) & ((1 << (AScriptParser.KEY_EXPRESSION - 21)) | (1 << (AScriptParser.TOTAL_EXPRESSION - 21)) | (1 << (AScriptParser.VALUE_KEY_EXPRESSION - 21)) | (1 << (AScriptParser.INSTANCE_KEY_EXPRESSION - 21)) | (1 << (AScriptParser.SUBSTR_EXPRESSION - 21)) | (1 << (AScriptParser.KW_NATIVE_FUNCTION - 21)) | (1 << (AScriptParser.MATH_METHOD - 21)) | (1 << (AScriptParser.CAST_OPERATOR - 21)) | (1 << (AScriptParser.TYPE - 21)) | (1 << (AScriptParser.DOUBLE_LITERAL - 21)) | (1 << (AScriptParser.INT_LITERAL - 21)) | (1 << (AScriptParser.BOOLEAN_LITERAL - 21)) | (1 << (AScriptParser.STRING_LITERAL - 21)) | (1 << (AScriptParser.IDENTIFIER - 21)) | (1 << (AScriptParser.L_PAREN - 21)))) !== 0) || ((((_la - 63)) & ~0x1F) === 0 && ((1 << (_la - 63)) & ((1 << (AScriptParser.ARITH_SUBTRACT - 63)) | (1 << (AScriptParser.BITWISE_COMPLEMENT - 63)) | (1 << (AScriptParser.LOGICAL_NOT - 63)))) !== 0)) {
				{
				this.state = 203;
				this.expression();
				this.state = 208;
				this._errHandler.sync(this);
				_la = this._input.LA(1);
				while (_la === AScriptParser.COMMA) {
					{
					{
					this.state = 204;
					this.match(AScriptParser.COMMA);
					this.state = 205;
					this.expression();
					}
					}
					this.state = 210;
					this._errHandler.sync(this);
					_la = this._input.LA(1);
				}
				}
			}

			this.state = 213;
			this.match(AScriptParser.R_PAREN);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public primaryNonKeyExpression(): PrimaryNonKeyExpressionContext {
		let _localctx: PrimaryNonKeyExpressionContext = new PrimaryNonKeyExpressionContext(this._ctx, this.state);
		this.enterRule(_localctx, 26, AScriptParser.RULE_primaryNonKeyExpression);
		let _la: number;
		try {
			this.state = 226;
			this._errHandler.sync(this);
			switch ( this.interpreter.adaptivePredict(this._input, 20, this._ctx) ) {
			case 1:
				this.enterOuterAlt(_localctx, 1);
				{
				this.state = 215;
				this.match(AScriptParser.CAST_OPERATOR);
				this.state = 216;
				_la = this._input.LA(1);
				if (!(_la === AScriptParser.DOUBLE_LITERAL || _la === AScriptParser.INT_LITERAL)) {
				this._errHandler.recoverInline(this);
				} else {
					if (this._input.LA(1) === Token.EOF) {
						this.matchedEOF = true;
					}

					this._errHandler.reportMatch(this);
					this.consume();
				}
				}
				break;

			case 2:
				this.enterOuterAlt(_localctx, 2);
				{
				this.state = 217;
				this.literal();
				}
				break;

			case 3:
				this.enterOuterAlt(_localctx, 3);
				{
				this.state = 219;
				this._errHandler.sync(this);
				_la = this._input.LA(1);
				if (_la === AScriptParser.CAST_OPERATOR) {
					{
					this.state = 218;
					this.match(AScriptParser.CAST_OPERATOR);
					}
				}

				this.state = 221;
				this.match(AScriptParser.IDENTIFIER);
				}
				break;

			case 4:
				this.enterOuterAlt(_localctx, 4);
				{
				this.state = 222;
				this.match(AScriptParser.L_PAREN);
				this.state = 223;
				this.expression();
				this.state = 224;
				this.match(AScriptParser.R_PAREN);
				}
				break;
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public varDeclaration(): VarDeclarationContext {
		let _localctx: VarDeclarationContext = new VarDeclarationContext(this._ctx, this.state);
		this.enterRule(_localctx, 28, AScriptParser.RULE_varDeclaration);
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 228;
			this.match(AScriptParser.TYPE);
			this.state = 229;
			this.match(AScriptParser.IDENTIFIER);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public literal(): LiteralContext {
		let _localctx: LiteralContext = new LiteralContext(this._ctx, this.state);
		this.enterRule(_localctx, 30, AScriptParser.RULE_literal);
		let _la: number;
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 231;
			_la = this._input.LA(1);
			if (!(((((_la - 32)) & ~0x1F) === 0 && ((1 << (_la - 32)) & ((1 << (AScriptParser.DOUBLE_LITERAL - 32)) | (1 << (AScriptParser.INT_LITERAL - 32)) | (1 << (AScriptParser.BOOLEAN_LITERAL - 32)) | (1 << (AScriptParser.STRING_LITERAL - 32)))) !== 0))) {
			this._errHandler.recoverInline(this);
			} else {
				if (this._input.LA(1) === Token.EOF) {
					this.matchedEOF = true;
				}

				this._errHandler.reportMatch(this);
				this.consume();
			}
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public statement(): StatementContext {
		let _localctx: StatementContext = new StatementContext(this._ctx, this.state);
		this.enterRule(_localctx, 32, AScriptParser.RULE_statement);
		try {
			this.state = 252;
			this._errHandler.sync(this);
			switch ( this.interpreter.adaptivePredict(this._input, 21, this._ctx) ) {
			case 1:
				this.enterOuterAlt(_localctx, 1);
				{
				this.state = 233;
				this.match(AScriptParser.SEMICOLON);
				}
				break;

			case 2:
				this.enterOuterAlt(_localctx, 2);
				{
				this.state = 234;
				this.labeledStatement();
				}
				break;

			case 3:
				this.enterOuterAlt(_localctx, 3);
				{
				this.state = 235;
				this.match(AScriptParser.COMMENT);
				}
				break;

			case 4:
				this.enterOuterAlt(_localctx, 4);
				{
				this.state = 236;
				this.statementBlockBegin();
				}
				break;

			case 5:
				this.enterOuterAlt(_localctx, 5);
				{
				this.state = 237;
				this.statementBlockEnd();
				}
				break;

			case 6:
				this.enterOuterAlt(_localctx, 6);
				{
				this.state = 238;
				this.rBlock();
				}
				break;

			case 7:
				this.enterOuterAlt(_localctx, 7);
				{
				this.state = 239;
				this.assignment();
				this.state = 240;
				this.match(AScriptParser.SEMICOLON);
				}
				break;

			case 8:
				this.enterOuterAlt(_localctx, 8);
				{
				this.state = 242;
				this.ifStatement();
				}
				break;

			case 9:
				this.enterOuterAlt(_localctx, 9);
				{
				this.state = 243;
				this.elseStatement();
				}
				break;

			case 10:
				this.enterOuterAlt(_localctx, 10);
				{
				this.state = 244;
				this.whileStatement();
				}
				break;

			case 11:
				this.enterOuterAlt(_localctx, 11);
				{
				this.state = 245;
				this.continueStatement();
				}
				break;

			case 12:
				this.enterOuterAlt(_localctx, 12);
				{
				this.state = 246;
				this.printStatement();
				}
				break;

			case 13:
				this.enterOuterAlt(_localctx, 13);
				{
				this.state = 247;
				this.incrementStatement();
				}
				break;

			case 14:
				this.enterOuterAlt(_localctx, 14);
				{
				this.state = 248;
				this.decrementStatement();
				}
				break;

			case 15:
				this.enterOuterAlt(_localctx, 15);
				{
				this.state = 249;
				this.returnStatement();
				}
				break;

			case 16:
				this.enterOuterAlt(_localctx, 16);
				{
				this.state = 250;
				this.customInstanceMethodCall();
				}
				break;

			case 17:
				this.enterOuterAlt(_localctx, 17);
				{
				this.state = 251;
				this.doubleVector();
				}
				break;
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public labeledStatement(): LabeledStatementContext {
		let _localctx: LabeledStatementContext = new LabeledStatementContext(this._ctx, this.state);
		this.enterRule(_localctx, 34, AScriptParser.RULE_labeledStatement);
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 254;
			this.match(AScriptParser.IDENTIFIER);
			this.state = 255;
			this.match(AScriptParser.COLON);
			this.state = 256;
			this.statement();
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public importStatement(): ImportStatementContext {
		let _localctx: ImportStatementContext = new ImportStatementContext(this._ctx, this.state);
		this.enterRule(_localctx, 36, AScriptParser.RULE_importStatement);
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 258;
			this.match(AScriptParser.KW_IMPORT);
			this.state = 259;
			this.fullyQualifiedClassName();
			this.state = 260;
			this.match(AScriptParser.KW_AS);
			this.state = 261;
			this.match(AScriptParser.IMPORT_ID);
			this.state = 262;
			this.match(AScriptParser.IMPORT_SEMICOLON);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public fullyQualifiedClassName(): FullyQualifiedClassNameContext {
		let _localctx: FullyQualifiedClassNameContext = new FullyQualifiedClassNameContext(this._ctx, this.state);
		this.enterRule(_localctx, 38, AScriptParser.RULE_fullyQualifiedClassName);
		let _la: number;
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 264;
			this.match(AScriptParser.IMPORT_ID);
			this.state = 269;
			this._errHandler.sync(this);
			_la = this._input.LA(1);
			while (_la === AScriptParser.IMPORT_PERIOD) {
				{
				{
				this.state = 265;
				this.match(AScriptParser.IMPORT_PERIOD);
				this.state = 266;
				this.match(AScriptParser.IMPORT_ID);
				}
				}
				this.state = 271;
				this._errHandler.sync(this);
				_la = this._input.LA(1);
			}
			this.state = 273;
			this._errHandler.sync(this);
			_la = this._input.LA(1);
			if (_la === AScriptParser.IMPORT_PARAMETERIZATION) {
				{
				this.state = 272;
				this.match(AScriptParser.IMPORT_PARAMETERIZATION);
				}
			}

			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public rBlock(): RBlockContext {
		let _localctx: RBlockContext = new RBlockContext(this._ctx, this.state);
		this.enterRule(_localctx, 40, AScriptParser.RULE_rBlock);
		let _la: number;
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 275;
			this.match(AScriptParser.KW_R_BLOCK_BEGIN);
			this.state = 279;
			this._errHandler.sync(this);
			_la = this._input.LA(1);
			while (_la === AScriptParser.R_NEW_LINE || _la === AScriptParser.R_BLOCK_CHAR) {
				{
				{
				this.state = 276;
				_la = this._input.LA(1);
				if (!(_la === AScriptParser.R_NEW_LINE || _la === AScriptParser.R_BLOCK_CHAR)) {
				this._errHandler.recoverInline(this);
				} else {
					if (this._input.LA(1) === Token.EOF) {
						this.matchedEOF = true;
					}

					this._errHandler.reportMatch(this);
					this.consume();
				}
				}
				}
				this.state = 281;
				this._errHandler.sync(this);
				_la = this._input.LA(1);
			}
			this.state = 282;
			this.match(AScriptParser.KW_R_BLOCK_END);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public statementBlockBegin(): StatementBlockBeginContext {
		let _localctx: StatementBlockBeginContext = new StatementBlockBeginContext(this._ctx, this.state);
		this.enterRule(_localctx, 42, AScriptParser.RULE_statementBlockBegin);
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 284;
			this.match(AScriptParser.L_BRACE);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public statementBlockEnd(): StatementBlockEndContext {
		let _localctx: StatementBlockEndContext = new StatementBlockEndContext(this._ctx, this.state);
		this.enterRule(_localctx, 44, AScriptParser.RULE_statementBlockEnd);
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 286;
			this.match(AScriptParser.R_BRACE);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public ifStatement(): IfStatementContext {
		let _localctx: IfStatementContext = new IfStatementContext(this._ctx, this.state);
		this.enterRule(_localctx, 46, AScriptParser.RULE_ifStatement);
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 288;
			this.match(AScriptParser.KW_IF);
			this.state = 289;
			this.match(AScriptParser.L_PAREN);
			this.state = 290;
			this.expression();
			this.state = 291;
			this.match(AScriptParser.R_PAREN);
			this.state = 294;
			this._errHandler.sync(this);
			switch ( this.interpreter.adaptivePredict(this._input, 25, this._ctx) ) {
			case 1:
				{
				this.state = 292;
				this.statement();
				}
				break;

			case 2:
				{
				this.state = 293;
				this.statementBlockBegin();
				}
				break;
			}
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public elseStatement(): ElseStatementContext {
		let _localctx: ElseStatementContext = new ElseStatementContext(this._ctx, this.state);
		this.enterRule(_localctx, 48, AScriptParser.RULE_elseStatement);
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 296;
			this.match(AScriptParser.KW_ELSE);
			this.state = 298;
			this._errHandler.sync(this);
			switch ( this.interpreter.adaptivePredict(this._input, 26, this._ctx) ) {
			case 1:
				{
				this.state = 297;
				this.statementBlockBegin();
				}
				break;
			}
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public whileStatement(): WhileStatementContext {
		let _localctx: WhileStatementContext = new WhileStatementContext(this._ctx, this.state);
		this.enterRule(_localctx, 50, AScriptParser.RULE_whileStatement);
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 300;
			this.match(AScriptParser.KW_WHILE);
			this.state = 301;
			this.match(AScriptParser.L_PAREN);
			this.state = 302;
			this.expression();
			this.state = 303;
			this.match(AScriptParser.R_PAREN);
			this.state = 305;
			this._errHandler.sync(this);
			switch ( this.interpreter.adaptivePredict(this._input, 27, this._ctx) ) {
			case 1:
				{
				this.state = 304;
				this.statementBlockBegin();
				}
				break;
			}
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public returnStatement(): ReturnStatementContext {
		let _localctx: ReturnStatementContext = new ReturnStatementContext(this._ctx, this.state);
		this.enterRule(_localctx, 52, AScriptParser.RULE_returnStatement);
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 307;
			this.match(AScriptParser.KW_RETURN);
			this.state = 308;
			this.expression();
			this.state = 309;
			this.match(AScriptParser.SEMICOLON);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public incrementStatement(): IncrementStatementContext {
		let _localctx: IncrementStatementContext = new IncrementStatementContext(this._ctx, this.state);
		this.enterRule(_localctx, 54, AScriptParser.RULE_incrementStatement);
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 311;
			this.match(AScriptParser.INCREMENT);
			this.state = 312;
			this.expression();
			this.state = 313;
			this.match(AScriptParser.SEMICOLON);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public decrementStatement(): DecrementStatementContext {
		let _localctx: DecrementStatementContext = new DecrementStatementContext(this._ctx, this.state);
		this.enterRule(_localctx, 56, AScriptParser.RULE_decrementStatement);
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 315;
			this.match(AScriptParser.DECREMENT);
			this.state = 316;
			this.expression();
			this.state = 317;
			this.match(AScriptParser.SEMICOLON);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public continueStatement(): ContinueStatementContext {
		let _localctx: ContinueStatementContext = new ContinueStatementContext(this._ctx, this.state);
		this.enterRule(_localctx, 58, AScriptParser.RULE_continueStatement);
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 319;
			this.match(AScriptParser.KW_CONTINUE);
			this.state = 320;
			this.match(AScriptParser.SEMICOLON);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public printStatement(): PrintStatementContext {
		let _localctx: PrintStatementContext = new PrintStatementContext(this._ctx, this.state);
		this.enterRule(_localctx, 60, AScriptParser.RULE_printStatement);
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 322;
			this.match(AScriptParser.KW_PRINT);
			this.state = 323;
			this.match(AScriptParser.L_PAREN);
			this.state = 324;
			this.expression();
			this.state = 325;
			this.match(AScriptParser.R_PAREN);
			this.state = 326;
			this.match(AScriptParser.SEMICOLON);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public doubleVector(): DoubleVectorContext {
		let _localctx: DoubleVectorContext = new DoubleVectorContext(this._ctx, this.state);
		this.enterRule(_localctx, 62, AScriptParser.RULE_doubleVector);
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 328;
			this.match(AScriptParser.KW_DOUBLE_VECTOR);
			this.state = 329;
			this.match(AScriptParser.L_PAREN);
			this.state = 330;
			this.match(AScriptParser.STRING_LITERAL);
			this.state = 331;
			this.match(AScriptParser.R_PAREN);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}

	public sempred(_localctx: RuleContext, ruleIndex: number, predIndex: number): boolean {
		switch (ruleIndex) {
		case 7:
			return this.opExpression_sempred(_localctx as OpExpressionContext, predIndex);
		}
		return true;
	}
	private opExpression_sempred(_localctx: OpExpressionContext, predIndex: number): boolean {
		switch (predIndex) {
		case 0:
			return this.precpred(this._ctx, 9);

		case 1:
			return this.precpred(this._ctx, 8);

		case 2:
			return this.precpred(this._ctx, 7);

		case 3:
			return this.precpred(this._ctx, 6);

		case 4:
			return this.precpred(this._ctx, 5);

		case 5:
			return this.precpred(this._ctx, 4);

		case 6:
			return this.precpred(this._ctx, 3);

		case 7:
			return this.precpred(this._ctx, 2);

		case 8:
			return this.precpred(this._ctx, 1);
		}
		return true;
	}

	public static readonly _serializedATN: string =
		"\x03\uC91D\uCABA\u058D\uAFBA\u4F53\u0607\uEA8B\uC241\x03T\u0150\x04\x02" +
		"\t\x02\x04\x03\t\x03\x04\x04\t\x04\x04\x05\t\x05\x04\x06\t\x06\x04\x07" +
		"\t\x07\x04\b\t\b\x04\t\t\t\x04\n\t\n\x04\v\t\v\x04\f\t\f\x04\r\t\r\x04" +
		"\x0E\t\x0E\x04\x0F\t\x0F\x04\x10\t\x10\x04\x11\t\x11\x04\x12\t\x12\x04" +
		"\x13\t\x13\x04\x14\t\x14\x04\x15\t\x15\x04\x16\t\x16\x04\x17\t\x17\x04" +
		"\x18\t\x18\x04\x19\t\x19\x04\x1A\t\x1A\x04\x1B\t\x1B\x04\x1C\t\x1C\x04" +
		"\x1D\t\x1D\x04\x1E\t\x1E\x04\x1F\t\x1F\x04 \t \x04!\t!\x03\x02\x03\x02" +
		"\x05\x02E\n\x02\x03\x02\x03\x02\x03\x02\x03\x02\x05\x02K\n\x02\x03\x02" +
		"\x03\x02\x03\x02\x03\x02\x03\x02\x07\x02R\n\x02\f\x02\x0E\x02U\v\x02\x05" +
		"\x02W\n\x02\x03\x02\x03\x02\x03\x03\x03\x03\x05\x03]\n\x03\x03\x04\x03" +
		"\x04\x03\x04\x03\x05\x03\x05\x03\x05\x03\x05\x03\x05\x03\x05\x03\x05\x03" +
		"\x06\x03\x06\x03\x06\x03\x06\x03\x06\x05\x06n\n\x06\x03\x06\x03\x06\x03" +
		"\x07\x03\x07\x05\x07t\n\x07\x03\b\x03\b\x05\bx\n\b\x03\b\x03\b\x03\b\x03" +
		"\t\x03\t\x03\t\x03\t\x05\t\x81\n\t\x03\t\x03\t\x03\t\x03\t\x03\t\x03\t" +
		"\x03\t\x03\t\x03\t\x03\t\x03\t\x03\t\x03\t\x03\t\x03\t\x03\t\x03\t\x03" +
		"\t\x03\t\x03\t\x03\t\x03\t\x03\t\x03\t\x03\t\x03\t\x03\t\x07\t\x9E\n\t" +
		"\f\t\x0E\t\xA1\v\t\x03\n\x03\n\x05\n\xA5\n\n\x03\n\x03\n\x03\n\x03\n\x03" +
		"\n\x03\n\x03\n\x03\n\x03\n\x05\n\xB0\n\n\x03\n\x05\n\xB3\n\n\x03\v\x03" +
		"\v\x03\v\x03\v\x03\v\x05\v\xBA\n\v\x05\v\xBC\n\v\x03\v\x03\v\x03\f\x03" +
		"\f\x03\f\x03\f\x03\r\x03\r\x03\r\x03\r\x03\r\x03\r\x03\x0E\x03\x0E\x03" +
		"\x0E\x03\x0E\x03\x0E\x03\x0E\x03\x0E\x07\x0E\xD1\n\x0E\f\x0E\x0E\x0E\xD4" +
		"\v\x0E\x05\x0E\xD6\n\x0E\x03\x0E\x03\x0E\x03\x0F\x03\x0F\x03\x0F\x03\x0F" +
		"\x05\x0F\xDE\n\x0F\x03\x0F\x03\x0F\x03\x0F\x03\x0F\x03\x0F\x05\x0F\xE5" +
		"\n\x0F\x03\x10\x03\x10\x03\x10\x03\x11\x03\x11\x03\x12\x03\x12\x03\x12" +
		"\x03\x12\x03\x12\x03\x12\x03\x12\x03\x12\x03\x12\x03\x12\x03\x12\x03\x12" +
		"\x03\x12\x03\x12\x03\x12\x03\x12\x03\x12\x03\x12\x03\x12\x05\x12\xFF\n" +
		"\x12\x03\x13\x03\x13\x03\x13\x03\x13\x03\x14\x03\x14\x03\x14\x03\x14\x03" +
		"\x14\x03\x14\x03\x15\x03\x15\x03\x15\x07\x15\u010E\n\x15\f\x15\x0E\x15" +
		"\u0111\v\x15\x03\x15\x05\x15\u0114\n\x15\x03\x16\x03\x16\x07\x16\u0118" +
		"\n\x16\f\x16\x0E\x16\u011B\v\x16\x03\x16\x03\x16\x03\x17\x03\x17\x03\x18" +
		"\x03\x18\x03\x19\x03\x19\x03\x19\x03\x19\x03\x19\x03\x19\x05\x19\u0129" +
		"\n\x19\x03\x1A\x03\x1A\x05\x1A\u012D\n\x1A\x03\x1B\x03\x1B\x03\x1B\x03" +
		"\x1B\x03\x1B\x05\x1B\u0134\n\x1B\x03\x1C\x03\x1C\x03\x1C\x03\x1C\x03\x1D" +
		"\x03\x1D\x03\x1D\x03\x1D\x03\x1E\x03\x1E\x03\x1E\x03\x1E\x03\x1F\x03\x1F" +
		"\x03\x1F\x03 \x03 \x03 \x03 \x03 \x03 \x03!\x03!\x03!\x03!\x03!\x03!\x02" +
		"\x02\x03\x10\"\x02\x02\x04\x02\x06\x02\b\x02\n\x02\f\x02\x0E\x02\x10\x02" +
		"\x12\x02\x14\x02\x16\x02\x18\x02\x1A\x02\x1C\x02\x1E\x02 \x02\"\x02$\x02" +
		"&\x02(\x02*\x02,\x02.\x020\x022\x024\x026\x028\x02:\x02<\x02>\x02@\x02" +
		"\x02\n\x04\x02AAEF\x03\x02BD\x03\x02@A\x03\x02<?\x03\x02:;\x03\x02\"#" +
		"\x03\x02\"%\x03\x02ST\x02\u0170\x02V\x03\x02\x02\x02\x04Z\x03\x02\x02" +
		"\x02\x06^\x03\x02\x02\x02\ba\x03\x02\x02\x02\nh\x03\x02\x02\x02\fs\x03" +
		"\x02\x02\x02\x0Ew\x03\x02\x02\x02\x10\x80\x03\x02\x02\x02\x12\xB2\x03" +
		"\x02\x02\x02\x14\xB4\x03\x02\x02\x02\x16\xBF\x03\x02\x02\x02\x18\xC3\x03" +
		"\x02\x02\x02\x1A\xC9\x03\x02\x02\x02\x1C\xE4\x03\x02\x02\x02\x1E\xE6\x03" +
		"\x02\x02\x02 \xE9\x03\x02\x02\x02\"\xFE\x03\x02\x02\x02$\u0100\x03\x02" +
		"\x02\x02&\u0104\x03\x02\x02\x02(\u010A\x03\x02\x02\x02*\u0115\x03\x02" +
		"\x02\x02,\u011E\x03\x02\x02\x02.\u0120\x03\x02\x02\x020\u0122\x03\x02" +
		"\x02\x022\u012A\x03\x02\x02\x024\u012E\x03\x02\x02\x026\u0135\x03\x02" +
		"\x02\x028\u0139\x03\x02\x02\x02:\u013D\x03\x02\x02\x02<\u0141\x03\x02" +
		"\x02\x02>\u0144\x03\x02\x02\x02@\u014A\x03\x02\x02\x02BD\x05\x04\x03\x02" +
		"CE\x07.\x02\x02DC\x03\x02\x02\x02DE\x03\x02\x02\x02EW\x03\x02\x02\x02" +
		"FK\x05\x06\x04\x02GK\x05\x1E\x10\x02HK\x05\b\x05\x02IK\x05\n\x06\x02J" +
		"F\x03\x02\x02\x02JG\x03\x02\x02\x02JH\x03\x02\x02\x02JI\x03\x02\x02\x02" +
		"KL\x03\x02\x02\x02LM\x07\'\x02\x02MR\x03\x02\x02\x02NR\x05&\x14\x02OR" +
		"\x05\"\x12\x02PR\x07.\x02\x02QJ\x03\x02\x02\x02QN\x03\x02\x02\x02QO\x03" +
		"\x02\x02\x02QP\x03\x02\x02\x02RU\x03\x02\x02\x02SQ\x03\x02\x02\x02ST\x03" +
		"\x02\x02\x02TW\x03\x02\x02\x02US\x03\x02\x02\x02VB\x03\x02\x02\x02VS\x03" +
		"\x02\x02\x02WX\x03\x02\x02\x02XY\x07\x02\x02\x03Y\x03\x03\x02\x02\x02" +
		"Z\\\x05\f\x07\x02[]\x07\'\x02\x02\\[\x03\x02\x02\x02\\]\x03\x02\x02\x02" +
		"]\x05\x03\x02\x02\x02^_\x07\f\x02\x02_`\x07\x19\x02\x02`\x07\x03\x02\x02" +
		"\x02ab\x07\x13\x02\x02bc\x07/\x02\x02cd\x07%\x02\x02de\x07*\x02\x02ef" +
		"\x07\x17\x02\x02fg\x070\x02\x02g\t\x03\x02\x02\x02hi\x07\x14\x02\x02i" +
		"j\x07/\x02\x02jm\x07%\x02\x02kl\x07*\x02\x02ln\x07\x17\x02\x02mk\x03\x02" +
		"\x02\x02mn\x03\x02\x02\x02no\x03\x02\x02\x02op\x070\x02\x02p\v\x03\x02" +
		"\x02\x02qt\x05\x0E\b\x02rt\x05\x10\t\x02sq\x03\x02\x02\x02sr\x03\x02\x02" +
		"\x02t\r\x03\x02\x02\x02ux\x05\x1C\x0F\x02vx\x05\x1E\x10\x02wu\x03\x02" +
		"\x02\x02wv\x03\x02\x02\x02xy\x03\x02\x02\x02yz\x07)\x02\x02z{\x05\f\x07" +
		"\x02{\x0F\x03\x02\x02\x02|}\b\t\x01\x02}\x81\x05\x12\n\x02~\x7F\t\x02" +
		"\x02\x02\x7F\x81\x05\x10\t\f\x80|\x03\x02\x02\x02\x80~\x03\x02\x02\x02" +
		"\x81\x9F\x03\x02\x02\x02\x82\x83\f\v\x02\x02\x83\x84\t\x03\x02\x02\x84" +
		"\x9E\x05\x10\t\f\x85\x86\f\n\x02\x02\x86\x87\t\x04\x02\x02\x87\x9E\x05" +
		"\x10\t\v\x88\x89\f\t\x02\x02\x89\x8A\t\x05\x02\x02\x8A\x9E\x05\x10\t\n" +
		"\x8B\x8C\f\b\x02\x02\x8C\x8D\t\x06\x02\x02\x8D\x9E\x05\x10\t\t\x8E\x8F" +
		"\f\x07\x02\x02\x8F\x90\x079\x02\x02\x90\x9E\x05\x10\t\b\x91\x92\f\x06" +
		"\x02\x02\x92\x93\x078\x02\x02\x93\x9E\x05\x10\t\x07\x94\x95\f\x05\x02" +
		"\x02\x95\x96\x077\x02\x02\x96\x9E\x05\x10\t\x06\x97\x98\f\x04\x02\x02" +
		"\x98\x99\x076\x02\x02\x99\x9E\x05\x10\t\x05\x9A\x9B\f\x03\x02\x02\x9B" +
		"\x9C\x075\x02\x02\x9C\x9E\x05\x10\t\x04\x9D\x82\x03\x02\x02\x02\x9D\x85" +
		"\x03\x02\x02\x02\x9D\x88\x03\x02\x02\x02\x9D\x8B\x03\x02\x02\x02\x9D\x8E" +
		"\x03\x02\x02\x02\x9D\x91\x03\x02\x02\x02\x9D\x94\x03\x02\x02\x02\x9D\x97" +
		"\x03\x02\x02\x02\x9D\x9A\x03\x02\x02\x02\x9E\xA1\x03\x02\x02\x02\x9F\x9D" +
		"\x03\x02\x02\x02\x9F\xA0\x03\x02\x02\x02\xA0\x11\x03\x02\x02\x02\xA1\x9F" +
		"\x03\x02\x02\x02\xA2\xB3\x05\x1C\x0F\x02\xA3\xA5\x07 \x02\x02\xA4\xA3" +
		"\x03\x02\x02\x02\xA4\xA5\x03\x02\x02\x02\xA5\xA6\x03\x02\x02\x02\xA6\xB3" +
		"\x07\x17\x02\x02\xA7\xB3\x07\x19\x02\x02\xA8\xB3\x07\x18\x02\x02\xA9\xB3" +
		"\x07\x1A\x02\x02\xAA\xB3\x07\x1C\x02\x02\xAB\xB3\x05\x14\v\x02\xAC\xB3" +
		"\x05\x16\f\x02\xAD\xB3\x05\x18\r\x02\xAE\xB0\x07 \x02\x02\xAF\xAE\x03" +
		"\x02\x02\x02\xAF\xB0\x03\x02\x02\x02\xB0\xB1\x03\x02\x02\x02\xB1\xB3\x05" +
		"\x1A\x0E\x02\xB2\xA2\x03\x02\x02\x02\xB2\xA4\x03\x02\x02\x02\xB2\xA7\x03" +
		"\x02\x02\x02\xB2\xA8\x03\x02\x02\x02\xB2\xA9\x03\x02\x02\x02\xB2\xAA\x03" +
		"\x02\x02\x02\xB2\xAB\x03\x02\x02\x02\xB2\xAC\x03\x02\x02\x02\xB2\xAD\x03" +
		"\x02\x02\x02\xB2\xAF\x03\x02\x02\x02\xB3\x13\x03\x02\x02\x02\xB4\xB5\x07" +
		"\x1F\x02\x02\xB5\xBB\x07/\x02\x02\xB6\xB9\x05\f\x07\x02\xB7\xB8\x07*\x02" +
		"\x02\xB8\xBA\x05\f\x07\x02\xB9\xB7\x03\x02\x02\x02\xB9\xBA\x03\x02\x02" +
		"\x02\xBA\xBC\x03\x02\x02\x02\xBB\xB6\x03\x02\x02\x02\xBB\xBC\x03\x02\x02" +
		"\x02\xBC\xBD\x03\x02\x02\x02\xBD\xBE\x070\x02\x02\xBE\x15\x03\x02\x02" +
		"\x02\xBF\xC0\x07\x1D\x02\x02\xC0\xC1\x07/\x02\x02\xC1\xC2\x070\x02\x02" +
		"\xC2\x17\x03\x02\x02\x02\xC3\xC4\x07&\x02\x02\xC4\xC5\x07(\x02\x02\xC5" +
		"\xC6\x07\x1E\x02\x02\xC6\xC7\x07/\x02\x02\xC7\xC8\x070\x02\x02\xC8\x19" +
		"\x03\x02\x02\x02\xC9\xCA\x07&\x02\x02\xCA\xCB\x07(\x02\x02\xCB\xCC\x07" +
		"&\x02\x02\xCC\xD5\x07/\x02\x02\xCD\xD2\x05\f\x07\x02\xCE\xCF\x07*\x02" +
		"\x02\xCF\xD1\x05\f\x07\x02\xD0\xCE\x03\x02\x02\x02\xD1\xD4\x03\x02\x02" +
		"\x02\xD2\xD0\x03\x02\x02\x02\xD2\xD3\x03\x02\x02\x02\xD3\xD6\x03\x02\x02" +
		"\x02\xD4\xD2\x03\x02\x02\x02\xD5\xCD\x03\x02\x02\x02\xD5\xD6\x03\x02\x02" +
		"\x02\xD6\xD7\x03\x02\x02\x02\xD7\xD8\x070\x02\x02\xD8\x1B\x03\x02\x02" +
		"\x02\xD9\xDA\x07 \x02\x02\xDA\xE5\t\x07\x02\x02\xDB\xE5\x05 \x11\x02\xDC" +
		"\xDE\x07 \x02\x02\xDD\xDC\x03\x02\x02\x02\xDD\xDE\x03\x02\x02\x02\xDE" +
		"\xDF\x03\x02\x02\x02\xDF\xE5\x07&\x02\x02\xE0\xE1\x07/\x02\x02\xE1\xE2" +
		"\x05\f\x07\x02\xE2\xE3\x070\x02\x02\xE3\xE5\x03\x02\x02\x02\xE4\xD9\x03" +
		"\x02\x02\x02\xE4\xDB\x03\x02\x02\x02\xE4\xDD\x03\x02\x02\x02\xE4\xE0\x03" +
		"\x02\x02\x02\xE5\x1D\x03\x02\x02\x02\xE6\xE7\x07!\x02\x02\xE7\xE8\x07" +
		"&\x02\x02\xE8\x1F\x03\x02\x02\x02\xE9\xEA\t\b\x02\x02\xEA!\x03\x02\x02" +
		"\x02\xEB\xFF\x07\'\x02\x02\xEC\xFF\x05$\x13\x02\xED\xFF\x07\x03\x02\x02" +
		"\xEE\xFF\x05,\x17\x02\xEF\xFF\x05.\x18\x02\xF0\xFF\x05*\x16\x02\xF1\xF2" +
		"\x05\x0E\b\x02\xF2\xF3\x07\'\x02\x02\xF3\xFF\x03\x02\x02\x02\xF4\xFF\x05" +
		"0\x19\x02\xF5\xFF\x052\x1A\x02\xF6\xFF\x054\x1B\x02\xF7\xFF\x05<\x1F\x02" +
		"\xF8\xFF\x05> \x02\xF9\xFF\x058\x1D\x02\xFA\xFF\x05:\x1E\x02\xFB\xFF\x05" +
		"6\x1C\x02\xFC\xFF\x05\x1A\x0E\x02\xFD\xFF\x05@!\x02\xFE\xEB\x03\x02\x02" +
		"\x02\xFE\xEC\x03\x02\x02\x02\xFE\xED\x03\x02\x02\x02\xFE\xEE\x03\x02\x02" +
		"\x02\xFE\xEF\x03\x02\x02\x02\xFE\xF0\x03\x02\x02\x02\xFE\xF1\x03\x02\x02" +
		"\x02\xFE\xF4\x03\x02\x02\x02\xFE\xF5\x03\x02\x02\x02\xFE\xF6\x03\x02\x02" +
		"\x02\xFE\xF7\x03\x02\x02\x02\xFE\xF8\x03\x02\x02\x02\xFE\xF9\x03\x02\x02" +
		"\x02\xFE\xFA\x03\x02\x02\x02\xFE\xFB\x03\x02\x02\x02\xFE\xFC\x03\x02\x02" +
		"\x02\xFE\xFD\x03\x02\x02\x02\xFF#\x03\x02\x02\x02\u0100\u0101\x07&\x02" +
		"\x02\u0101\u0102\x07+\x02\x02\u0102\u0103\x05\"\x12\x02\u0103%\x03\x02" +
		"\x02\x02\u0104\u0105\x07\v\x02\x02\u0105\u0106\x05(\x15\x02\u0106\u0107" +
		"\x07K\x02\x02\u0107\u0108\x07M\x02\x02\u0108\u0109\x07Q\x02\x02\u0109" +
		"\'\x03\x02\x02\x02\u010A\u010F\x07M\x02\x02\u010B\u010C\x07P\x02\x02\u010C" +
		"\u010E\x07M\x02\x02\u010D\u010B\x03\x02\x02\x02\u010E\u0111\x03\x02\x02" +
		"\x02\u010F\u010D\x03\x02\x02\x02\u010F\u0110\x03\x02\x02\x02\u0110\u0113" +
		"\x03\x02\x02\x02\u0111\u010F\x03\x02\x02\x02\u0112\u0114\x07L\x02\x02" +
		"\u0113\u0112\x03\x02\x02\x02\u0113\u0114\x03\x02\x02\x02\u0114)\x03\x02" +
		"\x02\x02\u0115\u0119\x07\x15\x02\x02\u0116\u0118\t\t\x02\x02\u0117\u0116" +
		"\x03\x02\x02\x02\u0118\u011B\x03\x02\x02\x02\u0119\u0117\x03\x02\x02\x02" +
		"\u0119\u011A\x03\x02\x02\x02\u011A\u011C\x03\x02\x02\x02\u011B\u0119\x03" +
		"\x02\x02\x02\u011C\u011D\x07R\x02\x02\u011D+\x03\x02\x02\x02\u011E\u011F" +
		"\x073\x02\x02\u011F-\x03\x02\x02\x02\u0120\u0121\x074\x02\x02\u0121/\x03" +
		"\x02\x02\x02\u0122\u0123\x07\x04\x02\x02\u0123\u0124\x07/\x02\x02\u0124" +
		"\u0125\x05\f\x07\x02\u0125\u0128\x070\x02\x02\u0126\u0129\x05\"\x12\x02" +
		"\u0127\u0129\x05,\x17\x02\u0128\u0126\x03\x02\x02\x02\u0128\u0127\x03" +
		"\x02\x02\x02\u0128\u0129\x03\x02\x02\x02\u01291\x03\x02\x02\x02\u012A" +
		"\u012C\x07\x05\x02\x02\u012B\u012D\x05,\x17\x02\u012C\u012B\x03\x02\x02" +
		"\x02\u012C\u012D\x03\x02\x02\x02\u012D3\x03\x02\x02\x02\u012E\u012F\x07" +
		"\x06\x02\x02\u012F\u0130\x07/\x02\x02\u0130\u0131\x05\f\x07\x02\u0131" +
		"\u0133\x070\x02\x02\u0132\u0134\x05,\x17\x02\u0133\u0132\x03\x02\x02\x02" +
		"\u0133\u0134\x03\x02\x02\x02\u01345\x03\x02\x02\x02\u0135\u0136\x07\x07" +
		"\x02\x02\u0136\u0137\x05\f\x07\x02\u0137\u0138\x07\'\x02\x02\u01387\x03" +
		"\x02\x02\x02\u0139\u013A\x07G\x02\x02\u013A\u013B\x05\f\x07\x02\u013B" +
		"\u013C\x07\'\x02\x02\u013C9\x03\x02\x02\x02\u013D\u013E\x07H\x02\x02\u013E" +
		"\u013F\x05\f\x07\x02\u013F\u0140\x07\'\x02\x02\u0140;\x03\x02\x02\x02" +
		"\u0141\u0142\x07\b\x02\x02\u0142\u0143\x07\'\x02\x02\u0143=\x03\x02\x02" +
		"\x02\u0144\u0145\x07\t\x02\x02\u0145\u0146\x07/\x02\x02\u0146\u0147\x05" +
		"\f\x07\x02\u0147\u0148\x070\x02\x02\u0148\u0149\x07\'\x02\x02\u0149?\x03" +
		"\x02\x02\x02\u014A\u014B\x07\x16\x02\x02\u014B\u014C\x07/\x02\x02\u014C" +
		"\u014D\x07%\x02\x02\u014D\u014E\x070\x02\x02\u014EA\x03\x02\x02\x02\x1E" +
		"DJQSV\\msw\x80\x9D\x9F\xA4\xAF\xB2\xB9\xBB\xD2\xD5\xDD\xE4\xFE\u010F\u0113" +
		"\u0119\u0128\u012C\u0133";
	public static __ATN: ATN;
	public static get _ATN(): ATN {
		if (!AScriptParser.__ATN) {
			AScriptParser.__ATN = new ATNDeserializer().deserialize(Utils.toCharArray(AScriptParser._serializedATN));
		}

		return AScriptParser.__ATN;
	}

}

export class CompilationUnitContext extends ParserRuleContext {
	public EOF(): TerminalNode { return this.getToken(AScriptParser.EOF, 0); }
	public singleExpression(): SingleExpressionContext | undefined {
		return this.tryGetRuleContext(0, SingleExpressionContext);
	}
	public NEW_LINE(): TerminalNode[];
	public NEW_LINE(i: number): TerminalNode;
	public NEW_LINE(i?: number): TerminalNode | TerminalNode[] {
		if (i === undefined) {
			return this.getTokens(AScriptParser.NEW_LINE);
		} else {
			return this.getToken(AScriptParser.NEW_LINE, i);
		}
	}
	public importStatement(): ImportStatementContext[];
	public importStatement(i: number): ImportStatementContext;
	public importStatement(i?: number): ImportStatementContext | ImportStatementContext[] {
		if (i === undefined) {
			return this.getRuleContexts(ImportStatementContext);
		} else {
			return this.getRuleContext(i, ImportStatementContext);
		}
	}
	public statement(): StatementContext[];
	public statement(i: number): StatementContext;
	public statement(i?: number): StatementContext | StatementContext[] {
		if (i === undefined) {
			return this.getRuleContexts(StatementContext);
		} else {
			return this.getRuleContext(i, StatementContext);
		}
	}
	public SEMICOLON(): TerminalNode[];
	public SEMICOLON(i: number): TerminalNode;
	public SEMICOLON(i?: number): TerminalNode | TerminalNode[] {
		if (i === undefined) {
			return this.getTokens(AScriptParser.SEMICOLON);
		} else {
			return this.getToken(AScriptParser.SEMICOLON, i);
		}
	}
	public requiredKeyDeclaration(): RequiredKeyDeclarationContext[];
	public requiredKeyDeclaration(i: number): RequiredKeyDeclarationContext;
	public requiredKeyDeclaration(i?: number): RequiredKeyDeclarationContext | RequiredKeyDeclarationContext[] {
		if (i === undefined) {
			return this.getRuleContexts(RequiredKeyDeclarationContext);
		} else {
			return this.getRuleContext(i, RequiredKeyDeclarationContext);
		}
	}
	public varDeclaration(): VarDeclarationContext[];
	public varDeclaration(i: number): VarDeclarationContext;
	public varDeclaration(i?: number): VarDeclarationContext | VarDeclarationContext[] {
		if (i === undefined) {
			return this.getRuleContexts(VarDeclarationContext);
		} else {
			return this.getRuleContext(i, VarDeclarationContext);
		}
	}
	public rAssignment(): RAssignmentContext[];
	public rAssignment(i: number): RAssignmentContext;
	public rAssignment(i?: number): RAssignmentContext | RAssignmentContext[] {
		if (i === undefined) {
			return this.getRuleContexts(RAssignmentContext);
		} else {
			return this.getRuleContext(i, RAssignmentContext);
		}
	}
	public armAssignment(): ArmAssignmentContext[];
	public armAssignment(i: number): ArmAssignmentContext;
	public armAssignment(i?: number): ArmAssignmentContext | ArmAssignmentContext[] {
		if (i === undefined) {
			return this.getRuleContexts(ArmAssignmentContext);
		} else {
			return this.getRuleContext(i, ArmAssignmentContext);
		}
	}
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return AScriptParser.RULE_compilationUnit; }
	// @Override
	public enterRule(listener: AScriptParserListener): void {
		if (listener.enterCompilationUnit) {
			listener.enterCompilationUnit(this);
		}
	}
	// @Override
	public exitRule(listener: AScriptParserListener): void {
		if (listener.exitCompilationUnit) {
			listener.exitCompilationUnit(this);
		}
	}
	// @Override
	public accept<Result>(visitor: AScriptParserVisitor<Result>): Result {
		if (visitor.visitCompilationUnit) {
			return visitor.visitCompilationUnit(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class SingleExpressionContext extends ParserRuleContext {
	public expression(): ExpressionContext {
		return this.getRuleContext(0, ExpressionContext);
	}
	public SEMICOLON(): TerminalNode | undefined { return this.tryGetToken(AScriptParser.SEMICOLON, 0); }
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return AScriptParser.RULE_singleExpression; }
	// @Override
	public enterRule(listener: AScriptParserListener): void {
		if (listener.enterSingleExpression) {
			listener.enterSingleExpression(this);
		}
	}
	// @Override
	public exitRule(listener: AScriptParserListener): void {
		if (listener.exitSingleExpression) {
			listener.exitSingleExpression(this);
		}
	}
	// @Override
	public accept<Result>(visitor: AScriptParserVisitor<Result>): Result {
		if (visitor.visitSingleExpression) {
			return visitor.visitSingleExpression(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class RequiredKeyDeclarationContext extends ParserRuleContext {
	public KW_REQUIRES(): TerminalNode { return this.getToken(AScriptParser.KW_REQUIRES, 0); }
	public VALUE_KEY_EXPRESSION(): TerminalNode { return this.getToken(AScriptParser.VALUE_KEY_EXPRESSION, 0); }
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return AScriptParser.RULE_requiredKeyDeclaration; }
	// @Override
	public enterRule(listener: AScriptParserListener): void {
		if (listener.enterRequiredKeyDeclaration) {
			listener.enterRequiredKeyDeclaration(this);
		}
	}
	// @Override
	public exitRule(listener: AScriptParserListener): void {
		if (listener.exitRequiredKeyDeclaration) {
			listener.exitRequiredKeyDeclaration(this);
		}
	}
	// @Override
	public accept<Result>(visitor: AScriptParserVisitor<Result>): Result {
		if (visitor.visitRequiredKeyDeclaration) {
			return visitor.visitRequiredKeyDeclaration(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class RAssignmentContext extends ParserRuleContext {
	public KW_R_ASSIGN(): TerminalNode { return this.getToken(AScriptParser.KW_R_ASSIGN, 0); }
	public L_PAREN(): TerminalNode { return this.getToken(AScriptParser.L_PAREN, 0); }
	public STRING_LITERAL(): TerminalNode { return this.getToken(AScriptParser.STRING_LITERAL, 0); }
	public COMMA(): TerminalNode { return this.getToken(AScriptParser.COMMA, 0); }
	public KEY_EXPRESSION(): TerminalNode { return this.getToken(AScriptParser.KEY_EXPRESSION, 0); }
	public R_PAREN(): TerminalNode { return this.getToken(AScriptParser.R_PAREN, 0); }
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return AScriptParser.RULE_rAssignment; }
	// @Override
	public enterRule(listener: AScriptParserListener): void {
		if (listener.enterRAssignment) {
			listener.enterRAssignment(this);
		}
	}
	// @Override
	public exitRule(listener: AScriptParserListener): void {
		if (listener.exitRAssignment) {
			listener.exitRAssignment(this);
		}
	}
	// @Override
	public accept<Result>(visitor: AScriptParserVisitor<Result>): Result {
		if (visitor.visitRAssignment) {
			return visitor.visitRAssignment(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class ArmAssignmentContext extends ParserRuleContext {
	public KW_ARM_ASSIGN(): TerminalNode { return this.getToken(AScriptParser.KW_ARM_ASSIGN, 0); }
	public L_PAREN(): TerminalNode { return this.getToken(AScriptParser.L_PAREN, 0); }
	public STRING_LITERAL(): TerminalNode { return this.getToken(AScriptParser.STRING_LITERAL, 0); }
	public R_PAREN(): TerminalNode { return this.getToken(AScriptParser.R_PAREN, 0); }
	public COMMA(): TerminalNode | undefined { return this.tryGetToken(AScriptParser.COMMA, 0); }
	public KEY_EXPRESSION(): TerminalNode | undefined { return this.tryGetToken(AScriptParser.KEY_EXPRESSION, 0); }
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return AScriptParser.RULE_armAssignment; }
	// @Override
	public enterRule(listener: AScriptParserListener): void {
		if (listener.enterArmAssignment) {
			listener.enterArmAssignment(this);
		}
	}
	// @Override
	public exitRule(listener: AScriptParserListener): void {
		if (listener.exitArmAssignment) {
			listener.exitArmAssignment(this);
		}
	}
	// @Override
	public accept<Result>(visitor: AScriptParserVisitor<Result>): Result {
		if (visitor.visitArmAssignment) {
			return visitor.visitArmAssignment(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class ExpressionContext extends ParserRuleContext {
	public assignment(): AssignmentContext | undefined {
		return this.tryGetRuleContext(0, AssignmentContext);
	}
	public opExpression(): OpExpressionContext | undefined {
		return this.tryGetRuleContext(0, OpExpressionContext);
	}
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return AScriptParser.RULE_expression; }
	// @Override
	public enterRule(listener: AScriptParserListener): void {
		if (listener.enterExpression) {
			listener.enterExpression(this);
		}
	}
	// @Override
	public exitRule(listener: AScriptParserListener): void {
		if (listener.exitExpression) {
			listener.exitExpression(this);
		}
	}
	// @Override
	public accept<Result>(visitor: AScriptParserVisitor<Result>): Result {
		if (visitor.visitExpression) {
			return visitor.visitExpression(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class AssignmentContext extends ParserRuleContext {
	public EQUAL_SIGN(): TerminalNode { return this.getToken(AScriptParser.EQUAL_SIGN, 0); }
	public expression(): ExpressionContext {
		return this.getRuleContext(0, ExpressionContext);
	}
	public primaryNonKeyExpression(): PrimaryNonKeyExpressionContext | undefined {
		return this.tryGetRuleContext(0, PrimaryNonKeyExpressionContext);
	}
	public varDeclaration(): VarDeclarationContext | undefined {
		return this.tryGetRuleContext(0, VarDeclarationContext);
	}
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return AScriptParser.RULE_assignment; }
	// @Override
	public enterRule(listener: AScriptParserListener): void {
		if (listener.enterAssignment) {
			listener.enterAssignment(this);
		}
	}
	// @Override
	public exitRule(listener: AScriptParserListener): void {
		if (listener.exitAssignment) {
			listener.exitAssignment(this);
		}
	}
	// @Override
	public accept<Result>(visitor: AScriptParserVisitor<Result>): Result {
		if (visitor.visitAssignment) {
			return visitor.visitAssignment(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class OpExpressionContext extends ParserRuleContext {
	public primaryExpression(): PrimaryExpressionContext | undefined {
		return this.tryGetRuleContext(0, PrimaryExpressionContext);
	}
	public opExpression(): OpExpressionContext[];
	public opExpression(i: number): OpExpressionContext;
	public opExpression(i?: number): OpExpressionContext | OpExpressionContext[] {
		if (i === undefined) {
			return this.getRuleContexts(OpExpressionContext);
		} else {
			return this.getRuleContext(i, OpExpressionContext);
		}
	}
	public BITWISE_COMPLEMENT(): TerminalNode | undefined { return this.tryGetToken(AScriptParser.BITWISE_COMPLEMENT, 0); }
	public LOGICAL_NOT(): TerminalNode | undefined { return this.tryGetToken(AScriptParser.LOGICAL_NOT, 0); }
	public ARITH_SUBTRACT(): TerminalNode | undefined { return this.tryGetToken(AScriptParser.ARITH_SUBTRACT, 0); }
	public ARITH_MULTIPLY(): TerminalNode | undefined { return this.tryGetToken(AScriptParser.ARITH_MULTIPLY, 0); }
	public ARITH_DIVIDE(): TerminalNode | undefined { return this.tryGetToken(AScriptParser.ARITH_DIVIDE, 0); }
	public ARITH_MOD(): TerminalNode | undefined { return this.tryGetToken(AScriptParser.ARITH_MOD, 0); }
	public ARITH_ADD(): TerminalNode | undefined { return this.tryGetToken(AScriptParser.ARITH_ADD, 0); }
	public COMP_LT(): TerminalNode | undefined { return this.tryGetToken(AScriptParser.COMP_LT, 0); }
	public COMP_GT(): TerminalNode | undefined { return this.tryGetToken(AScriptParser.COMP_GT, 0); }
	public COMP_LEQ(): TerminalNode | undefined { return this.tryGetToken(AScriptParser.COMP_LEQ, 0); }
	public COMP_GEQ(): TerminalNode | undefined { return this.tryGetToken(AScriptParser.COMP_GEQ, 0); }
	public COMP_EQ(): TerminalNode | undefined { return this.tryGetToken(AScriptParser.COMP_EQ, 0); }
	public COMP_NEQ(): TerminalNode | undefined { return this.tryGetToken(AScriptParser.COMP_NEQ, 0); }
	public BITWISE_AND(): TerminalNode | undefined { return this.tryGetToken(AScriptParser.BITWISE_AND, 0); }
	public BITWISE_XOR(): TerminalNode | undefined { return this.tryGetToken(AScriptParser.BITWISE_XOR, 0); }
	public BITWISE_OR(): TerminalNode | undefined { return this.tryGetToken(AScriptParser.BITWISE_OR, 0); }
	public LOGICAL_AND(): TerminalNode | undefined { return this.tryGetToken(AScriptParser.LOGICAL_AND, 0); }
	public LOGICAL_OR(): TerminalNode | undefined { return this.tryGetToken(AScriptParser.LOGICAL_OR, 0); }
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return AScriptParser.RULE_opExpression; }
	// @Override
	public enterRule(listener: AScriptParserListener): void {
		if (listener.enterOpExpression) {
			listener.enterOpExpression(this);
		}
	}
	// @Override
	public exitRule(listener: AScriptParserListener): void {
		if (listener.exitOpExpression) {
			listener.exitOpExpression(this);
		}
	}
	// @Override
	public accept<Result>(visitor: AScriptParserVisitor<Result>): Result {
		if (visitor.visitOpExpression) {
			return visitor.visitOpExpression(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class PrimaryExpressionContext extends ParserRuleContext {
	public primaryNonKeyExpression(): PrimaryNonKeyExpressionContext | undefined {
		return this.tryGetRuleContext(0, PrimaryNonKeyExpressionContext);
	}
	public KEY_EXPRESSION(): TerminalNode | undefined { return this.tryGetToken(AScriptParser.KEY_EXPRESSION, 0); }
	public CAST_OPERATOR(): TerminalNode | undefined { return this.tryGetToken(AScriptParser.CAST_OPERATOR, 0); }
	public VALUE_KEY_EXPRESSION(): TerminalNode | undefined { return this.tryGetToken(AScriptParser.VALUE_KEY_EXPRESSION, 0); }
	public TOTAL_EXPRESSION(): TerminalNode | undefined { return this.tryGetToken(AScriptParser.TOTAL_EXPRESSION, 0); }
	public INSTANCE_KEY_EXPRESSION(): TerminalNode | undefined { return this.tryGetToken(AScriptParser.INSTANCE_KEY_EXPRESSION, 0); }
	public SUBSTR_EXPRESSION(): TerminalNode | undefined { return this.tryGetToken(AScriptParser.SUBSTR_EXPRESSION, 0); }
	public mathMethodCall(): MathMethodCallContext | undefined {
		return this.tryGetRuleContext(0, MathMethodCallContext);
	}
	public nativeFunctionCall(): NativeFunctionCallContext | undefined {
		return this.tryGetRuleContext(0, NativeFunctionCallContext);
	}
	public nativeInstanceMethodCall(): NativeInstanceMethodCallContext | undefined {
		return this.tryGetRuleContext(0, NativeInstanceMethodCallContext);
	}
	public customInstanceMethodCall(): CustomInstanceMethodCallContext | undefined {
		return this.tryGetRuleContext(0, CustomInstanceMethodCallContext);
	}
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return AScriptParser.RULE_primaryExpression; }
	// @Override
	public enterRule(listener: AScriptParserListener): void {
		if (listener.enterPrimaryExpression) {
			listener.enterPrimaryExpression(this);
		}
	}
	// @Override
	public exitRule(listener: AScriptParserListener): void {
		if (listener.exitPrimaryExpression) {
			listener.exitPrimaryExpression(this);
		}
	}
	// @Override
	public accept<Result>(visitor: AScriptParserVisitor<Result>): Result {
		if (visitor.visitPrimaryExpression) {
			return visitor.visitPrimaryExpression(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class MathMethodCallContext extends ParserRuleContext {
	public MATH_METHOD(): TerminalNode { return this.getToken(AScriptParser.MATH_METHOD, 0); }
	public L_PAREN(): TerminalNode { return this.getToken(AScriptParser.L_PAREN, 0); }
	public R_PAREN(): TerminalNode { return this.getToken(AScriptParser.R_PAREN, 0); }
	public expression(): ExpressionContext[];
	public expression(i: number): ExpressionContext;
	public expression(i?: number): ExpressionContext | ExpressionContext[] {
		if (i === undefined) {
			return this.getRuleContexts(ExpressionContext);
		} else {
			return this.getRuleContext(i, ExpressionContext);
		}
	}
	public COMMA(): TerminalNode | undefined { return this.tryGetToken(AScriptParser.COMMA, 0); }
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return AScriptParser.RULE_mathMethodCall; }
	// @Override
	public enterRule(listener: AScriptParserListener): void {
		if (listener.enterMathMethodCall) {
			listener.enterMathMethodCall(this);
		}
	}
	// @Override
	public exitRule(listener: AScriptParserListener): void {
		if (listener.exitMathMethodCall) {
			listener.exitMathMethodCall(this);
		}
	}
	// @Override
	public accept<Result>(visitor: AScriptParserVisitor<Result>): Result {
		if (visitor.visitMathMethodCall) {
			return visitor.visitMathMethodCall(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class NativeFunctionCallContext extends ParserRuleContext {
	public KW_NATIVE_FUNCTION(): TerminalNode { return this.getToken(AScriptParser.KW_NATIVE_FUNCTION, 0); }
	public L_PAREN(): TerminalNode { return this.getToken(AScriptParser.L_PAREN, 0); }
	public R_PAREN(): TerminalNode { return this.getToken(AScriptParser.R_PAREN, 0); }
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return AScriptParser.RULE_nativeFunctionCall; }
	// @Override
	public enterRule(listener: AScriptParserListener): void {
		if (listener.enterNativeFunctionCall) {
			listener.enterNativeFunctionCall(this);
		}
	}
	// @Override
	public exitRule(listener: AScriptParserListener): void {
		if (listener.exitNativeFunctionCall) {
			listener.exitNativeFunctionCall(this);
		}
	}
	// @Override
	public accept<Result>(visitor: AScriptParserVisitor<Result>): Result {
		if (visitor.visitNativeFunctionCall) {
			return visitor.visitNativeFunctionCall(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class NativeInstanceMethodCallContext extends ParserRuleContext {
	public IDENTIFIER(): TerminalNode { return this.getToken(AScriptParser.IDENTIFIER, 0); }
	public PERIOD(): TerminalNode { return this.getToken(AScriptParser.PERIOD, 0); }
	public KW_NATIVE_INSTANCE_METHOD(): TerminalNode { return this.getToken(AScriptParser.KW_NATIVE_INSTANCE_METHOD, 0); }
	public L_PAREN(): TerminalNode { return this.getToken(AScriptParser.L_PAREN, 0); }
	public R_PAREN(): TerminalNode { return this.getToken(AScriptParser.R_PAREN, 0); }
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return AScriptParser.RULE_nativeInstanceMethodCall; }
	// @Override
	public enterRule(listener: AScriptParserListener): void {
		if (listener.enterNativeInstanceMethodCall) {
			listener.enterNativeInstanceMethodCall(this);
		}
	}
	// @Override
	public exitRule(listener: AScriptParserListener): void {
		if (listener.exitNativeInstanceMethodCall) {
			listener.exitNativeInstanceMethodCall(this);
		}
	}
	// @Override
	public accept<Result>(visitor: AScriptParserVisitor<Result>): Result {
		if (visitor.visitNativeInstanceMethodCall) {
			return visitor.visitNativeInstanceMethodCall(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class CustomInstanceMethodCallContext extends ParserRuleContext {
	public IDENTIFIER(): TerminalNode[];
	public IDENTIFIER(i: number): TerminalNode;
	public IDENTIFIER(i?: number): TerminalNode | TerminalNode[] {
		if (i === undefined) {
			return this.getTokens(AScriptParser.IDENTIFIER);
		} else {
			return this.getToken(AScriptParser.IDENTIFIER, i);
		}
	}
	public PERIOD(): TerminalNode { return this.getToken(AScriptParser.PERIOD, 0); }
	public L_PAREN(): TerminalNode { return this.getToken(AScriptParser.L_PAREN, 0); }
	public R_PAREN(): TerminalNode { return this.getToken(AScriptParser.R_PAREN, 0); }
	public expression(): ExpressionContext[];
	public expression(i: number): ExpressionContext;
	public expression(i?: number): ExpressionContext | ExpressionContext[] {
		if (i === undefined) {
			return this.getRuleContexts(ExpressionContext);
		} else {
			return this.getRuleContext(i, ExpressionContext);
		}
	}
	public COMMA(): TerminalNode[];
	public COMMA(i: number): TerminalNode;
	public COMMA(i?: number): TerminalNode | TerminalNode[] {
		if (i === undefined) {
			return this.getTokens(AScriptParser.COMMA);
		} else {
			return this.getToken(AScriptParser.COMMA, i);
		}
	}
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return AScriptParser.RULE_customInstanceMethodCall; }
	// @Override
	public enterRule(listener: AScriptParserListener): void {
		if (listener.enterCustomInstanceMethodCall) {
			listener.enterCustomInstanceMethodCall(this);
		}
	}
	// @Override
	public exitRule(listener: AScriptParserListener): void {
		if (listener.exitCustomInstanceMethodCall) {
			listener.exitCustomInstanceMethodCall(this);
		}
	}
	// @Override
	public accept<Result>(visitor: AScriptParserVisitor<Result>): Result {
		if (visitor.visitCustomInstanceMethodCall) {
			return visitor.visitCustomInstanceMethodCall(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class PrimaryNonKeyExpressionContext extends ParserRuleContext {
	public CAST_OPERATOR(): TerminalNode | undefined { return this.tryGetToken(AScriptParser.CAST_OPERATOR, 0); }
	public DOUBLE_LITERAL(): TerminalNode | undefined { return this.tryGetToken(AScriptParser.DOUBLE_LITERAL, 0); }
	public INT_LITERAL(): TerminalNode | undefined { return this.tryGetToken(AScriptParser.INT_LITERAL, 0); }
	public literal(): LiteralContext | undefined {
		return this.tryGetRuleContext(0, LiteralContext);
	}
	public IDENTIFIER(): TerminalNode | undefined { return this.tryGetToken(AScriptParser.IDENTIFIER, 0); }
	public L_PAREN(): TerminalNode | undefined { return this.tryGetToken(AScriptParser.L_PAREN, 0); }
	public expression(): ExpressionContext | undefined {
		return this.tryGetRuleContext(0, ExpressionContext);
	}
	public R_PAREN(): TerminalNode | undefined { return this.tryGetToken(AScriptParser.R_PAREN, 0); }
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return AScriptParser.RULE_primaryNonKeyExpression; }
	// @Override
	public enterRule(listener: AScriptParserListener): void {
		if (listener.enterPrimaryNonKeyExpression) {
			listener.enterPrimaryNonKeyExpression(this);
		}
	}
	// @Override
	public exitRule(listener: AScriptParserListener): void {
		if (listener.exitPrimaryNonKeyExpression) {
			listener.exitPrimaryNonKeyExpression(this);
		}
	}
	// @Override
	public accept<Result>(visitor: AScriptParserVisitor<Result>): Result {
		if (visitor.visitPrimaryNonKeyExpression) {
			return visitor.visitPrimaryNonKeyExpression(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class VarDeclarationContext extends ParserRuleContext {
	public TYPE(): TerminalNode { return this.getToken(AScriptParser.TYPE, 0); }
	public IDENTIFIER(): TerminalNode { return this.getToken(AScriptParser.IDENTIFIER, 0); }
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return AScriptParser.RULE_varDeclaration; }
	// @Override
	public enterRule(listener: AScriptParserListener): void {
		if (listener.enterVarDeclaration) {
			listener.enterVarDeclaration(this);
		}
	}
	// @Override
	public exitRule(listener: AScriptParserListener): void {
		if (listener.exitVarDeclaration) {
			listener.exitVarDeclaration(this);
		}
	}
	// @Override
	public accept<Result>(visitor: AScriptParserVisitor<Result>): Result {
		if (visitor.visitVarDeclaration) {
			return visitor.visitVarDeclaration(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class LiteralContext extends ParserRuleContext {
	public DOUBLE_LITERAL(): TerminalNode | undefined { return this.tryGetToken(AScriptParser.DOUBLE_LITERAL, 0); }
	public INT_LITERAL(): TerminalNode | undefined { return this.tryGetToken(AScriptParser.INT_LITERAL, 0); }
	public BOOLEAN_LITERAL(): TerminalNode | undefined { return this.tryGetToken(AScriptParser.BOOLEAN_LITERAL, 0); }
	public STRING_LITERAL(): TerminalNode | undefined { return this.tryGetToken(AScriptParser.STRING_LITERAL, 0); }
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return AScriptParser.RULE_literal; }
	// @Override
	public enterRule(listener: AScriptParserListener): void {
		if (listener.enterLiteral) {
			listener.enterLiteral(this);
		}
	}
	// @Override
	public exitRule(listener: AScriptParserListener): void {
		if (listener.exitLiteral) {
			listener.exitLiteral(this);
		}
	}
	// @Override
	public accept<Result>(visitor: AScriptParserVisitor<Result>): Result {
		if (visitor.visitLiteral) {
			return visitor.visitLiteral(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class StatementContext extends ParserRuleContext {
	public SEMICOLON(): TerminalNode | undefined { return this.tryGetToken(AScriptParser.SEMICOLON, 0); }
	public labeledStatement(): LabeledStatementContext | undefined {
		return this.tryGetRuleContext(0, LabeledStatementContext);
	}
	public COMMENT(): TerminalNode | undefined { return this.tryGetToken(AScriptParser.COMMENT, 0); }
	public statementBlockBegin(): StatementBlockBeginContext | undefined {
		return this.tryGetRuleContext(0, StatementBlockBeginContext);
	}
	public statementBlockEnd(): StatementBlockEndContext | undefined {
		return this.tryGetRuleContext(0, StatementBlockEndContext);
	}
	public rBlock(): RBlockContext | undefined {
		return this.tryGetRuleContext(0, RBlockContext);
	}
	public assignment(): AssignmentContext | undefined {
		return this.tryGetRuleContext(0, AssignmentContext);
	}
	public ifStatement(): IfStatementContext | undefined {
		return this.tryGetRuleContext(0, IfStatementContext);
	}
	public elseStatement(): ElseStatementContext | undefined {
		return this.tryGetRuleContext(0, ElseStatementContext);
	}
	public whileStatement(): WhileStatementContext | undefined {
		return this.tryGetRuleContext(0, WhileStatementContext);
	}
	public continueStatement(): ContinueStatementContext | undefined {
		return this.tryGetRuleContext(0, ContinueStatementContext);
	}
	public printStatement(): PrintStatementContext | undefined {
		return this.tryGetRuleContext(0, PrintStatementContext);
	}
	public incrementStatement(): IncrementStatementContext | undefined {
		return this.tryGetRuleContext(0, IncrementStatementContext);
	}
	public decrementStatement(): DecrementStatementContext | undefined {
		return this.tryGetRuleContext(0, DecrementStatementContext);
	}
	public returnStatement(): ReturnStatementContext | undefined {
		return this.tryGetRuleContext(0, ReturnStatementContext);
	}
	public customInstanceMethodCall(): CustomInstanceMethodCallContext | undefined {
		return this.tryGetRuleContext(0, CustomInstanceMethodCallContext);
	}
	public doubleVector(): DoubleVectorContext | undefined {
		return this.tryGetRuleContext(0, DoubleVectorContext);
	}
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return AScriptParser.RULE_statement; }
	// @Override
	public enterRule(listener: AScriptParserListener): void {
		if (listener.enterStatement) {
			listener.enterStatement(this);
		}
	}
	// @Override
	public exitRule(listener: AScriptParserListener): void {
		if (listener.exitStatement) {
			listener.exitStatement(this);
		}
	}
	// @Override
	public accept<Result>(visitor: AScriptParserVisitor<Result>): Result {
		if (visitor.visitStatement) {
			return visitor.visitStatement(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class LabeledStatementContext extends ParserRuleContext {
	public IDENTIFIER(): TerminalNode { return this.getToken(AScriptParser.IDENTIFIER, 0); }
	public COLON(): TerminalNode { return this.getToken(AScriptParser.COLON, 0); }
	public statement(): StatementContext {
		return this.getRuleContext(0, StatementContext);
	}
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return AScriptParser.RULE_labeledStatement; }
	// @Override
	public enterRule(listener: AScriptParserListener): void {
		if (listener.enterLabeledStatement) {
			listener.enterLabeledStatement(this);
		}
	}
	// @Override
	public exitRule(listener: AScriptParserListener): void {
		if (listener.exitLabeledStatement) {
			listener.exitLabeledStatement(this);
		}
	}
	// @Override
	public accept<Result>(visitor: AScriptParserVisitor<Result>): Result {
		if (visitor.visitLabeledStatement) {
			return visitor.visitLabeledStatement(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class ImportStatementContext extends ParserRuleContext {
	public KW_IMPORT(): TerminalNode { return this.getToken(AScriptParser.KW_IMPORT, 0); }
	public fullyQualifiedClassName(): FullyQualifiedClassNameContext {
		return this.getRuleContext(0, FullyQualifiedClassNameContext);
	}
	public KW_AS(): TerminalNode { return this.getToken(AScriptParser.KW_AS, 0); }
	public IMPORT_ID(): TerminalNode { return this.getToken(AScriptParser.IMPORT_ID, 0); }
	public IMPORT_SEMICOLON(): TerminalNode { return this.getToken(AScriptParser.IMPORT_SEMICOLON, 0); }
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return AScriptParser.RULE_importStatement; }
	// @Override
	public enterRule(listener: AScriptParserListener): void {
		if (listener.enterImportStatement) {
			listener.enterImportStatement(this);
		}
	}
	// @Override
	public exitRule(listener: AScriptParserListener): void {
		if (listener.exitImportStatement) {
			listener.exitImportStatement(this);
		}
	}
	// @Override
	public accept<Result>(visitor: AScriptParserVisitor<Result>): Result {
		if (visitor.visitImportStatement) {
			return visitor.visitImportStatement(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class FullyQualifiedClassNameContext extends ParserRuleContext {
	public IMPORT_ID(): TerminalNode[];
	public IMPORT_ID(i: number): TerminalNode;
	public IMPORT_ID(i?: number): TerminalNode | TerminalNode[] {
		if (i === undefined) {
			return this.getTokens(AScriptParser.IMPORT_ID);
		} else {
			return this.getToken(AScriptParser.IMPORT_ID, i);
		}
	}
	public IMPORT_PERIOD(): TerminalNode[];
	public IMPORT_PERIOD(i: number): TerminalNode;
	public IMPORT_PERIOD(i?: number): TerminalNode | TerminalNode[] {
		if (i === undefined) {
			return this.getTokens(AScriptParser.IMPORT_PERIOD);
		} else {
			return this.getToken(AScriptParser.IMPORT_PERIOD, i);
		}
	}
	public IMPORT_PARAMETERIZATION(): TerminalNode | undefined { return this.tryGetToken(AScriptParser.IMPORT_PARAMETERIZATION, 0); }
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return AScriptParser.RULE_fullyQualifiedClassName; }
	// @Override
	public enterRule(listener: AScriptParserListener): void {
		if (listener.enterFullyQualifiedClassName) {
			listener.enterFullyQualifiedClassName(this);
		}
	}
	// @Override
	public exitRule(listener: AScriptParserListener): void {
		if (listener.exitFullyQualifiedClassName) {
			listener.exitFullyQualifiedClassName(this);
		}
	}
	// @Override
	public accept<Result>(visitor: AScriptParserVisitor<Result>): Result {
		if (visitor.visitFullyQualifiedClassName) {
			return visitor.visitFullyQualifiedClassName(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class RBlockContext extends ParserRuleContext {
	public KW_R_BLOCK_BEGIN(): TerminalNode { return this.getToken(AScriptParser.KW_R_BLOCK_BEGIN, 0); }
	public KW_R_BLOCK_END(): TerminalNode { return this.getToken(AScriptParser.KW_R_BLOCK_END, 0); }
	public R_NEW_LINE(): TerminalNode[];
	public R_NEW_LINE(i: number): TerminalNode;
	public R_NEW_LINE(i?: number): TerminalNode | TerminalNode[] {
		if (i === undefined) {
			return this.getTokens(AScriptParser.R_NEW_LINE);
		} else {
			return this.getToken(AScriptParser.R_NEW_LINE, i);
		}
	}
	public R_BLOCK_CHAR(): TerminalNode[];
	public R_BLOCK_CHAR(i: number): TerminalNode;
	public R_BLOCK_CHAR(i?: number): TerminalNode | TerminalNode[] {
		if (i === undefined) {
			return this.getTokens(AScriptParser.R_BLOCK_CHAR);
		} else {
			return this.getToken(AScriptParser.R_BLOCK_CHAR, i);
		}
	}
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return AScriptParser.RULE_rBlock; }
	// @Override
	public enterRule(listener: AScriptParserListener): void {
		if (listener.enterRBlock) {
			listener.enterRBlock(this);
		}
	}
	// @Override
	public exitRule(listener: AScriptParserListener): void {
		if (listener.exitRBlock) {
			listener.exitRBlock(this);
		}
	}
	// @Override
	public accept<Result>(visitor: AScriptParserVisitor<Result>): Result {
		if (visitor.visitRBlock) {
			return visitor.visitRBlock(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class StatementBlockBeginContext extends ParserRuleContext {
	public L_BRACE(): TerminalNode { return this.getToken(AScriptParser.L_BRACE, 0); }
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return AScriptParser.RULE_statementBlockBegin; }
	// @Override
	public enterRule(listener: AScriptParserListener): void {
		if (listener.enterStatementBlockBegin) {
			listener.enterStatementBlockBegin(this);
		}
	}
	// @Override
	public exitRule(listener: AScriptParserListener): void {
		if (listener.exitStatementBlockBegin) {
			listener.exitStatementBlockBegin(this);
		}
	}
	// @Override
	public accept<Result>(visitor: AScriptParserVisitor<Result>): Result {
		if (visitor.visitStatementBlockBegin) {
			return visitor.visitStatementBlockBegin(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class StatementBlockEndContext extends ParserRuleContext {
	public R_BRACE(): TerminalNode { return this.getToken(AScriptParser.R_BRACE, 0); }
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return AScriptParser.RULE_statementBlockEnd; }
	// @Override
	public enterRule(listener: AScriptParserListener): void {
		if (listener.enterStatementBlockEnd) {
			listener.enterStatementBlockEnd(this);
		}
	}
	// @Override
	public exitRule(listener: AScriptParserListener): void {
		if (listener.exitStatementBlockEnd) {
			listener.exitStatementBlockEnd(this);
		}
	}
	// @Override
	public accept<Result>(visitor: AScriptParserVisitor<Result>): Result {
		if (visitor.visitStatementBlockEnd) {
			return visitor.visitStatementBlockEnd(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class IfStatementContext extends ParserRuleContext {
	public KW_IF(): TerminalNode { return this.getToken(AScriptParser.KW_IF, 0); }
	public L_PAREN(): TerminalNode { return this.getToken(AScriptParser.L_PAREN, 0); }
	public expression(): ExpressionContext {
		return this.getRuleContext(0, ExpressionContext);
	}
	public R_PAREN(): TerminalNode { return this.getToken(AScriptParser.R_PAREN, 0); }
	public statement(): StatementContext | undefined {
		return this.tryGetRuleContext(0, StatementContext);
	}
	public statementBlockBegin(): StatementBlockBeginContext | undefined {
		return this.tryGetRuleContext(0, StatementBlockBeginContext);
	}
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return AScriptParser.RULE_ifStatement; }
	// @Override
	public enterRule(listener: AScriptParserListener): void {
		if (listener.enterIfStatement) {
			listener.enterIfStatement(this);
		}
	}
	// @Override
	public exitRule(listener: AScriptParserListener): void {
		if (listener.exitIfStatement) {
			listener.exitIfStatement(this);
		}
	}
	// @Override
	public accept<Result>(visitor: AScriptParserVisitor<Result>): Result {
		if (visitor.visitIfStatement) {
			return visitor.visitIfStatement(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class ElseStatementContext extends ParserRuleContext {
	public KW_ELSE(): TerminalNode { return this.getToken(AScriptParser.KW_ELSE, 0); }
	public statementBlockBegin(): StatementBlockBeginContext | undefined {
		return this.tryGetRuleContext(0, StatementBlockBeginContext);
	}
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return AScriptParser.RULE_elseStatement; }
	// @Override
	public enterRule(listener: AScriptParserListener): void {
		if (listener.enterElseStatement) {
			listener.enterElseStatement(this);
		}
	}
	// @Override
	public exitRule(listener: AScriptParserListener): void {
		if (listener.exitElseStatement) {
			listener.exitElseStatement(this);
		}
	}
	// @Override
	public accept<Result>(visitor: AScriptParserVisitor<Result>): Result {
		if (visitor.visitElseStatement) {
			return visitor.visitElseStatement(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class WhileStatementContext extends ParserRuleContext {
	public KW_WHILE(): TerminalNode { return this.getToken(AScriptParser.KW_WHILE, 0); }
	public L_PAREN(): TerminalNode { return this.getToken(AScriptParser.L_PAREN, 0); }
	public expression(): ExpressionContext {
		return this.getRuleContext(0, ExpressionContext);
	}
	public R_PAREN(): TerminalNode { return this.getToken(AScriptParser.R_PAREN, 0); }
	public statementBlockBegin(): StatementBlockBeginContext | undefined {
		return this.tryGetRuleContext(0, StatementBlockBeginContext);
	}
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return AScriptParser.RULE_whileStatement; }
	// @Override
	public enterRule(listener: AScriptParserListener): void {
		if (listener.enterWhileStatement) {
			listener.enterWhileStatement(this);
		}
	}
	// @Override
	public exitRule(listener: AScriptParserListener): void {
		if (listener.exitWhileStatement) {
			listener.exitWhileStatement(this);
		}
	}
	// @Override
	public accept<Result>(visitor: AScriptParserVisitor<Result>): Result {
		if (visitor.visitWhileStatement) {
			return visitor.visitWhileStatement(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class ReturnStatementContext extends ParserRuleContext {
	public KW_RETURN(): TerminalNode { return this.getToken(AScriptParser.KW_RETURN, 0); }
	public expression(): ExpressionContext {
		return this.getRuleContext(0, ExpressionContext);
	}
	public SEMICOLON(): TerminalNode { return this.getToken(AScriptParser.SEMICOLON, 0); }
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return AScriptParser.RULE_returnStatement; }
	// @Override
	public enterRule(listener: AScriptParserListener): void {
		if (listener.enterReturnStatement) {
			listener.enterReturnStatement(this);
		}
	}
	// @Override
	public exitRule(listener: AScriptParserListener): void {
		if (listener.exitReturnStatement) {
			listener.exitReturnStatement(this);
		}
	}
	// @Override
	public accept<Result>(visitor: AScriptParserVisitor<Result>): Result {
		if (visitor.visitReturnStatement) {
			return visitor.visitReturnStatement(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class IncrementStatementContext extends ParserRuleContext {
	public INCREMENT(): TerminalNode { return this.getToken(AScriptParser.INCREMENT, 0); }
	public expression(): ExpressionContext {
		return this.getRuleContext(0, ExpressionContext);
	}
	public SEMICOLON(): TerminalNode { return this.getToken(AScriptParser.SEMICOLON, 0); }
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return AScriptParser.RULE_incrementStatement; }
	// @Override
	public enterRule(listener: AScriptParserListener): void {
		if (listener.enterIncrementStatement) {
			listener.enterIncrementStatement(this);
		}
	}
	// @Override
	public exitRule(listener: AScriptParserListener): void {
		if (listener.exitIncrementStatement) {
			listener.exitIncrementStatement(this);
		}
	}
	// @Override
	public accept<Result>(visitor: AScriptParserVisitor<Result>): Result {
		if (visitor.visitIncrementStatement) {
			return visitor.visitIncrementStatement(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class DecrementStatementContext extends ParserRuleContext {
	public DECREMENT(): TerminalNode { return this.getToken(AScriptParser.DECREMENT, 0); }
	public expression(): ExpressionContext {
		return this.getRuleContext(0, ExpressionContext);
	}
	public SEMICOLON(): TerminalNode { return this.getToken(AScriptParser.SEMICOLON, 0); }
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return AScriptParser.RULE_decrementStatement; }
	// @Override
	public enterRule(listener: AScriptParserListener): void {
		if (listener.enterDecrementStatement) {
			listener.enterDecrementStatement(this);
		}
	}
	// @Override
	public exitRule(listener: AScriptParserListener): void {
		if (listener.exitDecrementStatement) {
			listener.exitDecrementStatement(this);
		}
	}
	// @Override
	public accept<Result>(visitor: AScriptParserVisitor<Result>): Result {
		if (visitor.visitDecrementStatement) {
			return visitor.visitDecrementStatement(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class ContinueStatementContext extends ParserRuleContext {
	public KW_CONTINUE(): TerminalNode { return this.getToken(AScriptParser.KW_CONTINUE, 0); }
	public SEMICOLON(): TerminalNode { return this.getToken(AScriptParser.SEMICOLON, 0); }
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return AScriptParser.RULE_continueStatement; }
	// @Override
	public enterRule(listener: AScriptParserListener): void {
		if (listener.enterContinueStatement) {
			listener.enterContinueStatement(this);
		}
	}
	// @Override
	public exitRule(listener: AScriptParserListener): void {
		if (listener.exitContinueStatement) {
			listener.exitContinueStatement(this);
		}
	}
	// @Override
	public accept<Result>(visitor: AScriptParserVisitor<Result>): Result {
		if (visitor.visitContinueStatement) {
			return visitor.visitContinueStatement(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class PrintStatementContext extends ParserRuleContext {
	public KW_PRINT(): TerminalNode { return this.getToken(AScriptParser.KW_PRINT, 0); }
	public L_PAREN(): TerminalNode { return this.getToken(AScriptParser.L_PAREN, 0); }
	public expression(): ExpressionContext {
		return this.getRuleContext(0, ExpressionContext);
	}
	public R_PAREN(): TerminalNode { return this.getToken(AScriptParser.R_PAREN, 0); }
	public SEMICOLON(): TerminalNode { return this.getToken(AScriptParser.SEMICOLON, 0); }
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return AScriptParser.RULE_printStatement; }
	// @Override
	public enterRule(listener: AScriptParserListener): void {
		if (listener.enterPrintStatement) {
			listener.enterPrintStatement(this);
		}
	}
	// @Override
	public exitRule(listener: AScriptParserListener): void {
		if (listener.exitPrintStatement) {
			listener.exitPrintStatement(this);
		}
	}
	// @Override
	public accept<Result>(visitor: AScriptParserVisitor<Result>): Result {
		if (visitor.visitPrintStatement) {
			return visitor.visitPrintStatement(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class DoubleVectorContext extends ParserRuleContext {
	public KW_DOUBLE_VECTOR(): TerminalNode { return this.getToken(AScriptParser.KW_DOUBLE_VECTOR, 0); }
	public L_PAREN(): TerminalNode { return this.getToken(AScriptParser.L_PAREN, 0); }
	public STRING_LITERAL(): TerminalNode { return this.getToken(AScriptParser.STRING_LITERAL, 0); }
	public R_PAREN(): TerminalNode { return this.getToken(AScriptParser.R_PAREN, 0); }
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return AScriptParser.RULE_doubleVector; }
	// @Override
	public enterRule(listener: AScriptParserListener): void {
		if (listener.enterDoubleVector) {
			listener.enterDoubleVector(this);
		}
	}
	// @Override
	public exitRule(listener: AScriptParserListener): void {
		if (listener.exitDoubleVector) {
			listener.exitDoubleVector(this);
		}
	}
	// @Override
	public accept<Result>(visitor: AScriptParserVisitor<Result>): Result {
		if (visitor.visitDoubleVector) {
			return visitor.visitDoubleVector(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


