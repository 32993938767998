import axios from 'axios';
import {
  createDefaultUserInfo,
  createDefaultUserState,
  createRecentWorkspace,
} from '../shared/constants';
import { UserState } from '../shared/dataTypes';
import { setActivePortfolioSelection, _setDefaultPortfolioSelection } from './ActionCreators';
import * as ActionTypes from './ActionTypes';
import { disconnectWS } from './configureStore';
import { _setDisplayFunds } from './ui/actionCreators';
import { cancelRefreshTimeout } from './UserProfileActionCreators';

const RECENT_WORKSPACE_COUNT = 5;
export const user = (state: UserState = createDefaultUserState(), action): UserState => {
  switch (action.type) {
    case ActionTypes.SUCCESSFUL_LOGIN: {
      return { ...state, loggedIn: true, ...action.payload };
    }
    case ActionTypes.SET_REFRESH_TIMEOUT_ID: {
      return { ...state, refreshTimeoutId: action.payload.refreshTimeoutId };
    }
    case ActionTypes.CLEAR_REFRESH_TIMEOUT_ID: {
      cancelRefreshTimeout(state.refreshTimeoutId);
      return { ...state, refreshTimeoutId: null };
    }
    case ActionTypes.FAILED_LOGIN: {
      cancelRefreshTimeout(state.refreshTimeoutId);
      delete axios.defaults.headers.common['authorization'];
      return {
        ...state,
        hasUserInfo: false,
        userInfo: createDefaultUserInfo(),
        loggedIn: false,
        tk: null,
        username: null,
        expiresIn: null,
        refreshTimeoutId: null,
      };
    }
    case ActionTypes.UPDATE_WORKSPACE_CURRENCY: {
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          userPreferences: {
            ...state.userInfo.userPreferences,
            selectedCurrency: action.payload.currency,
          },
        },
      };
    }
    case ActionTypes.UPDATE_PIN_WORKSPACE_DRAWER: {
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          userPreferences: {
            ...state.userInfo.userPreferences,
            pinWorkspaceDrawer: action.payload,
          },
        },
      };
    }
    case ActionTypes.UPDATE_CURRENCIES: {
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          serverConfigs: {
            ...state?.userInfo?.serverConfigs,
            currencies: action.payload,
          },
        },
      };
    }
    case ActionTypes.UPDATE_SELECTED_DATE_CONTEXT: {
      return { ...state, selectedDateContext: action.payload.dateContext };
    }
    case ActionTypes.UPDATE_DATE_CONTEXTS: {
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          serverConfigs: {
            ...state?.userInfo?.serverConfigs,
            dateContexts: action.payload,
          },
        },
      };
    }
    case ActionTypes.UPDATE_DEFAULT_WORKSPACE: {
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          userPreferences: {
            ...state.userInfo.userPreferences,
            defaultWorkspace: action.payload,
          },
        },
      };
    }
    case ActionTypes.USER_INFO: {
      const userInfo = action.payload;

      return {
        ...state,
        hasUserInfo: true,
        userInfo: {
          ...state.userInfo,
          ...userInfo,
          serverConfigs: {
            ...state.userInfo?.serverConfigs,
            ...userInfo.serverConfigs,
          },
        },
      };
    }

    case ActionTypes.USER_LOGOUT: {
      disconnectWS();
      cancelRefreshTimeout(state.refreshTimeoutId);
      delete axios.defaults.headers.common['authorization'];
      return {
        ...state,
        hasUserInfo: false,
        userInfo: createDefaultUserInfo(),
        loggedIn: false,
        tk: null,
        username: null,
        expiresIn: null,
        refreshTimeoutId: null,
      };
    }
    case ActionTypes.USER_SESSION_EXPIRED_WARNING_TOGGLE: {
      return { ...state, sessionExpiredWarningToggle: !state.sessionExpiredWarningToggle };
    }
    case ActionTypes.ADD_FAVORITE: {
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          userPreferences: {
            ...state.userInfo.userPreferences,
            favoriteCharacteristics: (
              state.userInfo.userPreferences.favoriteCharacteristics || []
            ).concat([action.payload]),
          },
        },
      };
    }
    case ActionTypes.REMOVE_FAVORITE: {
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          userPreferences: {
            ...state.userInfo.userPreferences,
            favoriteCharacteristics: state.userInfo.userPreferences.favoriteCharacteristics.filter(
              f => f.charId !== action.payload.charId || f.modifier !== action.payload.modifier,
            ),
          },
        },
      };
    }
    case ActionTypes.ADD_FAVORITE_CUSTOM_GROUP: {
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          userPreferences: {
            ...state.userInfo.userPreferences,
            favoriteCustomGroupings: (
              state.userInfo.userPreferences.favoriteCustomGroupings || []
            ).concat([action.payload]),
          },
        },
      };
    }
    case ActionTypes.REMOVE_FAVORITE_CUSTOM_GROUP: {
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          userPreferences: {
            ...state.userInfo.userPreferences,
            favoriteCustomGroupings: state.userInfo.userPreferences.favoriteCustomGroupings.filter(
              f => f !== action.payload,
            ),
          },
        },
      };
    }
    case ActionTypes.UPDATE_SANDBOXES: {
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          userPreferences: {
            ...state.userInfo.userPreferences,
            sandboxes: action.payload,
          },
        },
      };
    }
    case ActionTypes.SET_MANAGE_CONTEXTS_PERMISSIONS: {
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          serverConfigs: {
            ...state.userInfo?.serverConfigs,
            contextPermissions: action.payload,
          },
        },
      };
    }
    case ActionTypes.UPDATE_USER_COMPONENT_PERMISSION: {
      return {
        ...state,
        userComponentPermission: action.payload,
      };
    }

    case ActionTypes.SET_ACTIVE_PORTFOLIO_SELECTION: {
      const {
        payload: activePortfolioSelection,
      }: ReturnType<typeof setActivePortfolioSelection> = action;

      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          userPreferences: {
            ...state.userInfo.userPreferences,
            activePortfolioSelection,
          },
        },
      };
    }

    case ActionTypes.SET_DISPLAY_FUNDS: {
      const { payload: displayFunds }: ReturnType<typeof _setDisplayFunds> = action;

      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          userPreferences: {
            ...state.userInfo.userPreferences,
            displayFunds,
          },
        },
      };
    }

    case ActionTypes.SET_DEFAULT_PORTFOLIO_SELECTION: {
      const {
        payload: defaultPortfolioSelection,
      }: ReturnType<typeof _setDefaultPortfolioSelection> = action;

      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          userPreferences: {
            ...state.userInfo.userPreferences,
            defaultPortfolioSelection,
          },
        },
      };
    }

    case ActionTypes.SET_EXPANDED_PORTFOLIOS:
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          userPreferences: {
            ...state.userInfo.userPreferences,
            expandedPortfolios: action.payload,
          },
        },
      };

    case ActionTypes.SET_DRAWER_WIDTHS:
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          userPreferences: {
            ...state.userInfo.userPreferences,
            drawerWidths: action.payload,
          },
        },
      };

    case ActionTypes.SET_EXPANDED_ADMIN_PORTFOLIOS:
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          userPreferences: {
            ...state.userInfo.userPreferences,
            expandedAdminPortfolios: action.payload,
          },
        },
      };

    case ActionTypes.SET_EXPANDED_REPORT_FOLDERS:
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          userPreferences: {
            ...state.userInfo.userPreferences,
            expandedReportFolders: action.payload,
          },
        },
      };

    case ActionTypes.SET_EXPANDED_WORKSPACE_FOLDERS:
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          userPreferences: {
            ...state.userInfo.userPreferences,
            expandedWorkspaceFolders: action.payload,
          },
        },
      };

    case ActionTypes.PUSH_RECENT_WORKSPACE: {
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          recentWorkspaces: [
            ...state.userInfo.recentWorkspaces,
            createRecentWorkspace(action.payload),
          ].slice(-RECENT_WORKSPACE_COUNT),
        },
      };
    }

    case ActionTypes.REMOVE_RECENT_WORKSPACE: {
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          recentWorkspaces: state.userInfo.recentWorkspaces.filter(
            ({ name }) => name !== action.payload,
          ),
        },
      };
    }

    case ActionTypes.CLEAR_RECENT_WORKSPACES: {
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          recentWorkspaces: [],
        },
      };
    }

    default:
      return state;
  }
};
