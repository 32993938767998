import { CharacteristicDrawerForm } from '../../components/metadata/characteristic-drawer/characteristicDrawerForm';
import {
  AccordionId,
  CharacteristicExpandedAccordion,
} from '../../components/metadata/characteristic-drawer/characteristicExpandedAccordion';
import { EntityTypes } from '../../model/metadata/entityTypes';
import { ColumnClasses } from '../../model/metadata/filters/columnClasses';
import { Sandbox } from '../../shared/dataTypes';

export enum MetadataActionType {
  SET_SELECTED_CHAR = 'metadata/setSelectedChar',
  SET_CHAR_TO_COPY = 'metadata/setCharToCopy',

  SET_ENTITY_TYPES = 'metadata/setEntityTypes',
  SET_COLUMN_CLASSES = 'metadata/setColumnClasses',

  SET_ACCORDION_EXPANDED = 'metadata/setAccordionExpanded',
  SET_ACCORDIONS_EXPANDED = 'metadata/setAccordionsExpanded',

  SET_SANDBOX = 'metadata/setSandbox',

  // TODO: SD-1893
  // SET_COLUMN_VISIBILITY = 'metadata/setColumnVisibility',
  // RESET_COLUMN_VISIBILITIES = 'metadata/resetColumnVisibilities',

  // TODO: SD-1894
  // ADD_FILTER = 'metadata/addFilter',
  // CLEAR_FILTERS = 'metadata/clearFilters',
}

export type MetadataAction =
  | ReturnType<typeof setSelectedChar>
  | ReturnType<typeof setCharToCopy>
  | ReturnType<typeof setEntityTypes>
  | ReturnType<typeof setAccordionExpanded>
  | ReturnType<typeof setAccordionsExpanded>
  | ReturnType<typeof setColumnClasses>
  | ReturnType<typeof setSandbox>;

export const setSelectedChar = (char: string) =>
  ({ type: MetadataActionType.SET_SELECTED_CHAR, payload: char } as const);

export const setCharToCopy = (char: CharacteristicDrawerForm) =>
  ({ type: MetadataActionType.SET_CHAR_TO_COPY, payload: char } as const);

export const setEntityTypes = (entityTypes: EntityTypes) =>
  ({ type: MetadataActionType.SET_ENTITY_TYPES, payload: entityTypes } as const);

export const setColumnClasses = (columnClasses: ColumnClasses) =>
  ({ type: MetadataActionType.SET_COLUMN_CLASSES, payload: columnClasses } as const);

export const setAccordionExpanded = (accordionId: AccordionId, expanded: boolean) =>
  ({
    type: MetadataActionType.SET_ACCORDION_EXPANDED,
    payload: { accordionId, expanded },
  } as const);

export const setAccordionsExpanded = (accordionsExpanded: CharacteristicExpandedAccordion) =>
  ({
    type: MetadataActionType.SET_ACCORDIONS_EXPANDED,
    payload: accordionsExpanded,
  } as const);

export const setSandbox = (sandbox: Sandbox | null) =>
  ({
    type: MetadataActionType.SET_SANDBOX,
    payload: sandbox,
  } as const);

/** TODO: WIP for SD-1893 */

// export const setColumnVisibility = (field: MetadataField, visible: boolean) =>
//   ({
//     type: MetadataActionType.SET_COLUMN_VISIBILITY,
//     payload: {
//       field,
//       visible,
//     },
//   } as const);

// export const resetColumnVisibilities = () =>
//   ({
//     type: MetadataActionType.RESET_COLUMN_VISIBILITIES,
//   } as const);

/** TODO: WIP for 1894 */

// export const addFilter = (filter: MetadataFilter) =>
//   ({
//     type: MetadataActionType.ADD_FILTER,
//     payload: filter,
//   } as const);

// export const clearFilters = () =>
//   ({
//     type: MetadataActionType.CLEAR_FILTERS,
//   } as const);
