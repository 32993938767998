import { Sandbox } from '../../shared/dataTypes';
import { AppThunk } from '../configureStore';
import { setFoliaSyntaxGuideOpen } from '../ui/actionCreators';
import { BookBenchmark, Mode } from './state';

export enum PositionDrawerActionType {
  OPEN = 'positionDrawer/open',
  CLOSE = 'positionDrawer/close',
}

export const openPositionDrawer = (
  sandbox: Sandbox,
  sequenceId: number,
  mode: Mode,
  positionToEdit?: BookBenchmark,
) =>
  ({
    type: PositionDrawerActionType.OPEN,
    payload: { sandbox, sequenceId, mode, positionToEdit },
  } as const);

const _closePositionDrawer = () => ({ type: PositionDrawerActionType.CLOSE } as const);

export const closePositionDrawer = (): AppThunk => dispatch => {
  dispatch(_closePositionDrawer());
  dispatch(setFoliaSyntaxGuideOpen(false));
};

export type PositionDrawerAction =
  | ReturnType<typeof openPositionDrawer>
  | ReturnType<typeof _closePositionDrawer>;
