import { Monaco } from '@monaco-editor/react';
import {
  ANTLRErrorListener,
  Lexer as AntlrLexer,
  Parser as AntlrParser,
  CharStreams,
  CommonToken,
  CommonTokenStream,
  ParserRuleContext,
  Vocabulary,
} from 'antlr4ts';
import { createEOFToken, createEOLToken } from '../SimpleToken';
import AbstractState from '../State';
import Token from '../Token';
import TokenFactory from '../TokenFactory';
import TokensProvider, { Newable } from '../TokensProvider';
import { EditorLanguage } from '../editorLanguage';
import ErrorLogger from '../errors/ErrorLogger';
import MonacoErrorCollector from '../errors/MonacoErrorCollector';
import StartColumnCollector from '../errors/StartColumnCollector';

interface BaseParser extends AntlrParser {
  // The name of the top-most rule in the .g4 parser.
  compilationUnit(): ParserRuleContext;
}

abstract class LanguageService<
  Language extends EditorLanguage = EditorLanguage,
  State extends AbstractState<Language> = AbstractState<Language>,
  Lexer extends AntlrLexer = AntlrLexer,
  Parser extends BaseParser = BaseParser
> {
  private readonly lexerLogger: ErrorLogger<unknown>;
  private readonly parserLogger: ErrorLogger<unknown>;

  constructor(
    public readonly language: Language,
    public readonly vocabulary: Vocabulary,
    protected readonly state: Newable<State>,
    protected readonly tokenFactory: TokenFactory<Language>,
    protected readonly tokensProvider: Newable<TokensProvider<Language, State>>,
    protected readonly lexer: Newable<Lexer>,
    protected readonly parser: Newable<Parser>,
    protected readonly debugLogging: boolean,
  ) {
    this.lexerLogger = ErrorLogger.fromTemplate(message => `${language} lexer error: ${message}`);
    this.parserLogger = ErrorLogger.fromTemplate(message => `${language} parser error: ${message}`);
  }

  register = (monaco: Monaco) => {
    // Register language
    monaco.languages.register({ id: this.language });

    // Register core syntax for highlighting/validation
    monaco.languages.setTokensProvider(this.language, this.getTokensProvider());
  };

  getParseTree = (input: string) => this.createParser(input).compilationUnit();
  getParseTreeString = (input: string) => {
    const parser = this.createParser(input);

    return parser.compilationUnit().toStringTree(parser.ruleNames);
  };

  getTokensProvider = () => new this.tokensProvider(this.tokenizeLine);

  tokenizeLine = (input: string, _state: State): Token<Language>[] => {
    const errorCollector = new StartColumnCollector();
    const lexer = this.createLexer(input);
    lexer.addErrorListener(errorCollector);

    const lexedTokens: Token<Language>[] = [];
    while (true) {
      const token = this.tokenFactory.fromAntlrToken(lexer.nextToken());
      if (this.debugLogging) {
        console.log(`token = ${token}`);
      }

      if (token === null || token.ruleName === TokenFactory.EOF_RULE_NAME) {
        break;
      }

      lexedTokens.push(token);
    }

    return [
      ...lexedTokens,
      ...errorCollector.getErrors().map(this.tokenFactory.createErrorToken),
    ].sort((a, b) => a.startIndex - b.startIndex);
  };

  getTokensByLine = (input: string) => {
    const state = new this.state();

    return input.split(/\r?\n/).map(line => this.tokenizeLine(line, state));
  };

  getSimpleTokens = (input: string) => [
    ...this.getTokensByLine(input).flatMap(tokens => [
      ...tokens.map(token => token.toSimpleToken()),
      createEOLToken(),
    ]),
    createEOFToken(),
  ];

  validate = (input: string) => {
    const errorCollector = new MonacoErrorCollector();
    const parser = this.createParser(input);
    parser.addErrorListener(errorCollector as ANTLRErrorListener<CommonToken>);
    const result = parser.compilationUnit();

    if (this.debugLogging) {
      console.log(result.toStringTree(parser));
    }

    const errors = errorCollector.getErrors();
    if (this.debugLogging) {
      console.log(`validate(errors = ${JSON.stringify(errors, null, 2)})`);
    }

    return errors;
  };

  protected createLexer = (input: string) => {
    const lexer = new this.lexer(CharStreams.fromString(input));
    lexer.removeErrorListeners();
    if (this.debugLogging) {
      lexer.addErrorListener(this.lexerLogger);
    }

    return lexer;
  };

  protected createParser = (input: string) => {
    const parser = new this.parser(new CommonTokenStream(this.createLexer(input)));
    parser.removeErrorListeners();
    if (this.debugLogging) {
      parser.addErrorListener(this.parserLogger);
    }

    return parser;
  };
}

export default LanguageService;
