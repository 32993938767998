import { EditorLanguage } from '../editorLanguage';
import TokenFactory from '../TokenFactory';
import { FoliaParser } from './antlr/FoliaParser';

class FoliaTokenFactory extends TokenFactory<EditorLanguage.FOLIA> {
  constructor() {
    super(EditorLanguage.FOLIA, FoliaParser.VOCABULARY);
  }
}

export default FoliaTokenFactory;
