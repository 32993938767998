import { EditorLanguage } from '../editorLanguage';
import State from '../State';
import { AScriptLexer } from './antlr/AScriptLexer';

class AScriptState extends State<EditorLanguage.ASCRIPT> {
  public static readonly MODES = AScriptLexer.modeNames;
  public static getModeIndex = (mode: string) => AScriptState.MODES.indexOf(mode);

  constructor(private mode: string | null = AScriptState.MODES[0]) {
    super(EditorLanguage.ASCRIPT);
  }

  getMode = () => this.mode;
  getModeIndex = () => AScriptState.getModeIndex(this.mode);
  setMode = (mode: string) => {
    this.mode = AScriptState.MODES.includes(mode) ? mode : null;
  };

  clone = () => new AScriptState(this.mode);
  equals = (other: AScriptState) => other.getMode() === this.mode;
}

export default AScriptState;
