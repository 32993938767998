import { createContext, useContext } from 'react';
import AuthService from './AuthService';

export const AuthContext = createContext<AuthService | null>(null);

// If `authService` is null, children of `<AuthProvider />`
// will not render, so this value can never be null
const useAuth = () => useContext(AuthContext)!;

export default useAuth;
