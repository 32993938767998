import { createDesignerWorkspacePayload } from '../model/designerWorkspacePayload';
import { WorkspacePayload } from '../shared/dataTypes';
import { setDesignerWorkspacePayload } from './ActionCreators';
import * as ActionTypes from './ActionTypes';

type Action = ReturnType<typeof setDesignerWorkspacePayload>;

export const designerWorkspacePayload = (
  state: WorkspacePayload = createDesignerWorkspacePayload(),
  action: Action,
): WorkspacePayload => {
  switch (action.type) {
    case ActionTypes.SET_DESIGNER_WORKSPACE_PAYLOAD:
      return action.payload;

    default:
      return state;
  }
};
