import { Characteristic, ReportDefinition } from '../../../shared/dataTypes';
import { DesignerSource } from './designerSource';

export interface DesignerPanelState {
  shouldRegenerate: boolean;
  isAutoGenerate: boolean;
  hasGenerated: boolean;
  allChars: Characteristic[];
  charToNickname?: Characteristic;
  originalDetachedReport?: ReportDefinition;
  // Props contingent on which route the Designer is opened by
  source: DesignerSource | null;
  sourceSequenceId: number | null;
  drillThrough: DrillThrough | null;
}

export interface DrillThrough {
  parentSequenceId: number;
  parentSelectedElements: string[];
  newTab: boolean;
}

export const createDefaultState = (): DesignerPanelState => ({
  isAutoGenerate: true,
  shouldRegenerate: false,
  hasGenerated: false,
  allChars: [],

  source: null,
  sourceSequenceId: null,
  drillThrough: null,
});
