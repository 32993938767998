import { createTheme, Theme, ThemeOptions } from '@mui/material/styles';
import { themeOptions as baseThemeOptions } from 'algo-react-dataviz';

// cf. https://mui.com/material-ui/migration/v5-style-changes/#%E2%9C%85-add-module-augmentation-for-defaulttheme-typescript
declare module '@mui/material/styles' {
  interface DefaultTheme extends Theme {}
}

export const themeOptions: ThemeOptions = {
  ...baseThemeOptions,

  zIndex: {
    mobileStepper: 1000,
    fab: 1050,
    speedDial: 1050,
    appBar: 1100,
    drawer: 1200,
    modal: 1300,
    snackbar: 1400,
    tooltip: 1500,
  },

  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          lineHeight: 1,
          backgroundColor: '#f7f5f7',
          color: '#000',
          fontSize: '16px',
        },
      },
    },
  },
};

export const theme = createTheme(themeOptions);
