import { FullyQualifiedClassNameContext } from './antlr/AScriptParser';
import { AScriptParserListener } from './antlr/AScriptParserListener';

class AScriptImportListener implements AScriptParserListener {
  private importedClasses: string[] = [];

  enterFullyQualifiedClassName = (context: FullyQualifiedClassNameContext) => {
    this.importedClasses.push(
      context.text.replace(context.IMPORT_PARAMETERIZATION()?.toString() ?? '', ''),
    );
  };

  getImportedClasses = () => this.importedClasses;
}

export default AScriptImportListener;
