import { FC, PropsWithChildren, useEffect } from 'react';
import useAScriptLanguage from '../ascript/useAScriptLanguage';
import { EditorLanguage } from '../editorLanguage';
import useFoliaLanguage from '../folia/useFoliaLanguage';
import useMemoizedMonaco from '../memoized-monaco/useMemoizedMonaco';
import theme, { THEME_ID } from '../theme/aggregate-theme';
import { LanguageServiceContext } from './useLanguageServices';

const LanguageServiceProvider: FC<PropsWithChildren> = props => {
  const monaco = useMemoizedMonaco();
  const aScriptLanguageService = useAScriptLanguage();
  const foliaLanguageService = useFoliaLanguage();

  useEffect(() => {
    if (!monaco) {
      return;
    }

    monaco.editor.defineTheme(THEME_ID, theme);
    monaco.editor.setTheme(THEME_ID);
  }, [monaco]);

  return (
    <LanguageServiceContext.Provider
      value={{
        [EditorLanguage.ASCRIPT]: aScriptLanguageService,
        [EditorLanguage.JAVA]: null,
        [EditorLanguage.FOLIA]: foliaLanguageService,
      }}
    >
      {props.children}
    </LanguageServiceContext.Provider>
  );
};
export default LanguageServiceProvider;
