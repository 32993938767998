import axios from 'axios';
import { enqueueSnackbar } from '../../../redux/ActionCreators';
import { AppThunk } from '../../../redux/configureStore';
import { importReport, isReportDefinition } from '../../../redux/ReportActionCreators';
import { clearWorkspace, importWorkspaceFile } from '../../../redux/WorkspaceActionCreators';
import { NotificationLevel } from '../../../shared/constants';
import {
  ParentToChildRelationship,
  SerializedWorkspace,
  WorkspaceData,
  WorkspaceTab,
} from '../../../shared/dataTypes';
import { nextSequenceId } from '../../../shared/utils';
import { baseUrl } from '../../shared/environment';

export const readerUtil = (
  file: File | Blob,
  onSuccess: (result: string | ArrayBuffer) => void,
  onError: (error: DOMException) => void,
) => {
  const reader = new FileReader();
  reader.onload = () => onSuccess(reader.result);
  reader.onerror = () => onError(reader.error);
  reader.readAsText(file);
};

export const importReportDataFromFile = (file: File): AppThunk => dispatch => {
  if (file.type !== 'application/json') {
    dispatch(enqueueSnackbar(NotificationLevel.ERROR, 'Invalid Workspace File type'));
    return;
  }
  const onSuccess = (result: string | ArrayBuffer) => {
    const parsed = JSON.parse(result.toString());
    if (Array.isArray(parsed) && parsed.length !== 1) {
      dispatch(
        enqueueSnackbar(
          NotificationLevel.ERROR,
          'Only a single report can be imported to workspace.',
        ),
      );
      return;
    }

    dispatch(importReport(Array.isArray(parsed) ? { ...parsed[0], path: null } : parsed));
  };
  const onError = (e: DOMException) =>
    dispatch(enqueueSnackbar(NotificationLevel.ERROR, `Error importing report: ${e.message}`));
  readerUtil(file, onSuccess, onError);
};

export const importReportDataFromClipboard = (): AppThunk => dispatch => {
  navigator.clipboard
    .readText()
    .then(text => {
      dispatch(importReport(JSON.parse(text)));
    })
    .catch(e => {
      dispatch(enqueueSnackbar(NotificationLevel.ERROR, `Error Pasting Report Data ${e}`));
    });
};

export const importWorkspaceDataFromFile = (file: File): AppThunk => dispatch => {
  if (file.type !== 'application/json') {
    dispatch(enqueueSnackbar(NotificationLevel.ERROR, 'Invalid Workspace File type'));
    return;
  }
  const onSuccess = (result: string | ArrayBuffer) => {
    dispatch(importWorkspace(result.toString()));
  };
  const onError = e =>
    dispatch(enqueueSnackbar(NotificationLevel.ERROR, `Error importing workspace: ${e}`));

  readerUtil(file, onSuccess, onError);
};

export const importWorkspaceDataFromClipboard = (): AppThunk => dispatch => {
  navigator.clipboard
    .readText()
    .then(text => dispatch(importWorkspace(text)))
    .catch(e => {
      dispatch(enqueueSnackbar(NotificationLevel.ERROR, `Error Pasting Workspace Definition ${e}`));
    });
};

export const isSerializedWorkspace = (data: any): data is SerializedWorkspace =>
  data.length === 2 && data[0].data && isReportDefinition(Object.values(data[1])[0]);

const importWorkspace = (workspaceDefinition: string): AppThunk => dispatch => {
  const parsed = JSON.parse(workspaceDefinition);

  // We can import two different workspace formats. One comes from exporting a live
  // workspace and one from exporting workspaces via the workspace drawer. The live
  // workspace will consist of an array containing the workspace data. If it's from
  // a drawer, then the workspace will consist of an array of many workspaces where
  // each workspace is an array similar to the live workspace. In the latter case,
  // the first workspace in the array is used.
  const data = parsed?.[0]?.data ? parsed : parsed?.[0]?.[0]?.data ? parsed[0] : null;

  if (!data || !isSerializedWorkspace(data)) {
    dispatch(enqueueSnackbar(NotificationLevel.ERROR, 'Invalid Workspace Definition'));
    return;
  }

  dispatch(clearWorkspace());

  axios.post(`${baseUrl}api/convertWorkspaceJson`, data).then(response => {
    dispatch(importWorkspaceFile(updateSequenceIds(response.data)));
  });
};

const updateSequenceIds = (serializedWorkspace: SerializedWorkspace): SerializedWorkspace => {
  const [
    { data, parentToChildRelationship, selectedTabIndex },
    reportDefinitions,
  ] = serializedWorkspace;

  const seqIdMapper: { [oldId: string]: number } = {};

  const newReportDefinitions: typeof reportDefinitions = {};

  // go thru all report definitions and remap sequenceIds to new ones while keeping track of the mapping
  Object.entries(reportDefinitions).forEach(([sequenceId, reportDefinition]) => {
    seqIdMapper[sequenceId] = nextSequenceId();
    newReportDefinitions[seqIdMapper[sequenceId]] = reportDefinition;
  });

  // now go thru all tabs and create new workspace data with mapped sequenceIds
  const newWorkspaceData: WorkspaceData = { ...data, tabs: {} };
  Object.entries(data.tabs).forEach(([tabId, tab]) => {
    const newTab: WorkspaceTab = { ...tab, reports: {} };
    Object.entries(tab.reports).forEach(([sequenceId, workspacePayload]) => {
      newTab.reports[seqIdMapper[sequenceId]] = {
        ...workspacePayload,
        sequenceId: seqIdMapper[workspacePayload.sequenceId],
        parentSequenceId: seqIdMapper[workspacePayload.parentSequenceId],
      };
    });
    newWorkspaceData.tabs[tabId] = newTab;
  });

  // and then go thru all parent to child relationships and create new one with mapped sequenceIds
  const newParentToChildRelationship: ParentToChildRelationship = {};
  Object.entries(parentToChildRelationship).forEach(([sequenceId, children]) => {
    newParentToChildRelationship[seqIdMapper[sequenceId]] = children.map(
      oldSeqId => seqIdMapper[oldSeqId],
    );
  });

  return [
    {
      data: newWorkspaceData,
      parentToChildRelationship: newParentToChildRelationship,
      selectedTabIndex,
    },
    newReportDefinitions,
  ];
};
