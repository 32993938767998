import { FC } from 'react';
import { Route, Switch } from 'react-router-dom';
import useNormalizedLocation from '../../../shared/useNormalizedLocation';
import MainComponent from '../../main/Main';
import {
  uriLoginCallback,
  uriLogout,
  uriLogoutCallback,
  uriRoot,
  uriSilentRenew,
} from '../auth/authConfig';
import Callback from './Callback';
import Logout from './Logout';
import LogoutCallback from './LogoutCallback';
import PrivateRoute from './PrivateRoute';
import SilentRenew from './SilentRenew';

const OidcRoutes: FC = () => {
  const location = useNormalizedLocation();

  return (
    <Switch {...{ location }}>
      <Route
        exact
        path={uriLoginCallback.path}
        render={({ location }) => <Callback url={`${location.pathname}${location.search}`} />}
      />
      <Route exact path={uriLogout.path} component={Logout} />
      <Route exact path={uriLogoutCallback.path} component={LogoutCallback} />
      <Route exact path={uriSilentRenew.path} component={SilentRenew} />
      <PrivateRoute path={uriRoot.path} component={MainComponent} />
    </Switch>
  );
};

export default OidcRoutes;
