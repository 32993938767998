import { FC, PropsWithChildren, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { setupLoggedInUser } from '../../../redux/UserProfileActionCreators';
import AuthService from './AuthService';
import { AuthContext } from './useAuth';

const AuthProvider: FC<PropsWithChildren> = props => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isInitialized, setIsInitialized] = useState(false);
  const authServiceRef = useRef<AuthService | null>(null);

  useEffect(() => {
    if (authServiceRef.current === null) {
      setIsInitialized(true);
      authServiceRef.current = new AuthService(
        history,
        (token, username, oidcLogout, expiresIn) => {
          dispatch(setupLoggedInUser(token, username, oidcLogout, expiresIn));
        },
      );
    }
  }, [dispatch, history]);

  // Re-evaluated exactly once when `isInitialized` is set
  const value = useMemo(() => (isInitialized ? authServiceRef.current : null), [isInitialized]);

  return (
    <AuthContext.Provider {...{ value }}>
      {isInitialized ? props.children : null}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
