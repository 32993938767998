import { FC } from 'react';
import useAuth from '../auth/useAuth';
import LoadingStub from './LoadingStub';

const Logout: FC = () => {
  useAuth().oidcLogout();

  return <LoadingStub />;
};

export default Logout;
