import { ContentPage } from '../../components/main/PageContent/contentPage';
import { ChangeWorkspaceModalStatus } from './changeWorkspaceModalStatus';
import { OverwriteConfirmationStatus } from './overwriteConfirmationStatus';

export interface UiState {
  activePage: ContentPage;
  intendedPage: ContentPage | null;
  previousPage: ContentPage | null;

  overwriteConfirmationModalStatus: OverwriteConfirmationStatus | null;
  changeWorkspaceModalStatus: ChangeWorkspaceModalStatus | null;
  closeDesignerModalOpen: boolean;

  isAboutOpen: boolean;
  isRemoteConsoleOpen: boolean;
  remoteConsoleFocus: () => void | null;
  pendingCsvExports: string[];
  isFoliaSyntaxGuideOpen: boolean;
}

export const DEFAULT_CONTENT_PAGE = ContentPage.HOME;
export const createDefaultUiState = (): UiState => ({
  activePage: DEFAULT_CONTENT_PAGE,
  intendedPage: null,
  previousPage: null,

  overwriteConfirmationModalStatus: null,
  changeWorkspaceModalStatus: null,
  closeDesignerModalOpen: false,

  isAboutOpen: false,
  isRemoteConsoleOpen: false,
  remoteConsoleFocus: null,
  pendingCsvExports: [],
  isFoliaSyntaxGuideOpen: false,
});
