import { FC, useEffect, useState } from 'react';
import {
  Area,
  AreaChart,
  CartesianGrid,
  Tooltip,
  XAxis,
  XAxisProps,
  YAxis,
  YAxisProps,
} from 'recharts';
import { BaseAxisProps } from 'recharts/types/util/types';
import { DataConverters } from '../shared/dataConverters';
import { ReportConvertedData, ReportProps } from '../shared/dataTypes';
import { getExtrema } from './helpers/chartingHelpers';

const XYAreaChartViz: FC<ReportProps> = props => {
  const [reportConvertedData, setReportConvertedData] = useState<ReportConvertedData>();

  useEffect(() => setReportConvertedData(DataConverters.AREA_CHART(props.reportRawData)), [
    props.reportRawData,
  ]);

  if (props.width <= 0 || props.height <= 0 || !reportConvertedData) {
    return null;
  }

  const dataKeys = {
    independent: reportConvertedData.groupingHeader.name,
    dependent: reportConvertedData.headers[0],
  };

  const areaData = reportConvertedData.rows;

  const gradientOffset = () => {
    const [dataMin, dataMax] = getExtrema(areaData.map(datum => datum[dataKeys.dependent]));

    if (dataMax <= 0) {
      return 0;
    } else if (dataMin >= 0) {
      return 1;
    } else {
      return dataMax / (dataMax - dataMin);
    }
  };

  const off = gradientOffset();

  const axisProps: Record<'independent' | 'dependent', BaseAxisProps> = {
    independent: {
      type: 'number',
      dataKey: dataKeys.independent,
      domain: getExtrema(areaData.map(datum => datum[dataKeys.independent])),
    },
    dependent: {
      type: 'number',
      dataKey: dataKeys.dependent,
    },
  };

  const { xAxisProps, yAxisProps } = {
    xAxisProps: (!props.isInverted ? axisProps.independent : axisProps.dependent) as XAxisProps,
    yAxisProps: (!props.isInverted ? axisProps.dependent : axisProps.independent) as YAxisProps,
  };

  return (
    <AreaChart
      layout={props.isInverted ? 'vertical' : 'horizontal'}
      width={props.width}
      height={props.height}
      data={areaData}
      margin={{ left: 30 }}
      onClick={event =>
        event &&
        props.onElementClick(
          event.activePayload[0].payload[`${event.activePayload[0].name}rowcol`],
        )(event)
      }
    >
      <CartesianGrid strokeDasharray='3 3' />
      <XAxis {...xAxisProps} />
      <YAxis {...yAxisProps} />
      <Tooltip formatter={(value, name, props) => props.payload[`${name}Formatted`]} />
      <defs>
        <linearGradient id='splitColor' x1='0' y1='0' x2='0' y2='1'>
          <stop offset={off} stopColor='green' stopOpacity={1} />
          <stop offset={off} stopColor='red' stopOpacity={1} />
        </linearGradient>
      </defs>
      <Area type='monotone' dataKey={dataKeys.dependent} stroke='#000' fill='url(#splitColor)' />
    </AreaChart>
  );
};

export default XYAreaChartViz;
