import { DrawerType } from '../shared/constants';
import { FolderListRow } from '../shared/dataTypes';
import {
  closeFolderDrawer,
  openFolderDrawer,
  setApplyButtonDisabled,
  setSelectedFolderItem,
} from './ActionCreators';
import * as ActionTypes from './ActionTypes';

export interface FolderDrawerState {
  active: boolean;

  type?: DrawerType;
  previousType?: DrawerType;
  // For preserving anchor position upon close

  selectedElements?: string[];
  sequenceId?: number;
  newTab?: boolean;
  drillThrough?: boolean;
  selectedFolderItem: FolderListRow | null;
  applyButtonDisabled: boolean;
}

const createDefaultState = (): FolderDrawerState => ({
  active: false,
  selectedFolderItem: null,
  applyButtonDisabled: false,
});

type Action =
  | ReturnType<typeof openFolderDrawer>
  | ReturnType<typeof closeFolderDrawer>
  | ReturnType<typeof setSelectedFolderItem>
  | ReturnType<typeof setApplyButtonDisabled>;

export const folderDrawer = (state = createDefaultState(), action: Action): FolderDrawerState => {
  switch (action.type) {
    case ActionTypes.OPEN_FOLDER_DRAWER:
      return {
        ...createDefaultState(),
        ...action.payload,
        active: true,
      };

    case ActionTypes.CLOSE_FOLDER_DRAWER:
      return {
        ...createDefaultState(),
        previousType: state.type,
      };

    case ActionTypes.SET_SELECTED_FOLDER_ITEM:
      return { ...state, selectedFolderItem: action.payload };

    case ActionTypes.SET_APPLY_BUTTON_DISABLED:
      return { ...state, applyButtonDisabled: action.payload };

    default:
      return state;
  }
};
