import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from '@mui/material';
import { RoundedButton } from 'algo-react-dataviz';
import axios from 'axios';
import { Formik, FormikState } from 'formik';
import { FC, useEffect, useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { enqueueSnackbar } from '../../redux/ActionCreators';
import { NotificationLevel } from '../../shared/constants';
import { baseUrl } from '../shared/environment';
import PasswordAdornment from './PasswordAdornment';
import './change-password-prompt.scss';

interface BaseProps {
  open: boolean;
  closeDialog: () => void;
  dialogTitle: string;
}

const mapDispatchToProps = { enqueueSnackbar };

const connector = connect(undefined, mapDispatchToProps);
type Props = ConnectedProps<typeof connector> & BaseProps;

const ChangePasswordPrompt: FC<Props> = ({ open, closeDialog, dialogTitle, enqueueSnackbar }) => {
  const [serverError, setServerError] = useState<string>(null);
  const [showPass, setShowPass] = useState(false);
  const [showNewPass, setShowNewPass] = useState(false);
  const [showVerifyPass, setShowVerifyPass] = useState(false);

  useEffect(() => {
    if (!open) {
      setServerError(null);
    }
  }, [open]);

  const changePassword = async (
    currentPassword: string,
    newPassword: string,
    resetForm: (
      nextState?: Partial<
        FormikState<{
          currentPassword: string;
          newPassword: string;
          newPasswordVerify: string;
        }>
      >,
    ) => void,
  ) => {
    try {
      await axios.post(`${baseUrl}api/changePassword`, {
        currentPassword,
        newPassword,
      });
      enqueueSnackbar(NotificationLevel.SUCCESS, 'Password changed successfully');
      closeDialog();
      resetForm({});
    } catch (error) {
      enqueueSnackbar(NotificationLevel.ERROR, 'Failed to change password');
      setServerError('Failed to change password. Try again.');
    }
  };

  return (
    <Formik
      initialValues={{ currentPassword: '', newPassword: '', newPasswordVerify: '' }}
      validate={values => {
        const errors: any = {};
        if (!values.currentPassword || values.currentPassword === '') {
          errors.currentPassword = 'Required';
        }
        if (!values.newPassword || values.newPassword === '') {
          errors.newPassword = 'Required';
        }
        if (!values.newPasswordVerify || values.newPasswordVerify === '') {
          errors.newPasswordVerify = 'Required';
        }

        if (
          values.currentPassword &&
          values.currentPassword !== '' &&
          values.newPassword &&
          values.newPassword !== '' &&
          values.currentPassword === values.newPassword
        ) {
          errors.newPassword = 'New password cannot be the same as current password';
        }
        if (
          values.newPassword &&
          values.newPassword !== '' &&
          values.newPasswordVerify &&
          values.newPasswordVerify !== '' &&
          values.newPassword !== values.newPasswordVerify
        ) {
          errors.newPasswordVerify = 'Password fields do not match';
        }

        return errors;
      }}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        changePassword(values.currentPassword, values.newPassword, resetForm);
        setSubmitting(false);
      }}
      onReset={() => {
        closeDialog();
        setShowPass(false);
        setShowNewPass(false);
        setShowVerifyPass(false);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        handleReset,
        isSubmitting,
      }) => (
        <Dialog
          open={open}
          onClose={closeDialog}
          aria-labelledby='form-dialog-title'
          className='change-password-prompt'
        >
          <form onSubmit={handleSubmit}>
            <DialogTitle id='form-dialog-title'>{dialogTitle}</DialogTitle>
            <DialogContent>
              <Grid xs={12} item style={{ color: 'red' }}>
                {serverError}
              </Grid>
              <Grid xs={12} item>
                <Box m={1}>
                  <TextField
                    type={showPass ? 'text' : 'password'}
                    name='currentPassword'
                    placeholder='Current password'
                    variant='outlined'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.currentPassword}
                    error={errors.currentPassword && touched.currentPassword}
                    helperText={
                      errors.currentPassword && touched.currentPassword
                        ? errors.currentPassword
                        : ''
                    }
                    InputProps={{
                      endAdornment: (
                        <PasswordAdornment
                          {...{ showPass }}
                          onClick={() => setShowPass(!showPass)}
                        />
                      ),
                    }}
                  />
                </Box>
              </Grid>
              <Grid xs={12} item>
                <Box m={1}>
                  <TextField
                    type={showNewPass ? 'text' : 'password'}
                    name='newPassword'
                    placeholder='New password'
                    variant='outlined'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.newPassword}
                    error={errors.newPassword && touched.newPassword}
                    helperText={errors.newPassword && touched.newPassword ? errors.newPassword : ''}
                    InputProps={{
                      endAdornment: (
                        <PasswordAdornment
                          showPass={showNewPass}
                          onClick={() => setShowNewPass(!showNewPass)}
                        />
                      ),
                    }}
                  />
                </Box>
              </Grid>
              <Grid xs={12} item>
                <Box m={1}>
                  <TextField
                    type={showVerifyPass ? 'text' : 'password'}
                    name='newPasswordVerify'
                    placeholder='Confirm new password'
                    variant='outlined'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.newPasswordVerify}
                    error={errors.newPasswordVerify && touched.newPasswordVerify}
                    helperText={
                      errors.newPasswordVerify && touched.newPasswordVerify
                        ? errors.newPasswordVerify
                        : ''
                    }
                    InputProps={{
                      endAdornment: (
                        <PasswordAdornment
                          showPass={showVerifyPass}
                          onClick={() => setShowVerifyPass(!showVerifyPass)}
                        />
                      ),
                    }}
                  />
                </Box>
              </Grid>
            </DialogContent>
            <DialogActions>
              <RoundedButton
                variant='contained'
                type='submit'
                disabled={
                  isSubmitting ||
                  values.currentPassword.length === 0 ||
                  values.newPassword.length === 0 ||
                  values.newPassword !== values.newPasswordVerify ||
                  values.currentPassword === values.newPassword
                }
                color='primary'
              >
                Change
              </RoundedButton>
              <RoundedButton onClick={handleReset} variant='outlined' color='primary'>
                Cancel
              </RoundedButton>
            </DialogActions>
          </form>
        </Dialog>
      )}
    </Formik>
  );
};

export default connector(ChangePasswordPrompt);
