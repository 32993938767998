import { editor } from 'monaco-editor';
import colors from '../theme/colors';
import TokenFactory from '../TokenFactory';
import FoliaLanguageService from './FoliaLanguageService';

type ThemeRule = Omit<editor.ITokenThemeRule, 'token'>;

enum RuleClass {
  UNSTYLED,
  DELIMITER,
  OPERATOR,
  RELATION,
  KEY_EXPRESSION,
  BOOLEAN,
  NUMBER,
  STRING,
  ARRAY,
  NULL,
  ERROR,
}

const ruleClasses = [
  RuleClass.UNSTYLED,
  RuleClass.DELIMITER,
  RuleClass.OPERATOR,
  RuleClass.RELATION,
  RuleClass.KEY_EXPRESSION,
  RuleClass.BOOLEAN,
  RuleClass.NUMBER,
  RuleClass.STRING,
  RuleClass.ARRAY,
  RuleClass.NULL,
  RuleClass.ERROR,
];

type RuleClassMap<T> = { [ruleClass in RuleClass]: T };

const tokens: RuleClassMap<string[]> = {
  [RuleClass.UNSTYLED]: ['WS', 'NEW_LINE', 'PERIOD'],
  [RuleClass.DELIMITER]: ['L_PAREN', 'R_PAREN', 'L_BRACKET', 'R_BRACKET'],
  [RuleClass.OPERATOR]: ['LOGICAL_NOT', 'LOGICAL_AND', 'LOGICAL_OR'],
  [RuleClass.RELATION]: ['COMP_EQ', 'COMP_NEQ', 'COMP_LT', 'COMP_GT', 'COMP_LEQ', 'COMP_GEQ'],
  [RuleClass.KEY_EXPRESSION]: ['KW_KEY', 'KEY_EXPRESSION', 'KEY_ACCESSOR'],
  [RuleClass.BOOLEAN]: ['BOOLEAN_LITERAL'],
  [RuleClass.NUMBER]: ['INT_LITERAL', 'DOUBLE_LITERAL'],
  [RuleClass.STRING]: ['STRING_LITERAL', 'DOUBLE_QUOTE'],
  [RuleClass.ARRAY]: [
    'L_BRACE',
    'R_BRACE',
    'COMMA',
    'COMP_IN',
    'DOUBLE_ARRAY_LITERAL',
    'INT_ARRAY_LITERAL',
    'STRING_ARRAY_LITERAL',
  ],
  [RuleClass.NULL]: ['NULL_LITERAL'],
  [RuleClass.ERROR]: ['ERROR', 'UNRECOGNIZED'],
};

const themeRules: RuleClassMap<ThemeRule> = {
  [RuleClass.UNSTYLED]: {
    foreground: colors.black,
  },

  [RuleClass.DELIMITER]: {
    foreground: colors.midGray,
  },

  [RuleClass.OPERATOR]: {
    foreground: colors.pricklyPink,
    fontStyle: 'bold',
  },

  [RuleClass.RELATION]: {
    foreground: colors.black,
  },

  [RuleClass.KEY_EXPRESSION]: {
    foreground: colors.orange,
  },

  [RuleClass.BOOLEAN]: {
    foreground: colors.teal,
    fontStyle: 'bold',
  },

  [RuleClass.NUMBER]: {
    foreground: colors.giraffe,
  },

  [RuleClass.STRING]: {
    foreground: colors.darkGreen,
  },

  [RuleClass.ARRAY]: {
    foreground: colors.twilightBlue,
  },

  [RuleClass.NULL]: {
    foreground: colors.purple,
    fontStyle: 'bold',
  },

  [RuleClass.ERROR]: {
    foreground: colors.red,
  },
};

const createThemeRule = (ruleClass: RuleClass) => (ruleName: string): editor.ITokenThemeRule => ({
  token: TokenFactory.createTokenName(FoliaLanguageService.LANGUAGE_ID, ruleName),
  ...themeRules[ruleClass],
});

const rules: editor.ITokenThemeRule[] = ruleClasses.flatMap(ruleClass =>
  tokens[ruleClass].map(createThemeRule(ruleClass)),
);

export default rules;
