import { Checkbox, SliderProps, Switch, TextFieldProps } from '@mui/material';
import axios from 'axios';
import { FormikProps } from 'formik';
import { ReactElement } from 'react';
import { KeysOfType } from '../../../shared/dataTypes';
import { baseUrl } from '../../shared/environment';
import { CharacteristicDrawerForm, PropertiesForm } from './characteristicDrawerForm';

export const characteristicIds: Record<keyof PropertiesForm, number> = {
  reportAggregationType: 1003, // dropdown
  reportAggregator: 1017, // string
  colChooserVisibility: 1042,
  autoFillType: 1049,
  benchmarkCopy: 1010,
  benchName: 1022,
  benchmarkVisibility: 1035,
  bucketOrderDescending: 1051,
  columnClasses: 1031,
  computer: 1009,
  ccySensitive: 1028,
  dataEntryComponent: 1030,
  diffName: 1023,
  divisor: 1006,
  dynamic: 1002,
  editable: 1004,
  editability: 1021,
  editProcessorClass: 1059,
  entityType: 1015,
  predefinedFilter: 1043,
  numberFormatString: 1005,
  breakdownClass: 1008,
  justification: 1034,
  keyTranslation: 1045,
  colName: 1000,
  postProcessor: 1019,
  relatedEntities: 1047,
  reportFormatter: 1007,
  scalable: 1058,
  showInReportCcy: 1029,
  colType: 1001,
  valueKey: 1046,
  visibleColumn: 1018,
  description: 1025,
};

type GetProps<ReturnType, DataType> = (
  formProps: FormikProps<CharacteristicDrawerForm>,
  initialValues: CharacteristicDrawerForm,
) => (
  id: KeysOfType<CharacteristicDrawerForm, DataType>,
  onBlur?: (value?: boolean) => void,
) => ReturnType;

export enum CharacteristicDrawerStatus {
  VALIDATING = 'VALIDATING',
  TYPING = 'TYPING',
  IDLE = 'IDLE',
}

export const getTextFieldFormProps: GetProps<TextFieldProps, string | number> = (
  formProps,
  initialValues,
) => (id, onBlur) => ({
  id,
  value: formProps.values[id] ?? initialValues[id],
  onChange: e =>
    formProps.setFieldValue(
      id,
      typeof initialValues[id] === 'number' ? parseFloat(e.target.value) : e.target.value,
      false,
    ),
  size: 'small',
  variant: 'outlined',
  fullWidth: true,
  onFocus: () => formProps.setStatus(CharacteristicDrawerStatus.TYPING),
  onBlur: async e => {
    formProps.setStatus(CharacteristicDrawerStatus.IDLE);
    formProps.setFieldTouched(id, true, false);
    validateSingleField(id, e.target.value, formProps);
    onBlur?.();
  },
  onClose: async e => {
    formProps.setStatus(CharacteristicDrawerStatus.IDLE);
    formProps.setFieldTouched(id, true, false);
    validateSingleField(id, e.target.value, formProps);
  },
  error: !!formProps.errors[id],
  helperText: formProps.errors[id],
});

export const getCheckboxFormProps: GetProps<{ control: ReactElement }, boolean> = (
  formProps,
  initialValues,
) => (id, onBlur) => ({
  control: (
    <Checkbox
      {...{ id }}
      color='primary'
      checked={!!formProps.values[id] ?? !!initialValues[id]}
      onChange={e => {
        formProps.setFieldValue(id, e.target.checked, false);
        formProps.setFieldTouched(id, true, false);
        validateSingleField(id, e.target.checked ? 'true' : 'false', formProps);
        onBlur?.(e.target.checked);
      }}
    />
  ),
});

export const getSwitchFormProps: GetProps<{ control: ReactElement }, boolean> = (
  formProps,
  initialValues,
) => (id, onBlur) => ({
  control: (
    <Switch
      {...{ id }}
      color='primary'
      checked={!!formProps.values[id] ?? !!initialValues[id]}
      onChange={e => {
        formProps.setFieldValue(id, e.target.checked, false);
        formProps.setFieldTouched(id, true, false);
        validateSingleField(id, e.target.checked ? 'true' : 'false', formProps);
        onBlur?.(e.target.checked);
      }}
    />
  ),
});

export const getSliderFormProps: GetProps<SliderProps, number> = (
  formProps,
  initialValues,
) => id => ({
  id,
  name: id,
  max: 10000,
  value: formProps.values[id] ?? initialValues[id],
  size: 'small',
});

export const charOptionsToLabels: {
  [V in keyof Partial<CharacteristicDrawerForm>]: {
    value: string | number;
    key: string | number;
  }[];
} = {
  reportAggregationType: [
    { value: 0, key: 'Average' },
    { value: 2, key: 'Sum' },
    { value: 3, key: 'None' },
    { value: 7, key: 'Default' },
    { value: 8, key: 'Default (Null aware)' },
    { value: 9, key: 'Standard Deviation' },
    { value: 10, key: 'Variance' },
    { value: 11, key: 'Median' },
  ],
  dataEntryComponent: [
    { value: 'com.armanta.guiutil.IntegerDataEntryComponent', key: 'Integer' },
    { value: 'com.armanta.guiutil.SemicolonDelimDataEntryComponent', key: 'Semicolon Separated' },
    { value: 'com.armanta.guiutil.CommaDelimDataEntryComponent', key: 'Comma Separated' },
    { value: 'com.armanta.guiutil.PercentDataEntryComponent', key: 'Percent' },
    { value: 'com.armanta.guiutil.AmountDataEntryComponent', key: 'Amount' },
    { value: 'com.armanta.guiutil.StringDataEntryComponent', key: 'String' },
    { value: 'com.armanta.guiutil.BooleanDataEntryComponent', key: 'Boolean' },
    { value: 'com.armanta.guiutil.TimestampDataEntryComponent', key: 'Timestamp' },
    { value: 'com.armanta.guiutil.DoubleDataEntryComponent', key: 'Double' },
    { value: 'com.armanta.guiutil.RefEntityMultiComboDataEntryComponent', key: 'Reference Entity' },
    { value: 'com.armanta.guiutil.DateDataEntryComponent', key: 'Date' },
  ],
  colType: [
    { value: 1, key: 'String' },
    { value: 2, key: 'Integer' },
    { value: 3, key: 'Double' },
    { value: 4, key: 'Date' },
    { value: 5, key: 'Other' },
    { value: 6, key: 'Timestamp' },
    { value: 7, key: 'Boolean' },
    { value: 8, key: 'Long' },
    { value: 9, key: 'Float' },
    { value: 10, key: 'Byte' },
    { value: 11, key: 'Short' },
  ],
  reportFormatter: [{ value: 'com.armanta.rptgen.RefEntityFormatter', key: 'Reference Entity' }],

  justification: [
    { value: 'Left', key: 'Left' },
    { value: 'Right', key: 'Right' },
    { value: 'Center', key: 'Center' },
    { value: 'Default', key: 'Default' },
  ],

  benchmarkCopy: [
    { value: 'Default', key: 'Default' },
    { value: 'Always', key: 'Always' },
    { value: 'Never', key: 'Never' },
  ],

  benchmarkVisibility: [
    { value: 'Default', key: 'Default' },
    { value: 'Always', key: 'Always' },
    { value: 'Never', key: 'Never' },
  ],

  editability: [
    { value: 'Single', key: 'Single' },
    { value: 'Group', key: 'Group' },
    { value: 'API', key: 'API' },
  ],

  autoFillType: [
    { value: 2, key: 'Default' },
    { value: 0, key: 'On' },
    { value: 1, key: 'Off' },
  ],

  bucketOrderDescending: [
    { value: 'false', key: 'Ascending' },
    { value: 'true', key: 'Descending' },
  ],

  aggregationMethod: [
    { value: 'Sum', key: 'Sum' },
    { value: 'Mean', key: 'Mean' },
    { value: 'Min', key: 'Min' },
    { value: 'Max', key: 'Max' },
  ],

  riskMeasure: [
    { value: 'Absolute Mean', key: 'Absolute Mean' },
    { value: 'Mean', key: 'Mean' },
    { value: 'Standard Deviation', key: 'Standard Deviation' },
    { value: 'VaR', key: 'VaR' },
    { value: 'Expected Shortfall', key: 'Expected Shortfall' },
    { value: 'Worst Case', key: 'Worst Case' },
    { value: 'Best Case', key: 'Best Case' },
  ],

  smoothingMethodology: [
    { value: 'Quantile', key: 'Quantile' },
    { value: 'Triangle', key: 'Triangle' },
    { value: 'Gaussian', key: 'Gaussian' },
    { value: 'Harrel Davis ', key: 'Harrel Davis' }, // trailing space is intentional
    { value: 'Flexible Kernel ', key: 'Flexible Kernel' },
    { value: 'User Defined Weight ', key: 'User Defined Weight' },
    { value: '', key: 'None' },
  ],

  confidenceLevel: [
    { value: 90, key: 90 },
    { value: 95, key: 95 },
    { value: 97.5, key: 97.5 },
    { value: 99, key: 99 },
  ],

  numberFormatString: [
    { value: '####', key: '####' },
    { value: '###.##', key: '###.##' },
    { value: '#,##0.###', key: '#,##0.###' },
    { value: '#,###;(#,###)', key: '#,###;(#,###)' },
    { value: '#,###.###;(#,###.###)', key: '#,###.###;(#,###.###)' },
    { value: 'MM-dd-yyyy', key: 'MM-dd-yyyy' },
    { value: 'dd-MMM-YYYY kk:mm:ss.SSS', key: 'dd-MMM-YYYY kk:mm:ss.SSS' },
  ],
};

export const isValueInDropdown = (
  id: keyof CharacteristicDrawerForm,
  value: string | number | unknown,
) => (charOptionsToLabels[id] ?? []).find(obj => obj.value === value);
// TODO implement column chooser
export const defaultMetadataChars = [
  1000,
  1001,
  1015,
  1016,
  1046,
  1007,
  1009,
  1003,
  1017,
  1019,
  1028,
  1018,
  1033,
  1032,
];

const validateValueKey = async (
  id: keyof CharacteristicDrawerForm,
  value: string,
  formProps: FormikProps<CharacteristicDrawerForm>,
  formValues: CharacteristicDrawerForm,
) => {
  const dynamicValue = id === 'dynamic' ? value === 'true' : formValues.dynamic;
  const valueKey = id === 'valueKey' ? value : formValues.valueKey;
  if (valueKey === formProps.initialValues['valueKey']) return '';
  let errorMsg = '';
  const { data } = await axios.get<boolean>(`${baseUrl}api/doesValueKeyExist`, {
    params: { valueKey },
  });
  if (data) {
    errorMsg += 'This value already exists in the database. ';
  }
  if (!dynamicValue) {
    const { data: data2 } = await axios.get<boolean>(`${baseUrl}api/validateStaticValueKey`, {
      params: { valueKey },
    });
    if (!data2) {
      errorMsg += 'This value is not a valid static key. ';
    }
  }

  return errorMsg;
};

const nonBlankValuesAreUnique = (values: string[]) => {
  const nonBlanks = values.filter(v => v);
  return new Set(nonBlanks).size === nonBlanks.length;
};

export const validateSingleField = async (
  id: keyof CharacteristicDrawerForm,
  value: string,
  formProps: FormikProps<CharacteristicDrawerForm>,
  bypassInitialCheck = false,
  formValues?: CharacteristicDrawerForm,
) => {
  const results = await (async () => {
    if (value === formProps.initialValues[id] && !bypassInitialCheck) return '';
    formProps.setStatus(CharacteristicDrawerStatus.VALIDATING);
    const values = formValues || formProps.values;
    switch (id) {
      case 'colName':
      case 'benchName':
      case 'diffName': {
        const currentMatchError = nonBlankValuesAreUnique([
          values.colName,
          values.benchName,
          values.diffName,
        ])
          ? ''
          : 'Name, Benchmark Name, and Difference Name must be unique. ';

        const spacesError =
          value.trim() !== value ? 'Leading and trailing spaces are not allowed. ' : '';

        const { data } = await axios.get<boolean>(`${baseUrl}api/doesNameExist`, {
          params: { name: value },
        });

        return `${currentMatchError}${spacesError}${
          data ? 'This name already exists in the database.' : ''
        }`;
      }

      case 'dynamic':
      case 'valueKey': {
        const res = validateValueKey(id, value, formProps, values);
        id = 'valueKey';
        return res;
      }

      case 'keyTranslation': {
        const { data } = await axios.get<boolean>(`${baseUrl}api/isValidKeyTranslation`, {
          params: { keyTranslation: value },
        });
        return data ? '' : 'Invalid key translation.';
      }

      case 'scaleKey':
      case 'ancestorValue':
      case 'baseKey': {
        const { data } = await axios.get<boolean>(`${baseUrl}api/doesValueKeyExist`, {
          params: { valueKey: value },
        });
        return data ? '' : 'This value key does not exist in the database.';
      }

      case 'computer': {
        const { data } = await axios.get<boolean>(`${baseUrl}api/validateComputer`, {
          params: { text: value },
        });
        return data ? '' : 'Invalid computer.';
      }

      case 'postProcessor': {
        const { data } = await axios.get<boolean>(`${baseUrl}api/validatePostProcessor`, {
          params: { text: value },
        });
        return data ? '' : 'Invalid post-processor.';
      }

      case 'reportAggregator': {
        const { data } = await axios.get<boolean>(`${baseUrl}api/validateAggregator`, {
          params: { text: value },
        });
        return data ? '' : 'Invalid report aggregator.';
      }

      case 'numStdDeviations':
      case 'confidenceLevel': {
        return parseFloat(value) < 0 || parseFloat(value) > 100 || value === 'Other'
          ? 'Value must be between 0 and 100.'
          : '';
      }

      case 'numScenarios': {
        return parseFloat(value) < 0 || parseFloat(value) > 10000
          ? 'Value must be between 0 and 10000.'
          : '';
      }

      default:
        return '';
    }
  })();
  formProps.setStatus(CharacteristicDrawerStatus.IDLE);
  formProps.setFieldError(id, results || undefined);
};
