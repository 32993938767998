const P50 = '#a970ff';
const T50 = '#009e9a';
const M50 = '#ee538b';
const C50 = '#1193e8';
const C30 = '#6ccaff';
const P30 = '#d0b0ff';
const T30 = '#20d5d2';
const M30 = '#ffa0c2';
const M70 = '#a11950';
const C70 = '#0058a1';
const P70 = '#6e32c9';
const T70 = '#006161';
const T40 = '#00bab6';
const M40 = '#fa75a6';
const C40 = '#30b0ff';
const P40 = '#bb8eff';
const P60 = '#8a3ffc';
const T60 = '#007d79';
const M60 = '#d12765';
const C60 = '#0072c3';
const C80 = '#003d73';
const P80 = '#4f2196';
const T80 = '#004548';
const M80 = '#760a3a';

export const paletteP50 = [
  P50,
  T50,
  M50,
  C50,

  C30,
  P30,
  T30,
  M30,

  M70,
  C70,
  P70,
  T70,

  T40,
  M40,
  C40,
  P40,

  P60,
  T60,
  M60,
  C60,

  C80,
  P80,
  T80,
  M80,
];

export const paletteT50 = [
  T50,
  M50,
  C50,
  P50,

  P30,
  T30,
  M30,
  C30,

  C70,
  P70,
  T70,
  M70,

  M40,
  C40,
  P40,
  T40,

  T60,
  M60,
  C60,
  P60,

  P80,
  T80,
  M80,
  C80,
];

export const paletteM50 = [
  M50,
  C50,
  P50,
  T50,

  T30,
  M30,
  C30,
  P30,

  P70,
  T70,
  M70,
  C70,

  C40,
  P40,
  T40,
  M40,

  M60,
  C60,
  P60,
  T60,

  T80,
  M80,
  C80,
  P80,
];

export const paletteC50 = [
  C50,
  P50,
  T50,
  M50,

  M30,
  C30,
  P30,
  T30,

  T70,
  M70,
  C70,
  P70,

  P40,
  T40,
  M40,
  C40,

  C60,
  P60,
  T60,
  M60,

  M80,
  C80,
  P80,
  T80,
];

export const heatMapRed2Red = [
  '#ff0000',
  '#f70000',
  '#ef0000',
  '#e80000',
  '#e00000',
  '#d80000',
  '#d10000',
  '#c90000',
  '#c20000',
  '#ba0000',
  '#b20000',
  '#ab0000',
  '#a30000',
  '#9c0000',
  '#940000',
  '#8c0000',
  '#850000',
  '#7d0000',
  '#760000',
  '#6e0000',
  '#660000',
  '#5f0000',
  '#570000',
  '#500000',
];

export const heatMapRed2Green = [
  '#ff0000',
  '#f30b00',
  '#e81600',
  '#dd2100',
  '#d22c00',
  '#c73700',
  '#bc4200',
  '#b14d00',
  '#a65800',
  '#9b6300',
  '#906e00',
  '#857900',
  '#798500',
  '#6e9000',
  '#639b00',
  '#58a600',
  '#4db100',
  '#42bc00',
  '#37c700',
  '#2cd200',
  '#21dd00',
  '#16e800',
  '#0bf300',
  '#00ff00',
];

export const heatMap = [M80, M60, M30, C30, C30, C30, T30, T60, T80];

export const geoMap = ['#16e800', '#4db100', '#798500', '#906e00', '#bc4200', '#e81600'];

export const carbonColors = {
  blue30: '#a6c8ff',
  blue50: '#4589ff',
  blue60: '#0f62fe',
  gray20: '#e0e0e0',
  gray50: '#8d8d8d',
  gray100: '#161616',
  green50: '#24a148',
  red60: '#da1e28',
  yellow20: '#fdd13a',
};
