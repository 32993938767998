import { useEffect, useMemo } from 'react';
import { EditorLanguage } from '../editorLanguage';
import useMemoizedMonaco from '../memoized-monaco/useMemoizedMonaco';
import AScriptLanguageService from './AScriptLanguageService';
import CompletionProviderService from './completion-providers/completionProviderService';
import { CompletionProviderType } from './completion-providers/completionProviderType';
import createModiferCompletionProvider from './completion-providers/modifierCompletionProvider';
import useCompletionProviderService from './completion-providers/useCompletionProviderService';

const useAScriptLanguage = () => {
  const monaco = useMemoizedMonaco();
  const languageService = useMemo(() => new AScriptLanguageService(), []);
  const completionProviderService = useCompletionProviderService(EditorLanguage.ASCRIPT);

  useEffect(() => {
    if (!monaco) {
      return;
    }

    languageService.register(monaco);
  }, [monaco, languageService]);

  useEffect(() => {
    if (!monaco || !completionProviderService) {
      return;
    }

    completionProviderService.registerProvider(
      CompletionProviderService.getId(CompletionProviderType.MODIFIER),
      createModiferCompletionProvider(),
    );

    return () => {
      completionProviderService.clearAllProviders();
    };
  }, [monaco, completionProviderService]);

  return languageService;
};

export default useAScriptLanguage;
