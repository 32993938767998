import { DrawerState } from '../shared/dataTypes';
import { closeReportPortfolioDrawer, openReportPortfolioDrawer } from './ActionCreators';
import { CLOSE_REPORT_PORTFOLIO_DRAWER, OPEN_REPORT_PORTFOLIO_DRAWER } from './ActionTypes';

type Action =
  | ReturnType<typeof openReportPortfolioDrawer>
  | ReturnType<typeof closeReportPortfolioDrawer>;

export const reportPortfolioDrawer = (
  state: DrawerState = { open: false, sequenceId: undefined },
  action: Action,
): DrawerState => {
  switch (action.type) {
    case OPEN_REPORT_PORTFOLIO_DRAWER:
      return { open: true, sequenceId: action.payload };
    case CLOSE_REPORT_PORTFOLIO_DRAWER:
      return { open: false, sequenceId: undefined };
    default:
      return state;
  }
};
