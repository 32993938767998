import { languages } from 'monaco-editor';
import { EditorLanguage } from './editorLanguage';
import LineTokens from './LineTokens';
import AbstractState from './State';
import Token from './Token';

export type Newable<T> = { new (...args: any[]): T };

abstract class TokensProvider<
  LanguageId extends EditorLanguage,
  State extends AbstractState<LanguageId>
> implements languages.TokensProvider {
  constructor(
    private state: Newable<State>,
    private tokenizeLine: (input: string, state: State) => Token<LanguageId>[],
  ) {}

  getInitialState = () => new this.state();
  tokenize = (line: string, state: State) =>
    new LineTokens<LanguageId, State>(this.tokenizeLine(line, state), state);
}

export default TokensProvider;
