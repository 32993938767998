import { FormikProps } from 'formik';
import { EditorInterfaceType } from '../../components/editor/ascript/model/editorInterfaceType';
import { CharacteristicDrawerForm } from '../../components/metadata/characteristic-drawer/characteristicDrawerForm';
import {
  createDefaultAScriptContents as createDefaultContents,
  EditorContents,
} from '../../lang/editorLanguage';

export interface AScriptEditorState {
  interfaceType: EditorInterfaceType | null;
  className: string | null;
  superclassName: string | null;
  initialContents: EditorContents;
  formProps: FormikProps<CharacteristicDrawerForm> | null;
}

export const createDefaultState = (): AScriptEditorState => ({
  interfaceType: null,
  className: null,
  superclassName: null,
  initialContents: createDefaultContents(),
  formProps: null,
});
