const colors = {
  black: '#000000',
  white: '#ffffff',
  lightGray: '#a9a9aa',
  red: '#ec0000',
  purple: '#7132a8',
  pink: '#c838c6',
  darkGreen: '#3b8737',
  rose: '#cd6069',
  giraffe: '#f0aa0b',
  green: '#53a053',
  teal: '#00bec4',
  pricklyPink: '#fa278e',
  twilightBlue: '#7c4dff',
  orange: '#ff6d12',
  midGray: '#5d5d5f',
};

export default colors;
