import { languages } from 'monaco-editor';
import { EditorLanguage } from './editorLanguage';
import { SimpleToken } from './SimpleToken';
import TokenFactory from './TokenFactory';

class Token<LanguageId extends EditorLanguage> implements languages.IToken {
  readonly scopes: string;

  constructor(
    public readonly languageId: LanguageId,
    public readonly ruleName: string,
    public readonly text: string | null,
    public readonly startIndex: number,
  ) {
    this.scopes = TokenFactory.createTokenName(languageId, ruleName);
  }

  toString = () => `${this.languageId}<${this.ruleName} @ ${this.startIndex}>`;

  toSimpleToken = (): SimpleToken => {
    const { ruleName: type, text } = this;
    return { type, text };
  };
}

export default Token;
