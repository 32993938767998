import { IRange, languages } from 'monaco-editor';

const MODIFIERS = ['port', 'bench', 'bench2', 'diff', 'diff2'];
const createModiferCompletionProvider = (): languages.CompletionItemProvider => {
  const createDependencyProposals = (range: IRange): languages.CompletionItem[] =>
    MODIFIERS.map((modifier, index) => ({
      label: modifier,
      kind: languages.CompletionItemKind.Variable,
      insertText: modifier,
      range,
      insertTextRules: languages.CompletionItemInsertTextRule.InsertAsSnippet,
      sortText: `${index}`,
    }));

  return {
    triggerCharacters: ['.'],

    provideCompletionItems: (model, position, _context, _token) => {
      const contentUntilPosition = model.getValueInRange({
        startLineNumber: 1,
        startColumn: 1,
        endLineNumber: position.lineNumber,
        endColumn: position.column,
      });

      const matchPattern = /(?:key|total|ValueKey|get)\[[^\]]*\]\.(?<content>\w*)$/;
      const match = contentUntilPosition.match(matchPattern);
      if (!match) {
        return {
          suggestions: [],
        };
      }

      return {
        suggestions: createDependencyProposals({
          startLineNumber: position.lineNumber,
          endLineNumber: position.lineNumber,
          startColumn: position.column - match.groups.content.length,
          endColumn: position.column,
        }),
      };
    },
  };
};

export default createModiferCompletionProvider;
