import { Sort, SortDirection } from 'algo-react-dataviz';

// TODO: Export somewhere else
const COL_NAME_CHAR_ID = 1000;

/**
 * N.B. only the first item is currently used by the backend.
 * (cf. `package.com.ssctech.algo.backstage.websockets.reports.SortHelper`)
 */
export const createDefaultSort = (): Sort[] => [
  {
    charId: COL_NAME_CHAR_ID,
    direction: 'asc',

    ...createStaticSortProps(),
  },
];

// Unused by the backend
export const createStaticSortProps = (): Pick<Sort, 'type' | 'columnHash' | 'modifier'> => ({
  type: null,
  columnHash: null,
  modifier: null,
});

export const getNewSort = (sort: Sort | null, charId: number): Sort[] => {
  if (sort === null) {
    return createDefaultSort();
  }

  const { charId: currentCharId, direction } = sort;
  return [
    {
      charId,
      direction: currentCharId === charId ? getReverseSortDirection(direction) : 'asc',
      ...createStaticSortProps(),
    },
  ];
};

export const getReverseSortDirection = (direction: SortDirection): SortDirection => {
  switch (direction) {
    case 'asc':
      return 'desc';

    case 'desc':
      return 'asc';
  }
};
