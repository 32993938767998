// Note that these ids have a space at the end. That is so that
// they match the server side. It mirrors the enum in
// integratedrisk.common.util.VarDecompositionMethodology from awa-common.
export enum SmoothingMethodology {
  QUANTILE = 'Quantile',
  TRIANGLE = 'Triangle',
  GAUSSIAN = 'Gaussian',
  HARREL_DAVIS = 'Harrel Davis ',
  FLEXIBLE_KERNEL = 'Flexible Kernel ',
  USER_DEFINED_WEIGHT = 'User Defined Weight ',
}

export interface ScenarioSet {
  id: string;
  name: string;
  isVirtual: boolean;
  isDefault: boolean;
  scenarios: { id: string; name: string }[];
  timesteps: { id: string; name: string }[];
}

export interface VarMethodology {
  id: string;
  name: string;
}

export enum RiskFactorCategory {
  ALL = 'All',
  IR = 'IR',
  CS = 'CS',
  EQ = 'EQ',
  FX = 'FX',
  CO = 'CO',
}

export const DEFAULT_RISK_FACTOR_CATEGORY = RiskFactorCategory.ALL;
export const getRiskFactorCategoryOptions = () => Object.values(RiskFactorCategory);

export const DEFAULT_LIQUIDITY_HORIZON = '10 days';
export const getLiquidityHorizonOptions = () => [
  '10 days',
  '20 days',
  '40 days',
  '60 days',
  '120 days',
];

export enum Period {
  STRESS = 'Stress',
  CURRENT = 'Current',
}

export const DEFAULT_PERIOD = Period.STRESS;
export const getPeriodOptions = () => Object.values(Period);

export enum RiskFactorSet {
  REDUCED = 'Reduced',
  FULL = 'Full',
}

export const DEFAULT_RISK_FACTOR_SET = RiskFactorSet.REDUCED;
export const getRiskFactorSetOptions = () => Object.values(RiskFactorSet);

export interface ScenariosConfig {
  sets: string[];
  scenarios: string[];
  timesteps: string[];
  varMethodology: string;
  confidenceLevelRadioOption: number;
  confidenceLevelOther?: number;
  smoothingMethodology: SmoothingMethodology;
  smoothingMethodologyInput: number;
  flexibleKernelInput: number;
  stdDevInput: number;
  varDecompRadioOption: 'root' | 'parent';
  marginalVar: number;
  startingIndex: number;
  range: number;
  windowIncrement: number;
  userDefinedDropdownOption: string;

  // FRTB-specific properties
  riskFactorCategory: RiskFactorCategory;
  liquidityHorizon: string;
  period: Period;
  riskFactorSet: RiskFactorSet;
}

export const TIMESTEP_ID_DEFAULT = 'Default';
export const TIMESTEP_ID_ALL = 'All';

export const createDefaultConfig = (): ScenariosConfig => ({
  sets: [],
  scenarios: ['-1'],
  timesteps: [TIMESTEP_ID_DEFAULT],
  varMethodology: 'Outer Limit',
  confidenceLevelRadioOption: 97.5,
  confidenceLevelOther: 97,
  smoothingMethodology: SmoothingMethodology.QUANTILE,
  smoothingMethodologyInput: 5,
  flexibleKernelInput: 100,
  stdDevInput: 2,
  varDecompRadioOption: 'root',
  marginalVar: 1,
  startingIndex: 1,
  range: 260,
  windowIncrement: 260,
  userDefinedDropdownOption: '',

  riskFactorCategory: DEFAULT_RISK_FACTOR_CATEGORY,
  liquidityHorizon: DEFAULT_LIQUIDITY_HORIZON,
  period: DEFAULT_PERIOD,
  riskFactorSet: DEFAULT_RISK_FACTOR_SET,
});
