import { useMemo } from 'react';
import { EditorLanguage } from '../../editorLanguage';
import useMemoizedMonaco from '../../memoized-monaco/useMemoizedMonaco';
import CompletionProviderService from './completionProviderService';

const useCompletionProviderService = (
  language: EditorLanguage,
): CompletionProviderService | null => {
  const monaco = useMemoizedMonaco();

  return useMemo(() => (!monaco ? null : new CompletionProviderService(language, monaco)), [
    language,
    monaco,
  ]);
};

export default useCompletionProviderService;
