import axios from 'axios';
import { enqueueSnackbar } from '../../../redux/ActionCreators';
import { AppThunk } from '../../../redux/configureStore';
import { DESIGNER_SEQUENCE_ID, NotificationLevel } from '../../../shared/constants';

import {
  DrawerItemType,
  ParentToChildRelationship,
  ReportDefinition,
  ReportDefinitions,
  SerializedWorkspace,
  SerializedWorkspaceDefinition,
  WorkspaceData,
} from '../../../shared/dataTypes';
import { getDateContextForPortfolioDrawer } from '../../../shared/utils';
import { getSelectedPortfolios } from '../../drawers/portfolioDrawerHelpers';
import { baseUrl } from '../../shared/environment';

export const downloadURI = (uri: string, name: string): void => {
  const link = document.createElement('a');
  link.download = name;
  link.href = uri;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const exportToPDF = (sequenceId, pdfData): AppThunk => (_dispatch, getState) => {
  const data = new Blob([pdfData]);

  downloadURI(
    window.URL.createObjectURL(data),
    getState().report.reportDefinition[sequenceId]?.reportTitle + '.pdf',
  );
};

export const exportToCSV = (sequenceId: number, rawData: any = null): AppThunk => (
  _dispatch,
  getState,
) => {
  const data = new Blob([rawData], {
    type: 'csv/plain',
  });

  downloadURI(
    window.URL.createObjectURL(data),
    getState().report.reportDefinition[sequenceId]?.reportTitle + '.csv',
  );
};

export const triggerJsonExport = async (path: string, type: DrawerItemType) =>
  axios
    .get<unknown>(`${baseUrl}api/resourceDefinitions`, {
      params: {
        path,
        type,
      },
    })
    .then(({ data }) => {
      exportToJson(data, decodeURIComponent(path.replaceAll('/', '_')));
    });

// exports an object to a json file
export const exportToJson = <T>(value: T, path: string) => {
  const data = new Blob([JSON.stringify(value)], {
    type: 'application/json',
  });
  downloadURI(window.URL.createObjectURL(data), path + '.json');
};

// exports a report definition to a json file
export const exportReportDefToJson = (sequenceId: number): AppThunk => (_dispatch, getState) => {
  const data = new Blob([convertReportDefinition(getState().report.reportDefinition[sequenceId])], {
    type: 'application/json',
  });
  downloadURI(
    window.URL.createObjectURL(data),
    getState().report.reportDefinition[sequenceId].reportTitle + '.json',
  );
};

export const exportReportDescriptorToJson = (sequenceId: number): AppThunk => (
  dispatch,
  getState,
) => {
  const report = getState().report.reportDefinition[sequenceId];
  const { date, id } = getDateContextForPortfolioDrawer(
    getState().user.selectedDateContext,
    report.dateContext,
  );

  const {
    selectedPortfolioNodeIds,
    selectedAdHocPortfolioNames,
    selectedBenchmarkPortfolioNames,
    selectedPortfolioHierarchyName,
  } = getSelectedPortfolios(getState(), sequenceId);

  axios
    .put(
      `${baseUrl}api/reportDescriptor`,
      {
        ...report,
        selectedPortfolioNodeIds,
        selectedAdHocPortfolioNames,
        selectedBenchmarkPortfolioNames,
        selectedPortfolioHierarchyName,
      },
      {
        params: {
          date,
          id,
        },
      },
    )
    .then(result => {
      const data = new Blob([JSON.stringify(result.data)], {
        type: 'application/json',
      });
      downloadURI(window.URL.createObjectURL(data), report.reportTitle + '-rest.json');
    })
    .catch(error =>
      dispatch(enqueueSnackbar(NotificationLevel.ERROR, `${error.response.data.message}`)),
    );
};

// exports a report definition as json to clipboard
export const copyReportDefToJson = (sequenceId: number): AppThunk => (dispatch, getState) => {
  navigator.clipboard.writeText(
    convertReportDefinition(getState().report.reportDefinition[sequenceId]),
  );
  dispatch(enqueueSnackbar(NotificationLevel.SUCCESS, 'Copied Report to Clipboard'));
};

// converts a report definition to an adhoc (with null path) and returns it as json string
const convertReportDefinition = (reportDefinition: ReportDefinition): string => {
  const def = {
    ...reportDefinition,
    path: null,
  };

  return JSON.stringify(def);
};

// exports a workspace definition to a json file
export const exportWorkspaceDefToJson = (): AppThunk => (_dispatch, getState) => {
  const { data, selectedTabIndex, parentToChildRelationship } = getState().workspace;
  const { reportDefinition } = getState().report;

  const blobData = new Blob(
    [
      convertWorkspaceDefinition(
        data,
        selectedTabIndex,
        parentToChildRelationship,
        reportDefinition,
      ),
    ],
    {
      type: 'application/json',
    },
  );

  downloadURI(
    window.URL.createObjectURL(blobData),
    data.path
      ? data.path.substring(data.path.lastIndexOf('/') + 1) + '.json'
      : 'untitled_workspace.json',
  );
};

export const copyWorkspaceDefToJson = (): AppThunk => (dispatch, getState) => {
  const { data, selectedTabIndex, parentToChildRelationship } = getState().workspace;
  const { reportDefinition } = getState().report;

  try {
    navigator.clipboard.writeText(
      convertWorkspaceDefinition(
        data,
        selectedTabIndex,
        parentToChildRelationship,
        reportDefinition,
      ),
    );
    dispatch(enqueueSnackbar(NotificationLevel.SUCCESS, 'Copied Workspace to Clipboard'));
  } catch (e) {
    console.error('Error exporting workspace', e);
    dispatch(enqueueSnackbar(NotificationLevel.ERROR, 'Error exporting workspace'));
  }
};

// converts a workspace definition to an adhoc (with null path) and returns it as json string
const convertWorkspaceDefinition = (
  data: WorkspaceData,
  selectedTabIndex: number,
  parentToChildRelationship: ParentToChildRelationship,
  reportDefinitions: ReportDefinitions,
): string => {
  const workspaceDefinition: SerializedWorkspaceDefinition = {
    data: { ...data, path: null },
    parentToChildRelationship,
    selectedTabIndex,
  };

  const updatedReportDefinitions: ReportDefinitions = {};

  Object.entries(reportDefinitions).forEach(([sequenceId, reportDefinition]) => {
    if (sequenceId === `${DESIGNER_SEQUENCE_ID}`) return;
    updatedReportDefinitions[parseInt(sequenceId)] = {
      ...reportDefinition,
      path: null,
    };
  });

  const dataFormat: SerializedWorkspace = [workspaceDefinition, updatedReportDefinitions];
  return JSON.stringify(dataFormat);
};
